import ADMIN from "../constants/Admin.constant";

const initialState = {
    admins: [],
    getLoading: true,
    metaData: {},
    addLoading: false,
    updateLoading: false,
    toggleStatusLoading: false,

    roles: [],
    getRolesLoading: true,
    rolesMetaData: {},
    rolesLoadMoreLoading: false,
    rolesCanLoadMore: true,

    createAndUpdateRoleLoading: false,
    updateRoleStatusLoading: false,
    assignOrUnassignRoleLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ADMIN.GET_API:
            return {
                ...state,
                admins: action.data || state?.admins,
                getLoading: action.loading,
                metaData: action.metaData || state?.metaData
            };
        case ADMIN.ADD_API:
            return {
                ...state,
                addLoading: action.loading
            };

        case ADMIN.UPDATE_API:
            return {
                ...state,
                updateLoading: action.loading
            };

        case ADMIN.TOGGLE_STATUS:
            return {
                ...state,
                toggleStatusLoading: action.loading
            };

        case ADMIN.GET_ROLES:
            return {
                ...state,
                roles: action.data || state?.roles,
                getRolesLoading: action.loading,
                rolesMetaData: action.metaData || state?.rolesMetaData,
                rolesLoadMoreLoading: false,
                rolesCanLoadMore: true,
            };

        case ADMIN.LOAD_MORE_ROLES:
            return {
                ...state,
                roles: [...state.roles, ...action.data],
                rolesMetaData: action.metaData,
                rolesLoadMoreLoading: action.loading,
                rolesCanLoadMore: action.canLoadMore
            };

        case ADMIN.CREATE_AND_UPDATE_ROLE:
            return {
                ...state,
                createAndUpdateRoleLoading: action.loading
            };

        case ADMIN.UPDATE_ROLE_STATUS:
            return {
                ...state,
                updateRoleStatusLoading: action.loading
            };

        case ADMIN.ASSIGN_UNASSIGN_ROLE:
            return {
                ...state,
                assignOrUnassignRoleLoading: action.loading
            };

        default:
            return state;
    }
};
