const CommonConstant = {

    TOGGLE_LEFT_DRAWER: "TOGGLE_LEFT_DRAWER",

    CURRENT_COUNTRY: "CURRENT_COUNTRY",

    GET_COUNTRIES: 'GET_COUNTRIES',

    GET_STATES: 'GET_STATES',

    GET_CLIENTS: 'GET_CLIENTS',

    SELECTED_CLIENT: 'SELECTED_CLIENT',

    DOWNLOAD_CSV: "DOWNLOAD_CSV",

    GET_ALL_TOPICS: 'GET_ALL_TOPICS',

    REFRESH_TOKEN_TIMER: "REFRESH_TOKEN_TIMER",

    GET_CONSTANTS: 'GET_CONSTANTS',

    GET_APPROVAL_COUNT: "GET_APPROVAL_COUNT",

    SEND_OTP_MOBILE_API: "SEND_OTP_MOBILE_API",
    RESEND_OTP_MOBILE_API: "RESEND_OTP_MOBILE_API",
    VERIFY_OTP_MOBILE_API: "VERIFY_OTP_MOBILE_API",
};

export default CommonConstant;
