import { post, get } from "../../utils/apiMethods";
import CommonConstant from "../constants/Common.constant";
import { handleError, handleSuccess } from "../../utils/methods";
import { setValueIntoHeaders, setValueInToLocalStorage } from "../../utils/asyncStorage/Functions";
import { CLIENTID } from "../../utils/asyncStorage/Constants";

export const toggleLeftDrawer = (value) => (dispatch) => {
    dispatch({ type: CommonConstant.TOGGLE_LEFT_DRAWER, value: value })
};

export const selectCountry = (value) => (dispatch) => {
    let selectedCountry = {
        "name": "United Arab Emirates",
        "code": "+971",
        "iso": "AE",
        "flag": require('../../assets/images/uaeFlag.png'),
        "mask": ["99 999 9999", "9 999 9999"],
        "currency": 'AED',
        pattern: [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
    };
    dispatch({ type: CommonConstant.CURRENT_COUNTRY, country: selectedCountry })
};

export const getCountries = (values, CB) => (dispatch) => {
    dispatch({ type: CommonConstant.GET_COUNTRIES, loading: true });
    post(`masters/getAllCountries`, values)
        .then(async ({ data }) => {
            if (!data.error) {
                dispatch({ type: CommonConstant.GET_COUNTRIES, loading: false, data: data?.data?.entries });
            } else {
                handleError(data?.data?.message || 'Something went wrong!');
                dispatch({ type: CommonConstant.GET_COUNTRIES, loading: false });
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || error?.response?.data?.message || 'Something went wrong!');
            dispatch({ type: CommonConstant.GET_COUNTRIES, loading: false });
        });
};

export const getStates = (values, CB) => (dispatch) => {
    dispatch({ type: CommonConstant.GET_STATES, loading: true });
    post(`masters/getAllStates`, values)
        .then(async ({ data }) => {
            if (!data.error) {
                dispatch({ type: CommonConstant.GET_STATES, loading: false, data: data?.data?.entries });
            } else {
                handleError(data?.data?.message || 'Something went wrong!');
                dispatch({ type: CommonConstant.GET_STATES, loading: false });
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || error?.response?.data?.message || 'Something went wrong!');
            dispatch({ type: CommonConstant.GET_STATES, loading: false });
        });
};

export const getClients = (values, CB) => (dispatch) => {
    dispatch({ type: CommonConstant.GET_CLIENTS, loading: true });
    post(`clients/getAll`, values)
        .then(async ({ data }) => {
            if (!data.error) {
                dispatch({ type: CommonConstant.GET_CLIENTS, loading: false, data: data?.data?.entries });
            } else {
                handleError(data?.data?.message || 'Something went wrong!');
                dispatch({ type: CommonConstant.GET_CLIENTS, loading: false });
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || error?.response?.data?.message || 'Something went wrong!');
            dispatch({ type: CommonConstant.GET_CLIENTS, loading: false });
        });
};

export const changeClient = (_id, CB) => async (dispatch) => {
    setValueIntoHeaders(_id);
    await setValueInToLocalStorage(CLIENTID, _id);
    dispatch({ type: CommonConstant.SELECTED_CLIENT, data: _id });
    CB && CB();
};

export const downloadCSV = (payload, uri, fileName) => (dispatch) => {
    let dispatchType = CommonConstant.DOWNLOAD_CSV;
    dispatch({ type: dispatchType, loading: true });
    post(uri, payload)
        .then(async ({ data }) => {
            try {
                let link = document.createElement("a");
                let d = new Date();
                link.download = `${fileName}-${d.toISOString()}.csv`;
                let blob = new Blob([data], { type: "text/csv" });
                link.href = URL.createObjectURL(blob);
                link.click();
                URL.revokeObjectURL(link.href);
                dispatch({ type: dispatchType, loading: false });
            } catch (err) {
                console.log('error', err);
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || error?.response?.data?.message || "Something went wrong!");
            dispatch({ type: dispatchType, loading: false });
        });
};

export const getAllTopics = () => (dispatch) => {
    const dispatchType = CommonConstant.GET_ALL_TOPICS;
    dispatch({ type: dispatchType, loading: true, data: [] });
    get(`admins/approvalTopics`)
        .then(async ({ data }) => {
            if (!data.error) {
                dispatch({ type: dispatchType, loading: false, data: data?.data });
            } else {
                handleError(data?.data?.message || "Something went wrong!");
                dispatch({ type: dispatchType, loading: false });
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || error?.response?.data?.message || "Something went wrong!");
            dispatch({ type: dispatchType, loading: false });
        });
};

export const refreshTokenTimer = (isTimer) => (dispatch) => {
    let dispatchType = CommonConstant.REFRESH_TOKEN_TIMER;
    dispatch({ type: dispatchType, data: isTimer });
};

export const getConstants = (CB) => (dispatch) => {
    const dispatchType = CommonConstant.GET_CONSTANTS;
    dispatch({ type: dispatchType, loading: true });
    get(`generic/getConstants`)
        .then(async ({ data }) => {
            if (!data.error) {
                let employeesDocs = data?.data?.DOCUMENT_TYPES?.filter(doc => doc?.docType === 'E')?.sort((a, b) => { return a?.docId - b?.docId });
                let employerDocs = data?.data?.DOCUMENT_TYPES?.filter(doc => doc?.docType === 'C')?.sort((a, b) => { return a?.docId - b?.docId });
                dispatch({ type: dispatchType, loading: false, data: data?.data, employeesDocs, employerDocs });
                CB && CB();
            } else {
                handleError(data?.data?.message || 'Something went wrong!');
                dispatch({ type: dispatchType, loading: false });
            }
        })
        .catch((error) => {
            handleError(error?.data?.message || error?.response?.data?.message || 'Something went wrong!');
            dispatch({ type: dispatchType, loading: false });
        });
};

export const setApprovalsCount = (response) => (dispatch) => {
    const dispatchType = CommonConstant.GET_APPROVAL_COUNT;
    dispatch({
        type: dispatchType,
        ...response
    });
};

export const sendOtpMobileApi = (payload, CB) => async (dispatch) => {
    dispatch({ type: CommonConstant.SEND_OTP_MOBILE_API, loading: true});
    try {
        let response = await post("generic/mobileAppSendOTP", payload);
        if(response?.data?.error) {
            handleError(response?.data?.data?.message || 'Something went wrong!');
        } else {
            CB && CB({ token: response?.data?.data?.mobileAppToken, userId: response?.data?.data?.userId, ...payload});
        }
        dispatch({type: CommonConstant.SEND_OTP_MOBILE_API, loading: false});
    } catch (error) {
        handleError(error?.data?.message || error?.response?.data?.message || 'Something went wrong!');
        dispatch({ type: CommonConstant.SEND_OTP_MOBILE_API, loading: false });
    }
};

export const resendOtpMobileApi = (payload, CB) => async (dispatch) => {
    dispatch({ type: CommonConstant.RESEND_OTP_MOBILE_API, loading: true});
    try {
        let response = await post("generic/mobileAppReSendOTP", payload);
        if(response?.data?.error) {
            handleError(response?.data?.data?.message || 'Something went wrong!');
        } else {
            handleSuccess(response?.data?.data?.message);
            CB && CB({ token: response?.data?.data?.mobileAppToken, userId: response?.data?.data?.userId });
        }
        dispatch({type: CommonConstant.RESEND_OTP_MOBILE_API, loading: false});
    } catch (error) {
        handleError(error?.data?.message || error?.response?.data?.message || 'Something went wrong!');
        dispatch({ type: CommonConstant.RESEND_OTP_MOBILE_API, loading: false });
    }
};

export const verifyOtpMobileApi = (payload, CB) => async (dispatch) => {
    dispatch({ type: CommonConstant.VERIFY_OTP_MOBILE_API, loading: true});
    try {
        let response = await post("generic/mobileAppVerifyOTP", payload);
        if(response?.data?.error) {
            handleError(response?.data?.data?.message || 'Something went wrong!');
        } else {
            CB && CB({...payload, ...response?.data?.data});
            // handleSuccess(response?.data?.data?.message);
        }
        dispatch({type: CommonConstant.VERIFY_OTP_MOBILE_API, loading: false});
    } catch (error) {
        handleError(error?.data?.message || error?.response?.data?.message || 'Something went wrong!');
        dispatch({ type: CommonConstant.VERIFY_OTP_MOBILE_API, loading: false });
    }
};
