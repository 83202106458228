import * as Yup from "yup";
import { showNumberInBank } from "./helper";
import { phoneValidateNumberRegex } from "../../../utils/methods";

const iban = Yup.object().shape({
  iban: Yup.string().label("IBAN")
    .required('Please enter IBAN')
    .matches(/\b(PK|RO)[0-9]{2}[A-Z]{4}[A-Z0-9]{16,20}\b/, 'Please enter valid IBAN number'),
});

const ifsc = Yup.object().shape({
  branchCode: Yup.string().label("IBAN")
    .required('Please enter IBAN')
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Please enter valid IFSC number'),
});

const normalScheme = () => ({
  beneficiaryFirstName: Yup.string().required("First name is required")
    .min(2, 'First name must be at least 2 characters long')
    .max(45, 'First name should not exceed 45 characters')
    .matches(/^[aA-zZ\s]+$/, "First name should be alphabetic only"),
  beneficiaryLastName: Yup.string().required("Last name is required")
    .min(2, 'Last name must be at least 2 characters long')
    .max(45, 'Last name should not exceed 45 characters')
    .matches(/^[aA-zZ\s]+$/, "Last name should be alphabetic only"),
  // RemitPurpose: Yup.string().required("Remit purpose is required"),
  // RemitPurposeText: Yup.string().when(['RemitPurpose'], {
  //   is: (RemitPurpose) =>
  //     RemitPurpose === 'Other',
  //   then: Yup.string().required('Remit purpose text is required')
  // }),
  beneficiaryNationalityCountryISOCode: Yup.object().required("Please select nationality"),
  addBeneficiary: Yup.boolean(),
  alias: Yup.string()
    .when("AddBeneficiary", {
      is: true,
      then: Yup.string().required("Beneficiary Alias is required").matches(/^[aA-zZ\s]+$/, "Beneficiary Alias should be alphabetic only"),
    }),
});

const bankScheme = {
  // BeneficiaryAccountNo: Yup.string().required("VALIDATION.ACCOUNT_OR_IBAN_NUMBER.REQUIRED").matches(/^[a-zA-Z0-9_.-]*$/, 'VALIDATION.ACCOUNT_OR_IBAN_NUMBER.MATCH'),
  beneficiaryAccountNo: Yup.string().required("Account number is required").matches(/^[a-zA-Z0-9]+$/, 'Account number must be alphanumeric only'),
  accountTitle: Yup.string().required("Account title is required").matches(/^[a-zA-Z0-9_._ ]*$/, 'Account title must be alphanumeric only'),
};
const phoneScheme = (regex) => {
  return {
    beneficiaryMSISDN: Yup.string().label('Phone Number')
      .test('checkPhoneNumber', (value, obj) => phoneValidateNumberRegex(regex, value || '', obj))
      .required("Phone number is required")
  }
};


const scheme = (type, regex) => {
  return (
    Yup.object().shape({
      ...normalScheme(),
      ...(type === 'BankAccount' ?
        showNumberInBank(type, regex) ? { ...phoneScheme(regex), ...bankScheme } : bankScheme
        : phoneScheme(regex)),
    })
  )
};

const update = Yup.object().shape({
  alias: Yup.string().required("Beneficiary Alias is required").min(3, 'Beneficiary Alias should be at least 3 characters long').matches(/^[aA-zZ\s]+$/, "Beneficiary Alias should be alphabetic only"),
});

const otpScheme = Yup.object().shape({
  otp: Yup.string().label("OTP").required('Please enter user OTP code').min(6, 'Please enter user OTP code').max(6, 'Please enter user OTP code'),
});

const currencyExchangeScheme = Yup.object().shape({
  sender: Yup.number()
      .label("Sender Amount")
      .required("Please enter sender amount.")
      .min(1, "Sender amount grater than 1."),
  receiver: Yup.number()
      .label("Receiver Amount")
      .min(1, "Receiver amount grater than 1."),

});

export { iban, ifsc, scheme, update, otpScheme, currencyExchangeScheme };
