import TEMPLATES from "../constants/Templates.constant";

const initialState = {
    templates: [],
    getLoading: false,
    metaData: {},

    notificationConfig: [],
    getNotificationLoading: false,
    metaDataNotification: {},

    getCreateTemplateLoading: false,
    getAddNotificationConfigLoading: false,

};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case TEMPLATES.GET_TEMPLATE:
            return {
                ...state,
                templates: action.data,
                getLoading: action.loading,
                metaData: action.metaData,
            };
            case TEMPLATES.CREATE_TEMPLATE:
                return {
                    ...state,
                    getCreateTemplateLoading: action.loading
                }
            case TEMPLATES.GET_NOTIFICATION_CONFIG:
                return {
                    ...state,
                    notificationConfig: action.data,
                    getNotificationLoading: action.loading,
                    metaDataNotification: action.metaData,
                };
            case TEMPLATES.ADD_NOTIFICATION_CONFIG:
                return {
                    ...state,
                    getAddNotificationConfigLoading: action.loading
                }

        default:
            return state;
    }
};
