const Admins = {
    GET_API: "GET_API",
    ADD_API: "ADD_API",
    UPDATE_API: "UPDATE_API",

    TOGGLE_STATUS: "TOGGLE_STATUS",

    CREATE_AND_UPDATE_ROLE: "CREATE_AND_UPDATE_ROLE",
    GET_ROLES: "GET_ROLES",
    LOAD_MORE_ROLES: "LOAD_MORE_ROLES",
    GET_ROLE_BY_ID: "GET_ROLE_BY_ID",
    ASSIGN_UNASSIGN_ROLE: "ASSIGN_UNASSIGN_ROLE",
    UPDATE_ROLE_STATUS: "UPDATE_ROLE_STATUS",
};

export default Admins;
