import OFFERS from "../constants/Offers.constant";

const initialState = {
    offers: [],
    getLoading: false,
    metaData: {},

    getCreateOfferLoading: false,

    users: [],
    usersMetaData: {},
    getUsersLoading: false,
    usersLoadMoreLoading: false,
    usersCanLoadMore: true,

    getOffersApplicables: [],
    getOffersApplicablesLoading: false,
    applicablesMetaData: [],
    loadMoreLoading: false,
    canLoadMore: true,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case OFFERS.GET_OFFERS:
            return {
                ...state,
                offers: action.data,
                getLoading: action.loading,
                metaData: action.metaData,
            };
        case OFFERS.CREATE_OFFER:
            return {
                ...state,
                getCreateOfferLoading: action.loading
            }
        case OFFERS.GET_USERS:
            return {
                ...state,
                users: action.data,
                usersMetaData: action.metaData,
                getUsersLoading: action.loading,
                usersLoadMoreLoading: false,
                usersCanLoadMore: true,
            };

        case OFFERS.LOAD_MORE_USERS:
            return {
                ...state,
                users: [...state.users, ...action.data],
                usersMetaData: action.metaData,
                usersLoadMoreLoading: action.loading,
                usersCanLoadMore: action.canLoadMore
            };

        case OFFERS.GET_OFFERS_APPLICABLES:
            return {
                ...state,
                getOffersApplicables: action.data,
                getOffersApplicablesLoading: action.loading,
                canLoadMore: true,
                loadMoreLoading: false,
                applicablesMetaData: action.metaData,
            }
        case OFFERS.LOAD_MORE_APPLICABLES:
            return {
                ...state,
                getOffersApplicables: [...state.getOffersApplicables, ...action.data],
                canLoadMore: action?.canLoadMore,
                loadMoreLoading: action.loading,
                applicablesMetaData: action.metaData
            };

        default:
            return state;
    }
};
