import REMITTANCE from "../constants/Remittance.constant";

const initialState = {

    helloPaisaGetCurrencyLoading: false,
    helloPaisaGetCurrency: {},

    processPaymentLoading: false,

    getTransactionHistoryLoading: false,
    transactionHistoryMetaData: {},
    transactionHistory: [],

    getSingleTransactionHistoryLoading: false,

    cancelTransaction: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case REMITTANCE.GET_HELLO_PAISA_CURRENCY_RATES:
            return {...state, helloPaisaGetCurrencyLoading: action.loading, helloPaisaGetCurrency: action.data };

        case REMITTANCE.PROCESS_PAYMENT:
            return {
                ...state,
                processPaymentLoading: action.loading,
            };

        case REMITTANCE.GET_TRANSACTION_HISTORY:
            return {
                ...state,
                getTransactionHistoryLoading: action.loading,
                transactionHistory: action.data,
                transactionHistoryMetaData: action.metaData,
            };

        case REMITTANCE.GET_HELLO_PAISA_SINGLE_TRANSACTION_HISTORY:
            return {
                ...state,
                getSingleTransactionHistoryLoading: action.loading
            };

        case REMITTANCE.CANCEL_HELLO_PAISA_TRANSACTION:
            return { ...state, cancelTransaction: action.loading };


        default:
            return state;
    }
};
