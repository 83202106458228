

const SALARY_STATUS = {
    GET_SALARY_STATUS: 'GET_SALARY_STATUS',

    GET_SALARY_STATUS_DETAIL: 'GET_SALARY_STATUS_DETAIL',

    DOWNLOAD_SALARY_STATUS_DETAIL: 'DOWNLOAD_SALARY_STATUS_DETAIL',

    SEARCH_EMPLOYEES_TRANSACTION_LIST: 'SEARCH_EMPLOYEES_TRANSACTION_LIST',

    GET_SALARY_COUNT: 'GET_SALARY_COUNT',
};

export default SALARY_STATUS
