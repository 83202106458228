
export const Style = {
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    fullContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        flex: 1
    },

    view: {
        backgroundColor: '#ffffff',
        borderRadius: 23/2,
        width: '100%',
        maxWidth: 500,
        minWidth: 200,
    },
    viewContainer: {
        // paddingBottom: 30,
        paddingHorizontal: 30,
        borderRadius: 23,
        width: '100%',
        backgroundColor: '#ffffff',
    },
    popupHeaderButton: {
        padding: 5,
        alignItems: 'flex-end',
        position: 'absolute',
        right: 15,
        top: 15,
        zIndex: 1
    },
    popupHeaderButtonIcon: {
        fontSize: 20,
        color: '#D5D4D4',
    },
    popupHeader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        zIndex: 1,
        // paddingTop: 30
    },
    popupHeaderImage: {
        width: 145,
        height: 145,
        marginBottom: 20,
        marginTop: 15
    },

    title: {
        fontSize: 16,
        color: '#000080',
        fontWeight: '700',
        textAlign: 'center',
    },
    subTitle: {
        fontSize: 14,
        color: '#565677',
        fontWeight: '400',
    },
    amountTitle: {
        fontSize: 16,
        color: '#000000',
        fontWeight: '700',
        marginTop: 20,
        marginBottom: 5
    },
    amountText: {
        fontSize: 20,
        color: '#000080',
        fontWeight: '700',
        marginBottom: 10
    },
    secondAmount: {
        fontSize: 14,
        color: '#000080',
        fontWeight: '700',
    },
    list: {
        marginTop: 15
    },
    listHeader: {
        marginBottom: 15
    },
    listHeaderText: {
        fontSize: 16,
        color: '#000000',
        fontWeight: '700',
        textAlign: 'left'
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10
    },
    listItem2: {},
    listItemText: {
        flex: 1,
        fontSize: 12,
        color: '#A1A1A1',
        fontWeight: '700',
        textAlign: 'left'
    },
    listItemImage: {
        height: 12,
        width: 90,
    },
    listItemText2: {
        color: '#000000',
    },
    listItemLastText: {
        textAlign: 'right',
        color: '#000000',
        flex: 1,
        marginLeft: 10
    },
    listItemLastText2: {
        fontSize: 14,
        textAlign: 'right',
        color: '#000080',
        textDecorationLine: 'underline'
    },

    popupFooter: {
        display: 'flex',
        flexDirection: 'row',
        paddingVertical: 12,
        // marginTop: -15,
        // paddingBottom: 30,
        borderBottomLeftRadius: 35,
        borderBottomRightRadius: 35
    },

    popupFooterButton: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },

    popupFooterButtonIcon: {
        fontSize: 18,
        color: '#0059F7',
        marginBottom: 10
    },

    popupFooterButtonText: {
        fontSize: 12,
        color: '#757575',
    },


    fontBold: {
        fontWeight: '700',
    },

    separatorContainer: {
        position:'relative'
    },
    confirmInfoListSeparator: {
        height: 0.5,
        borderStyle: 'dashed',
        borderRadius: 1,
        borderWidth: 1,
        backgroundColor: '#ffffff',
        borderColor: '#8c8c8c',
        marginTop: 15,
        marginBottom: 15
    },

    circle: {
        width: 18,
        height: 20,
        backgroundColor: '#A1A1A1',
        position: 'absolute',
        zIndex: 1,
        top: -4
    },
    circleLeft: {
        left: -30,
        borderTopRightRadius: 30,
        borderBottomRightRadius: 30,
    },
    circleRight: {
        right: -30,
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
    },
};
