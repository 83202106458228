import React, {useEffect, useRef, useState} from "react";
import {Table, Tag} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    getQueryFiltersAsObject,
    pushFiltersToQueryString,
    readableText,
    renderDateAndTime,
    renderRangeFilter,
} from "../../../../utils/methods";
import {getTransactionHistory, getSingleTransactionHistory} from "../../../../store/actions/Remittance.action";
import {PageWrapper} from "../../../../containers";
import {useLocation, useNavigate} from "react-router";
import {STATUS, formatAmount, generateSendToInfoForHistory} from "../helper";
import Receipt from "../receipt/Receipt";
import { AiOutlineEye } from "react-icons/ai";

function TrackHistory() {

    const receiptRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const employee = location.state?.employee;
    const client = location.state?.client;
    const mobileUser = location.state?.mobileUser;

    const reduxState = useSelector(({ remittance }) => {
        return {
            data: remittance.transactionHistory,
            metaData: remittance.transactionHistoryMetaData,
            loading: remittance.getTransactionHistoryLoading || remittance.getSingleTransactionHistoryLoading,
        };
    });

    const { data, metaData, loading } = reduxState;

    const [ticketPayload, updateTicketPayload] = useState(null);
    const [isInitial, setIsInitial] = useState(true);
    const [limit] = useState(10);
    const [allFilter, setAllFilter] = useState({});

    useEffect(() => {
        let filters = getQueryFiltersAsObject(location, true);
        if (Object.keys(filters)?.length) {
            setAllFilter(filters);
        } else {
            pushFilter();
        }
        setIsInitial(false);
    }, []);

    useEffect(() => {
        if (!isInitial) {
            pushFilter();
        }
    }, [allFilter]);

    const get = () => {
        let payload = {
            ...allFilter,
            page: allFilter?.page || 1,
            limit: limit,
            client: client?._id,
            employeeId: employee?._id,
        };
        dispatch(getTransactionHistory(payload));
    };

    // push filters on queryString
    const pushFilter = async () => {
        let payload = {
            ...allFilter,
            page: allFilter?.page || 1,
        };
        await pushFiltersToQueryString(payload, navigate, location);
        get();
    };

    const view = (item) => {
        let payload = {...item};
        payload.Currency = 'AED';
        payload.foreignCurrency = item.Currency;
        payload.amountInAED = item?.totalAmount;
        payload.Amount = item?.amount;
        payload.message = STATUS[item?.status].message;
        payload.icon = STATUS[item?.status];
        if(item?.transactionCode && item?.transactionId){
            payload.referenceNo = `${item?.transactionCode}${item?.transactionId}`;
        }
        updateTicketPayload(payload);
        receiptRef?.current?.toggleModal(true)
    };

    const singleTransactionHistoryView = (item) => {
        if(item._id){
            let payload = {
                transactionId: item._id,
            };
            dispatch(getSingleTransactionHistory(payload, (res) => {
                view(res)
            }))
        }
    };


    const COLUMNS = [
        {
            title: "Beneficiary",
            dataIndex: "",
            render: (val, _) => <div className="bold-text break ">{readableText(_.BeneficiaryFirstName)} {readableText(_.BeneficiaryLastName)}</div>,
            width: 120,
        },
        {
            title: "Payout Partner Ref",
            dataIndex: "payoutPartnerRef",
            render: (val, _) => <div className="bold-text break ">{readableText(val)}</div>,
            width: 120,
        },
        {
            title: "Reference #",
            dataIndex: "",
            render: (val, _) => <div className="bold-text break ">{_?.transactionCode}{_?.transactionId}</div>,
            width: 120,
        },
        {
            title: "Amount",
            dataIndex: "totalAmount",
            render: (val) => <div className="bold-text break ">{formatAmount(val, 'AED')}</div>,
            width: 120,
        },
        {
            title: "Platform",
            dataIndex: "fromPlatform",
            render: (val) => <div className="bold-text break ">{readableText(val)}</div>,
            width: 120,
        },
        {
            title: "Created By",
            dataIndex: "createdBy",
            render: (obj, _) => <div className="bold-text break ">{readableText(obj?.user?.name)}</div>,
            width: 120,
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (val, _) => <div className="bold-text break ">
                <Tag color={STATUS[val].bgColor}>
                    {STATUS[val] ? STATUS[val].title : ''}
                </Tag>
            </div>,
            width: 120,
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            render: (val) => <div className="bold-text ">{renderDateAndTime(val)}</div>,
            width: 100,
            ...renderRangeFilter('Select Date', 'from', 'to', allFilter, setAllFilter),
        },
        {
            title: "Action",
            dataIndex: "",
            render: (_, obj) => {
                return (
                    <div className="inner-data no-wrap">
                        <AiOutlineEye
                            className="c-icon large"
                            loading={false}
                            disabled={false}
                            onClick={() => singleTransactionHistoryView(obj)}
                        />
                    </div>
                )
            },
            width: 100,
        },
    ];

    const headerProps = {
        title: "Send Money Track History",
        subTitle: `${employee?.mobileUser?.fullName || mobileUser?.fullName || ''}`,
        canGoBack: true,
    };

    return (
        <PageWrapper headerProps={headerProps}>
            <Table
                className={`c-table layout-auto ${data?.length ? "" : "no-data-table"}`}
                rowKey={(obj) => obj?._id}
                scroll={{ x: "100%" }}
                loading={loading}
                columns={COLUMNS}
                dataSource={data}
                pagination={{
                    total: metaData?.totalDocuments,
                    pageSize: metaData?.limit || 10,
                    showSizeChanger: false,
                    current: metaData?.page,
                    defaultCurrent: metaData?.page || 1,
                    onChange: (page) => setAllFilter({ ...allFilter, page }),
                }}
            />

            <Receipt
                ref={receiptRef}
                data={ticketPayload}
                onClose={() => null}
                refId={ticketPayload?.payoutPartnerRef || ''}
                infoTitle={"Transaction Details"}
                infoUpperTitle=""
                senTo={generateSendToInfoForHistory(ticketPayload)}
                isHistory={true}
            />
        </PageWrapper>
    );
}
export default React.memo(TrackHistory);
