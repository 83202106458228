import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import { Modal } from "antd";
import OtpInput from "react-otp-input";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { otpScheme } from "./Validations";
import { CButton, CField } from "../../../uiComponents";
import { resendOtpMobileApi, verifyOtpMobileApi } from "../../../store/actions/Common.action";

const OTPByRef = forwardRef(({ clientId, employeeId, callBack, skipVerifyOtp = false }, ref) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({common, findCardHolder}) => {
        return {
            loading: common.reSendOtpMobileApiLoading || common.verifyOtpMobileApiLoading || findCardHolder?.addBeneficiaryLoading,
        };
    });

    const { loading } = reduxState;
    const [isOpen, updateIsOpen] = useState(false);
    const [holdingResponse, setHoldingResponse] = useState(null);
    const [counter, setCounter] = useState(0);

    useImperativeHandle(ref, () => ({
            toggleModal(val = false, obj = null) {
                updateIsOpen(val);
                setHoldingResponse(obj);
            }
        }));

    const modalClose = (value = false) => {
        updateIsOpen(value);
        setHoldingResponse(null)
    };


    useEffect(() => {
        setTimeout(() => {
            if (counter > 0) {
                setCounter(counter - 1);
            }
        }, 1000);
    }, [counter]);

    const onResendCallback = (res) => {
        setHoldingResponse(res);
        setCounter(30);
    };

    const onResend = () => {
        if (!loading) {
            let payload = {
                userId: holdingResponse?.userId,
                token: holdingResponse?.token,
            };
            dispatch(resendOtpMobileApi(payload, onResendCallback));
        }
    };

    const onFinish = (values) => {
        if(skipVerifyOtp){
            callBack && callBack({
                token: holdingResponse?.token,
                otp: values?.otp,
                userId: holdingResponse?.userId
            });
            // modalClose();
        } else {
            const payload = {
                client: clientId,
                employeeId: employeeId,
                otp: values?.otp,
                token: holdingResponse?.token
            };
            dispatch(verifyOtpMobileApi(payload, (res) => callBack && callBack({
                token: res?.appUserData?.token,
                otp: res?.otp,
                userId: holdingResponse?.userId
            })))
        }
    };

    return (
        <Modal
            title="Enter OTP to proceed"
            width={450}
            centered
            open={isOpen}
            maskClosable={false}
            keyboard={false}
            closable={!loading}
            onCancel={() => modalClose()}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose
        >
            <Formik
                validationSchema={otpScheme}
                validateOnChange={true}
                validateOnBlur={true}
                initialValues={{}}
                onSubmit={onFinish}
            >
                {({ errors, touched, handleSubmit, setFieldValue, values }) => (
                    <Form>
                        <CField
                            className="otp-container"
                            name="otp"
                            error={errors?.otp}
                            touched={touched?.otp}
                            customField={() => <OtpInput
                                className="otp-field"
                                value={values?.otp}
                                isDisabled={loading}
                                onChange={(val) => setFieldValue('otp', val)}
                                numInputs={6}
                            />}
                        />

                        <CButton
                            containerClass="flex0"
                            onClick={handleSubmit}
                            type="submit"
                            htmlType="submit"
                            title={'Submit'}
                            loading={loading}
                            disabled={loading}
                        />
                    </Form>)}
            </Formik>
            <div style={{ fontSize: 12 }} >
                {counter < 1 ?
                    <div style={{ textAlign: 'center' }} >
                        <span>Didn't Receive Code?</span> <span style={{ color: '#4A58D1', cursor: loading ? 'default' : 'pointer', opacity: loading ? .6 : 1 }} onClick={onResend}>Resend</span>
                    </div> :
                    <div style={{ textAlign: 'center' }} >00:{counter.toString().padStart(2, '0')}</div>}
            </div>
        </Modal>
    );
});
export default React.memo(OTPByRef);
