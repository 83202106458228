import CAMPS from "../constants/Camps.constant";

const initialState = {
    camps: [],
    getLoading: true,
    metaData: {},
    addLoading: false,

    assignEmployeesLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case CAMPS.GET_CAMPS:
            return {
                ...state,
                camps: action.data,
                getLoading: action.loading,
                metaData: action.metaData
            };
        case CAMPS.ADD_CAMPS:
            return {
                ...state,
                addLoading: action.loading
            };

        case CAMPS.ASSIGN_EMPLOYEES:
            return {
                ...state,
                assignEmployeesLoading: action.loading
            };

        default:
            return state;
    }
};