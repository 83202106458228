import React from "react";
import { FaHandHoldingUsd } from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";
import { GiWallet } from "react-icons/gi";
import dayjs from 'dayjs';

export const icons = {
    BankAccount: <BsBank2 className="c-icon secondary" />,
    COTC: <FaHandHoldingUsd className="c-icon secondary" />,
    'Mobile Wallet': <GiWallet className="c-icon secondary" />,
    bank: <GiWallet className="c-icon secondary" />,
};

export const modifyData = (types, specialBanks) => {
    let array = [];
    array.push(...types);
    if (specialBanks?.length) {
        array.push(...specialBanks)
    }
    return array
};

export const isPakistan = (cca2) => cca2 === 'PK';
export const isIndia = (cca2) => cca2 === 'IN';

export const showNumberInBank = (type, country) => {
    let countries = ['IN'];
    return type === 'BankAccount' && (countries?.includes(country?.CCA2) || countries?.includes(country?.cca2) || countries?.includes(country?.iso))
};

export const wallets = ['JazzCash', 'Jazz Cash', 'Easy Paisa'];
export const phWallets = ['GCASH G XCHANGE INC'];


export const numberWithToFix = (val, toFixed = 2) => {
    if (!val && val !== 0) return ''; // Null ya undefined ke liye empty string return karein

    // Handle special characters or invalid numbers
    if (isNaN(val)) {
        return ''; // Return 0 for invalid or dot inputs
    }

    const num = Number(val); // Value ko number mein convert karein

    if (num % 1 === 0) {
        // Agar integer hai, directly integer return karein
        return num.toString();
    } else {
        // Agar decimal part significant hai, to poora show karein
        const fixed = num.toFixed(toFixed);
        return fixed
        // return fixed.replace(/\.?0+$/, ''); // Trailing zeros aur unnecessary dot remove karein
    }
};


export const SEND_MONEY_TYPE = {
    RECEIVER: {
        _id: "RECEIVER"
    },
    SENDER: {
        _id: "SENDER"
    }
};


export const truncateAmount = (val = 0) => {
    return Math.floor(val * 100) / 100
};

export const formatAmount = (value, currency = '', fractionDigits = 2) => {
    if(value){
        let formattedValue = Number(value).toFixed(fractionDigits).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return currency ? `${formattedValue} ${currency}` : formattedValue
    } else {
        return 0
    }
};

export const generateReceiverDetail = (paramObj, paramData) => {
    let info = [];

    let obj = {
        ...(paramObj?.otherDetails && paramObj?.otherDetails),
        ...(paramObj?.bank && {
            BankTypeName: paramObj?.bank?.BankTypeName,
            BankName: paramObj?.bank?.BankName,
            BranchName: paramObj?.bank?.BranchName || '',
        }),
        referenceNo: paramData?.referenceNo,
        ...(paramObj?.currencyInfo && paramObj?.currencyInfo)
    };

    if(obj?.BeneficiaryFirstName) {
        info.push({
            Name: "Receiver",
            value: `${obj?.BeneficiaryFirstName} ${obj?.BeneficiaryLastName}`
        })
    }
    if((showNumberInBank(obj?.bank?.BankType, obj?.country)  || obj?.bank?.BankType !== 'BankAccount') && obj?.BeneficiaryMSISDN) {
        info.push({
            Name: "Receiver Number",
            value: '+' + obj?.BeneficiaryMSISDN
        })
    }

    if(obj?.BankName) {
        info.push({
            Name: "Bank Name",
            value: obj?.BankName
        })
    }

    if(obj?.BranchName) {
        info.push({
            Name: "Branch Name",
            value: obj?.BranchName
        })
    }

    if(obj?.BankTypeName) {
        info.push({
            Name: "Transfer Type",
            value: obj?.BankTypeName
        })
    }

    if(obj?.AccountTitle) {
        info.push({
            Name: "Receiver Account Title",
            value: obj?.AccountTitle
        })
    }

    if(obj?.BeneficiaryAccountNo) {
        info.push({
            Name: "Receiver Account Number",
            value: obj?.BeneficiaryAccountNo
        })
    }

    if(obj?.RemitPurpose) {
        info.push({
            Name: "Remittance Purpose",
            value: obj?.RemitPurpose
        })
    }

    if(obj?.referenceNo) {
        info.push({
            Name: "Reference #",
            value: obj?.referenceNo
        })
    }

    return info
};

export const generateTransferAmountAndCharges = (paramObj) => {
    let info = [];

    let obj = {
        ...(paramObj?.otherDetails && paramObj?.otherDetails),
        ...(paramObj?.bank && {BankTypeName: paramObj?.bank?.BankTypeName}),
        ...(paramObj?.currencyInfo && paramObj?.currencyInfo)
    };
    //
    let receiverCurrency = paramObj?.country?.Currency || paramObj?.country?.currency;
    //
    if(obj?.amountInForeignCurrency) {
        info.push({
            Name: "Transfer Amount",
            value: formatAmount(obj?.amountInForeignCurrency, receiverCurrency || ''),
            separate: true
        })
    }
    //
    if(obj?.totalFee) {
        info.push({
            Name: 'Charges',
            value: `${formatAmount(obj?.totalFee)} AED`
        })
    }
    //
    if(obj?.totalVat) {
        info.push({
            Name: "VAT",
            value: `${formatAmount(obj?.totalVat)} AED`
        })
    }
    //
    if(obj?.countryFee && Number(obj?.countryFee)) {
        info.push({
            Name: 'Receiver Charges',
            value: `${formatAmount(obj?.countryFee)} ${receiverCurrency}`
        })
    }
    //
    if(obj?.promoDetails?.promo) {
        info.push({
            Name: "Promo Code",
            value: obj?.promoDetails?.promo
        })
    }
    //
    if(obj?.mode && obj?.discountAmount) {
        info.push({
            Name: obj?.currencyInfo?.mode === 'DISCOUNT' ? 'Discount' : 'Cashback',
            value: `${obj?.currencyInfo?.mode === 'DISCOUNT' ? '-' : ''} ${formatAmount(obj?.discountAmount)} AED` || 0
        })
    }
    //
    if(obj?.tat) {
        info.push({
            Name: "Turn Around Time",
            value: obj?.tat
        })
    }

    return info
};

export const generateSendToInfo = (obj, paramData) => {
    return [
        ...generateReceiverDetail(obj, paramData),
        ...generateTransferAmountAndCharges(obj),
    ]
};

export const generateSendToInfoForHistory = (obj) => {

    let info = [];

    if(obj?.BeneficiaryFirstName) {
        info.push({
            Name: "Receiver Name",
            value: `${obj?.BeneficiaryFirstName} ${obj?.BeneficiaryLastName}`
        })
    }

    if(obj?.BankType !== "BankAccount" && obj?.BeneficiaryMSISDN) {
        info.push({
            Name: "Receiver Number",
            value: '+' + obj?.BeneficiaryMSISDN
        })
    }

    if(obj?.BankName) {
        info.push({
            Name: "Bank Name",
            value: obj?.BankName
        })
    }

    if(obj?.BranchName) {
        info.push({
            Name: "Branch Name",
            value: obj?.BranchName
        })
    }

    if(obj?.BankTypeName) {
        info.push({
            Name: "Transfer Type",
            value: obj?.BankTypeName
        })
    }

    if(obj?.BankType === "BankAccount" && obj?.AccountTitle) {
        info.push({
            Name: "Receiver Account Title",
            value: obj?.AccountTitle
        })
    }

    if(obj?.BankType === "BankAccount" && obj?.BeneficiaryAccountNo) {
        info.push({
            Name: "Receiver Account Number",
            value: obj?.BeneficiaryAccountNo
        })
    }
    if(obj?.RemitPurpose) {
        info.push({
            Name: "Remittance Purpose",
            value: obj?.RemitPurpose
        })
    }

    if(obj?.referenceNo) {
        info.push({
            Name: "Reference #",
            value: obj?.referenceNo
        })
    }

    if(obj?.amountInForeignCurrency) {
        info.push({
            Name: "Transfer Amount",
            value: `${formatAmount(obj?.amountInForeignCurrency, obj?.foreignCurrency || '')}`,
            separate: true
        })
    }

    if(obj?.totalFee) {
        info.push({
            Name: "Charges",
            value: `${formatAmount(obj?.totalFee)} ${obj?.Currency}`
        })
    }

    if(obj?.totalVat) {
        info.push({
            Name: "VAT",
            value: `${formatAmount(obj?.totalVat)} ${obj?.Currency}`
        })
    }

    if(obj?.countryFee && Number(obj?.countryFee)) {
        info.push({
            Name: "Receiver Charges",
            value: `${formatAmount(obj?.countryFee)} ${obj?.foreignCurrency}`
        })
    }

    if(obj?.promoDetails?.promo) {
        info.push({
            Name: "Promo Code",
            value: obj?.promoDetails?.promo
        })
    }

    if(obj?.mode) {
        info.push({
            Name: obj?.currencyInfo?.mode === 'DISCOUNT' ? 'Discount' : 'Cashback',
            value: `${obj?.currencyInfo?.mode === 'DISCOUNT' ? '-' : ''} ${formatAmount(obj?.discountAmount, obj?.Currency)}` || 0
        })
    }

    if(obj?.tat) {
        info.push({
            Name: "Turn Around Time",
            value: obj?.tat
        })
    }

    if(obj?.createdAt) {
        info.push({
            Name: "Date",
            value: dayjs(obj?.createdAt).format('DD MMM YYYY'),
        });
        info.push({
            Name: "Time",
            value: dayjs(obj?.createdAt).format('hh:mm a'),
        });
    }

    return info
};


export const OTP_TOPIC = {
    TRANSACTION_PURPOSE: "transaction purpose",
    CARD_ACTIVATION: "card activation",
    CARD_PIN_CHANGE: "card pin change",
    ACCOUNT_CREATION: "account creation",
    FORGET_PASSWORD: "loss password",
    UPDATE_MOBILE_NUMBER: "mobile number update",
    RESET_PASSWORD: "reset password",
    ADD_BENEFICIARY: "add beneficiary"
}


export const STATUS = {
    'SUCCESS': {
        title: "Success",
        message: "Success, Your transaction has been processed",
        color: 'white',
        bgColor: '#42B658',
        darkBgColor: '#42B658',
        iconName: 'check'
    },
    'FAILED': {
        title: "Failed",
        message: "Your transaction has not been processed. Please try later",
        color: 'white',
        bgColor: '#EE3B35',
        darkBgColor: '#EE3B35',
        iconName: 'cross'
    },
    'IN_PROGRESS': {
        title: "In Progress",
        message: "Your transaction is under process",
        color: 'white',
        bgColor: '#F59D1E',
        darkBgColor: '#F59D1E',
        iconName: 'refresh'
    },
    'IN_PROGRESS_CANCELLATION': {
        title: "Cancellation In Progress",
        message: "Your transaction is under cancellation",
        color: 'white',
        bgColor: '#86a2c7',
        darkBgColor: '#86a2c7',
        iconName: 'refresh'
    },
    'CANCELLED': {
        title: "Cancelled",
        message: "Your transaction has been Cancelled",
        color: 'white',
        bgColor: '#262031',
        darkBgColor: '#262031',
        iconName: 'refresh'
    }
};
