import { post, get } from "../../utils/apiMethods";
import EmployeesConstant from "../constants/Employees.constant";
import { handleError, handleSuccess } from "../../utils/methods";

export const getEmployees = (payload) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.GET_EMPLOYEES, loading: true });
  post(`employees/getAll`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: EmployeesConstant.GET_EMPLOYEES,
          loading: false,
          metaData: data?.data?.metaData,
          data: data?.data?.entries,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: EmployeesConstant.GET_EMPLOYEES, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.GET_EMPLOYEES, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const clearEmployees = (payload) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.GET_EMPLOYEES, loading: false, metaData: {}, data: [] });
};

export const dissociationAndDisabled = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.DISSOCIATION_DISABLED;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/mobileAppCardDisabledOrDisassociate`, payload)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({ type: dispatchType, loading: false });
        handleError(data?.data?.message || 'Something went wrong!');
      } else {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message || 'Successfully Employer File Uploaded');
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || 'Something went wrong!');
    });
};

export const getEmployeeDetails = (payload) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.GET_EMPLOYEE_DETAILS, loading: true });
  post(`employees/getEmployee`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: EmployeesConstant.GET_EMPLOYEE_DETAILS, loading: false, employeeDetails: data?.data });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: EmployeesConstant.GET_EMPLOYEE_DETAILS, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.GET_EMPLOYEE_DETAILS, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};
export const uploadEmployees = (payload, CB) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.UPLOAD_EMPLOYEE, loading: true });
  post(`clients/uploadEmployees`, payload)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({ type: EmployeesConstant.UPLOAD_EMPLOYEE, loading: false });
        handleError(data?.data?.message || "Something went wrong!");
      } else {
        dispatch({ type: EmployeesConstant.UPLOAD_EMPLOYEE, loading: false });
        handleSuccess(
          data?.data?.message || "Successfully Employees File Uploaded"
        );
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.UPLOAD_EMPLOYEE, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};
export const uploadOtherBankEmployees = (payload, CB) => async (dispatch) => {
  dispatch({
    type: EmployeesConstant.UPLOAD_OTHER_BANK_EMPLOYEES,
    loading: true,
  });
  post(`clients/uploadOtherBankEmployees`, payload)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({
          type: EmployeesConstant.UPLOAD_OTHER_BANK_EMPLOYEES,
          loading: false,
        });
        handleError(data?.data?.message || "Something went wrong!");
      } else {
        dispatch({
          type: EmployeesConstant.UPLOAD_OTHER_BANK_EMPLOYEES,
          loading: false,
        });
        handleSuccess(
          data?.data?.message || "Successfully Employees File Uploaded"
        );
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({
        type: EmployeesConstant.UPLOAD_OTHER_BANK_EMPLOYEES,
        loading: false,
      });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};
export const addEmployees = (payload, CB) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.ADD_EMPLOYEE, loading: true });
  post(`employees/createByClient`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: EmployeesConstant.ADD_EMPLOYEE,
          loading: false,
        });
        handleSuccess(data?.data?.message || "Employee added sucessfully");
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: EmployeesConstant.ADD_EMPLOYEE, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.ADD_EMPLOYEE, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const updateEmployees = (payload, CB) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.UPDATE_EMPLOYEE, loading: true });
  post(`employees/update`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: EmployeesConstant.UPDATE_EMPLOYEE,
          loading: false,
        });
        handleSuccess(data?.data?.message || "Employee added sucessfully");
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: EmployeesConstant.UPDATE_EMPLOYEE, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.UPDATE_EMPLOYEE, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const changeBank = (payload, CB) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.CHANGE_BANK_TYPE, loading: true });
  post(`employees/changeType`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: EmployeesConstant.CHANGE_BANK_TYPE, loading: false });
        handleSuccess(data?.data?.message || "Bank Changed sucessfully");
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: EmployeesConstant.CHANGE_BANK_TYPE, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.CHANGE_BANK_TYPE, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};
export const blockActiveRequest = (payload, CB) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.BLOCK_ACTIVE_REQUEST, loading: true });
  post(`employees/activation`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: EmployeesConstant.BLOCK_ACTIVE_REQUEST,
          loading: false,
        });
        handleSuccess(data?.data?.message || "Card Blocked sucessfully");
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({
          type: EmployeesConstant.BLOCK_ACTIVE_REQUEST,
          loading: false,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: EmployeesConstant.BLOCK_ACTIVE_REQUEST,
        loading: false,
      });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const blockAppApi = (payload, CB) => async (dispatch) => {
  let dispatchType = ''
  if (payload?.isApplicationBlocked) {
    dispatchType = EmployeesConstant.BLOCK_APP;
  } else if (!payload?.isApplicationBlocked) {
    dispatchType = EmployeesConstant.ACTIVATE_APP;
  }
  dispatch({ type: dispatchType, loading: true });
  post(`employees/blockApplication`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const getEmployeesTransactions = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.GET_EMPLOYEE_TRANSACTION;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/getTransaction`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        if (data?.data?.message) {
          dispatch({ type: dispatchType, loading: false, data: [], metaData: {} });
        } else {
          dispatch({ type: dispatchType, loading: false, metaData: data?.data?.metaData, data: data?.data?.transactions });
        }
        CB && CB(data?.data?.message);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({
          type: dispatchType,
          loading: false,
        });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false, });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const getEmployeesTransactionsOtherBank = (payload) => async (dispatch) => {
  dispatch({
    type: EmployeesConstant.GET_EMPLOYEE_TRANSACTION_OTHER_BANK,
    loading: true,
  });
  post(`employees/getOtherBankTransaction`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: EmployeesConstant.GET_EMPLOYEE_TRANSACTION_OTHER_BANK,
          loading: false,
          metaData: data?.data?.metaData,
          data: data?.data?.transactions,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({
          type: EmployeesConstant.GET_EMPLOYEE_TRANSACTION_OTHER_BANK,
          loading: false,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: EmployeesConstant.GET_EMPLOYEE_TRANSACTION_OTHER_BANK,
        loading: false,
      });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const clearTransactions = () => async (dispatch) => {
  dispatch({ type: EmployeesConstant.GET_EMPLOYEE_DETAILS, loading: false, employeeDetails: {} });
  dispatch({ type: EmployeesConstant.GET_EMPLOYEE_TRANSACTION_OTHER_BANK, loading: false, data: [], metaData: {} });
  dispatch({ type: EmployeesConstant.GET_EMPLOYEE_TRANSACTION, loading: false, data: [], metaData: {} });
};

export const downloadEmployeesList = (payload) => (dispatch) => {
  dispatch({ type: EmployeesConstant.DOWNLOAD_LIST, loading: true });
  post(`employees/csv`, payload)
    .then(({ data }) => {
      dispatch({ type: EmployeesConstant.DOWNLOAD_LIST, loading: false });
      if (!data.error) {
        let link = document.createElement("a");
        let d = new Date();
        link.download = `employess-list-${d.toISOString()}.csv`;
        let blob = new Blob([data], { type: "text/plain" }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
        dispatch({
          type: EmployeesConstant.DOWNLOAD_LIST,
          loading: false,
        });
      } else {
        handleError("error", data?.data?.message || "Something went wrong!");
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.DOWNLOAD_LIST, loading: false });
      handleError(
        error?.data?.message ||
        error?.response?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const replaceCard = (payload, CB) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.REPLACE_CARD, loading: true });
  post(`employees/cardReplacement`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: EmployeesConstant.REPLACE_CARD,
          loading: false,
        });
        handleSuccess(data?.data?.message || "Application blocked sucessfully");
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: EmployeesConstant.REPLACE_CARD, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.REPLACE_CARD, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const syncEmployees = (payload, CB) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.SYNC_EMPLOYEES, loading: true });
  post(`admins/getEmp`, payload)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({ type: EmployeesConstant.SYNC_EMPLOYEES, loading: false });
        handleError(data?.data?.message || "Something went wrong!");
      } else {
        dispatch({ type: EmployeesConstant.SYNC_EMPLOYEES, loading: false });
        if (!payload?.productid) {
          handleSuccess(data?.data?.message || "Successfully Syncronized Employees");
        }
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.SYNC_EMPLOYEES, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const changePIN = (payload, CB) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.CHANGE_PIN, loading: true });
  post(`employees/changepin`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: EmployeesConstant.CHANGE_PIN, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: EmployeesConstant.CHANGE_PIN, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.CHANGE_PIN, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const getBalance = (payload, CB) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.GET_BALANCE, loading: true });
  post(`employees/getEmployeeBalance`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: EmployeesConstant.GET_BALANCE, loading: false });
        CB && CB(data?.data?.employeeBalance);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: EmployeesConstant.GET_BALANCE, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.GET_BALANCE, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const checkCardStatus = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.CHECK_CARD_STATUS;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/getEmployeeCardStatus`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        CB && CB(data?.data);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const getAppTransactions = (payload, isAppUser = true) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.GET_APP_TRANSACTIONS, loading: true });
  let url = isAppUser ? `employees/getAppBankTransactions` : `employees/getPayDTransaction`;
  post(`${url}`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: EmployeesConstant.GET_APP_TRANSACTIONS,
          loading: false,
          data: data?.data?.entries || data?.data?.data,
          metaData: data?.data?.metaData,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({
          type: EmployeesConstant.GET_APP_TRANSACTIONS,
          loading: false,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: EmployeesConstant.GET_APP_TRANSACTIONS,
        loading: false,
      });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};
export const updateMolEid = (payload, CB) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.EDIT_EMPLOYEE, loading: true });
  post(`employees/updateEmpMolEstId`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: EmployeesConstant.EDIT_EMPLOYEE,
          loading: false,
        });
        handleSuccess(data?.data?.message || "Bank Changed sucessfully");
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: EmployeesConstant.EDIT_EMPLOYEE, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.EDIT_EMPLOYEE, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const updateMobileNumber = (payload, CB) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.EDIT_EMPLOYEE, loading: true });

  post(`employees/updatePhone`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: EmployeesConstant.EDIT_EMPLOYEE,
          loading: false,
        });
        handleSuccess(data?.data?.message || "Bank Changed sucessfully");
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: EmployeesConstant.EDIT_EMPLOYEE, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.EDIT_EMPLOYEE, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const updateMobileNumberDirect = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.EDIT_EMPLOYEE;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/phoneNumberDirectUpdate`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const updateEid = (payload, CB) => async (dispatch) => {

  dispatch({ type: EmployeesConstant.UPDATE_EID, loading: true });
  post(`employees/eidUpdate`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: EmployeesConstant.UPDATE_EID, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: EmployeesConstant.UPDATE_EID, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.UPDATE_EID, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const getKamelPayEmployeesTemplate = (payload) => (dispatch) => {
  let link = document.createElement("a");
  let d = new Date();
  link.download = `kamelpay-employees-template-${d.toISOString()}.csv`;
  link.href = "https://static-medias.s3.ap-southeast-1.amazonaws.com/kamelpayEmployeesUpload.xlsx"
  link.click();
  URL.revokeObjectURL(link.href);
};

export const employeeStatementRequest = (payload, CB) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.EMPLOYEE_STATEMENT_REQUEST, loading: true });
  post(`employees/statementRequest`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: EmployeesConstant.EMPLOYEE_STATEMENT_REQUEST,
          loading: false,
        });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: EmployeesConstant.EMPLOYEE_STATEMENT_REQUEST, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.EMPLOYEE_STATEMENT_REQUEST, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const deleteDuplication = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.DELET_DUPLICATION;
  dispatch({ type: dispatchType, loading: true });
  post(`admins/deleteDuplicates`, payload)
    .then(async ({ data }) => {
      if (data?.error) {
        dispatch({ type: dispatchType, loading: false });
        handleError(data?.data?.message || "Something went wrong!");
      } else {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message || "Duplicate Users delete successfully!");
        CB && CB();
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const emptyAppTransaction = () => async (dispatch) => {
  dispatch({ type: EmployeesConstant.GET_APP_TRANSACTIONS, loading: false, data: [], metaData: {} });
};

export const cardActiveOrBlockRequest = (payload, CB) => async (dispatch) => {
  dispatch({ type: EmployeesConstant.CARD_ACTIVATE_OR_BLOCK, loading: true });
  post(`employees/cardActiveOrBlock`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: EmployeesConstant.CARD_ACTIVATE_OR_BLOCK,
          loading: false,
        });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: EmployeesConstant.CARD_ACTIVATE_OR_BLOCK, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: EmployeesConstant.CARD_ACTIVATE_OR_BLOCK, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const sendEIDToOCR = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.OCR_EID;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/eidUploadToOcr`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        // handleSuccess(data?.data?.message);
        CB && CB(data?.data);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const getEIDFullDetail = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.GET_EID_FULL_DETAIL;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/getEidDetails`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        CB && CB(data?.data);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const updateDirectEid = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.UPDATE_DIRECT_EID;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/eidDirectUpdate`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message || 'EID Updated Successfully');
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const inactiveEmployees = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.INACTIVE_EMPLOYEES;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/inActiveOrActiveEmployees`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const activeEmployees = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.ACTIVE_EMPLOYEES;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/inActiveOrActiveEmployees`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(
        error?.response?.data?.message ||
        error?.data?.message ||
        "Something went wrong!"
      );
    });
};

export const checkSubscriptionStatus = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.CHECK_SUBSCRIPTION_STATUS;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/getEmployeeSubscription`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        CB && CB(data?.data?.status);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const whiteBlackListing = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.WHITELIST_BLACKLIST;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/updateSubscription`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: dispatchType,
          loading: false,
        });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const switchToKamelpay = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.SWITCH_TO_KAMELPAY;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/changeTypeToKamelpay`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message || "Application blocked sucessfully");
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const createMobileAppUser = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.CREATE_MOBILE_APP_USER;
  dispatch({ type: dispatchType, loading: true, data: {} });
  post(`employees/createMobileAppUser `, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data?.appUserData });
        if (data?.data?.appUserData?.message) {
          handleSuccess(data?.data?.appUserData?.message);
        }
        CB && CB(data?.data?.appUserData);
      } else {
        handleError(data?.data?.appUserData?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const reSendMobileAppUser = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.RESEND_OTP_MOBILE_APP_USER;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/resendOtpAppUser `, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.appUserData?.message);
        CB && CB(data?.data?.appUserData);
      } else {
        handleError(data?.data?.appUserData?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const verifyMobileAppUser = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.VERIFY_OTP_MOBILE_USER;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/mobileUserOtpValidate`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.appUserData?.message);
        CB && CB();
      } else {
        handleError(data?.data?.appUserData?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const getOCRToken = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.GET_OCR_TOKEN;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/getOcrTokenForMobileApp`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data?.appUserData });
        // handleSuccess(data?.data?.appUserData?.message);
        CB && CB(data?.data?.appUserData);
      } else {
        handleError(data?.data?.appUserData?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const uploadUqudoImages = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.UPLOAD_UQUDO_IMAGES;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/mobileUserValidateUqudu`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.appUserData?.message);
        CB && CB();
      } else {
        handleError(data?.data?.appUserData?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const UpdateEidMobileUser = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.UPLOAD_UQUDO_IMAGES;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/eidUpdateMobileApp`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.appUserData?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const clearState = () => async (dispatch) => {
  dispatch({ type: EmployeesConstant.CREATE_MOBILE_APP_USER, loading: false, data: {} });
  dispatch({ type: EmployeesConstant.GET_OCR_TOKEN, loading: false, data: {} });

};

export const checkAdvanceSalaryEligibility = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.CHECK_ADVANCE_SALARY_ELIGIBILITY;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/checkAdvanceSalaryEligibility`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data });
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const applyPromomAndOffer = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.APPLY_PROMO_AND_OFFER;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/applyPromoCodeAndOfferAdvanceSalary`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data });
        CB && CB(data);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const applyAdvanceSalary = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.APPLY_ADVANCE_SALARY;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/applyForAdvanceSalary`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.appUserData?.message)
        CB && CB(data?.data?.appUserData);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const verifyOTPAdvSalary = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.VERIFY_OTP_ADV_SALARY;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/verifyOtpForAdvanceSalary `, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.appUserData?.message);
        CB && CB(data?.data?.appUserData);
      } else {
        handleError(data?.data?.appUserData?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const verifyAdvSalary = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.VERIFY_ADV_SALARY;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/verifyAdvanceSalary `, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message || data?.data?.appUserData?.message);
        CB && CB({ type: 'success', description: data?.data?.message || data?.data?.appUserData?.message });
      } else {
        handleError(data?.data?.appUserData?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      CB && CB({ type: 'error', description: error?.data?.message });
    });
};

export const updateDOB = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.EDIT_EMPLOYEE;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/updateDateOfBirth`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const updateMobileUserPasswordOTP = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.UPDATE_MOBILE_USER_PASSWORD_OTP;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/mobileAppUserPasswordResetOTP`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data });
        handleSuccess(data?.data?.message);
        CB && CB(data?.data);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const updateMobileUserPassword = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.UPDATE_MOBILE_USER_PASSWORD;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/changeMobileAppUserPassword`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const updateMobileUserPhoneOTP = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.UPDATE_MOBILE_USER_PHONE_OTP;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/changePhoneNumberOTPRequest`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data });
        handleSuccess(data?.data?.message);
        CB && CB(data?.data);
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const updateMobileUserPhone = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.UPDATE_MOBILE_USER_PHONE;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/changePhoneNumberVerifyOTP`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const uploadKYC = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.UPLOAD_KYC;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/updateKYCDocument`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const getOTPEidBypass = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.GET_OTP_BYPASS_EID;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/eidScanBypassOTP`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        // handleSuccess(data?.data?.message);
        CB && CB(data?.data?.otp);
      } else {
        handleError(data?.data?.appUserData?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const lightUserToPortalUser = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.LIGHT_USER_TO_PORTAL_USER;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/lightToPortalUserChange`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB(data?.data?.otp);
      } else {
        handleError(data?.data?.appUserData?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const updateNationality = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.EDIT_EMPLOYEE;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/updateNationality`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const updateEmpCode = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.EDIT_EMPLOYEE;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/updateEmpCode`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const addPassport = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.ADD_PASSPORT;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/addEditPassport`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const reSendOTPAdvanceSalary = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.RESEND_OTP_ADV_SALARY;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/resendAdvanceSalaryOTP `, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB(data?.data?.appUserData);
      } else {
        handleError(data?.data?.appUserData?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const processBulkDeactivationFile = (payload, CB) => (dispatch) => {
  const dispatchType = EmployeesConstant.PROCESS_BULK_DEACTIVATION_FILE;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/processEmployeesForInActiveOrActive`, payload)
    .then(({ data }) => {
      dispatch({ type: dispatchType, loading: false });
      if (!data.error) {
        CB && CB({ validEmployees: data?.data?.validEmployees, invalidEmployees: data?.data?.invalidEmployees, validEmployeeIds: data?.data?.validEmployeeIds });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.data?.message || error?.response?.data?.message || "Something went wrong!");
    });
};

export const deactivateBulkEmployees = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.BULK_EMPLOYEES_DEACTIVATION;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/submitEmployeesForInActiveOrActive`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const processBulkActivationFile = (payload, CB) => (dispatch) => {
  const dispatchType = EmployeesConstant.PROCESS_BULK_DEACTIVATION_FILE;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/processEmployeesForInActiveOrActive`, payload)
    .then(({ data }) => {
      dispatch({ type: dispatchType, loading: false });
      if (!data.error) {
        CB && CB({ validEmployees: data?.data?.validEmployees, invalidEmployees: data?.data?.invalidEmployees, validEmployeeIds: data?.data?.validEmployeeIds });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.data?.message || error?.response?.data?.message || "Something went wrong!");
    });
};

export const activateBulkEmployees = (payload, CB) => async (dispatch) => {
  const dispatchType = EmployeesConstant.BULK_EMPLOYEES_DEACTIVATION;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/submitEmployeesForInActiveOrActive`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const getCNPL = (payload) => async (dispatch) => {
  const dispatchType = EmployeesConstant.GET_CNPL;
  dispatch({ type: dispatchType, loading: true });
  post(`clients/getCnplDetailsByCompanyOrEmployee`, payload)
    .then(async ({ data }) => {
      if (!data.error) {
        dispatch({
          type: dispatchType,
          loading: false,
          metaData: data?.data?.metaData,
          data: data?.data?.entries,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const getPersonalLoanDetails = (payload) => async (dispatch) => {
  const dispatchType = EmployeesConstant.GET_PERSONAL_LOAN;
  dispatch({ type: dispatchType, loading: true });
  post(`clients/getPersonalLoadDetails`, payload)
    .then(async ({ data }) => {
      console.log(data, "GET_PERSONAL_LOAN")
      if (!data.error) {
        dispatch({
          type: dispatchType,
          loading: false,
          metaData: data?.data?.metaData,
          data: data?.data?.entries,
        });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false });
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
    });
};

export const processEmployeesFile = (payload, CB) => (dispatch) => {
  const dispatchType = EmployeesConstant.PROCESS_EMPLOYEES_FILE;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/processEmployeesForInActiveOrActive`, payload)
    .then(({ data }) => {
      dispatch({ type: dispatchType, loading: false });
      if (!data.error) {
        CB && CB({ validEmployees: data?.data?.validEmployees, invalidEmployees: data?.data?.invalidEmployees, validEmployeeIds: data?.data?.validEmployeeIds });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false });
      handleError(error?.data?.message || error?.response?.data?.message || "Something went wrong!");
    });
};