import React, { useEffect, useState } from "react";
import { memo } from "react";
import { formatter, getQueryFiltersAsObject, getStringFromBool, pushFiltersToQueryString, renderDate } from "../../../utils/methods";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Table } from "antd";
import { PageWrapper } from "../../../containers";
import { getPersonalLoanDetails } from "../../../store/actions/Employees.action";
import { renderTag } from "./helper";

function PersonalLoan() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const employee = location.state?.employee;
  const client = location.state?.client;

  const [limit] = useState(10);
  const [allFilter, setAllFilter] = useState({});
  const [isInitial, setIsInitial] = useState(true);

  const reduxState = useSelector(({ employees }) => {
    return {
      data: employees?.personalLoan,
      metaData: employees?.personalLoanMetaData,
      loading: employees?.getPersonalLoanLoading,
    };
  });

  useEffect(() => {
    // get filters from query string and reflect on site
    let filters = getQueryFiltersAsObject(location, allFilter);
    setAllFilter({
      ...filters,
      page: filters?.page ? filters?.page : 1,
    });
    setIsInitial(false)
  }, []);

  useEffect(() => {
    if (!isInitial && Object.keys(allFilter).length) {
      pushFilter();
    }
  }, [allFilter]);

  const pushFilter = async () => {
    let payload = {
      page: allFilter?.page || 1,
    };
    await pushFiltersToQueryString(payload, navigate, location);
    get();
  };

  const get = () => {
    let payload = {
      ...allFilter,
      page: allFilter?.page || 1,
      client: client?._id,
      kamelpayCustomerId: employee?.kamelpayCustomerId,
      limit: limit
    };
    dispatch(getPersonalLoanDetails(payload));
  };

  const headerProps = {
    title: `${employee?.displayName}'s Persoanl Loan Details`,
    subTitle: "",
    canGoBack: true,
  };

  const COLUMNS = [
    {
      title: "BNPL Id",
      dataIndex: "bnplId",
      render: (val) => val || '-',
      width: 50
    },
    {
      title: "Customer Id",
      dataIndex: "customerId",
      render: (val) => <div className="bold-text ">{val}</div>,
      width: 100,
    },
    {
      title: "Employee Id",
      dataIndex: "empId",
      render: (val) => val || '-',
      width: 100,
    },
    {
      title: "Employee Name",
      dataIndex: "empName",
      render: (val) => val || '-',
      width: 100,
    },
    {
      title: "Monthly Processing Fee",
      dataIndex: "monthlyProcessingFee",
      render: (val) => <div className="bold-text ">{formatter(val)}</div>,
      width: 100,
    },
    {
      title: "No Of Installment",
      dataIndex: "noOfInstallment",
      render: (val) => val || '-',
      width: 100,
    },
    {
      title: "Loan Status",
      dataIndex: "loanStatus",
      render: renderTag,
      width: 100,
    },
    {
      title: "Platform Fees",
      dataIndex: "platformFees",
      render: (val) => <div className="bold-text ">{formatter(val)}</div>,
      width: 100,
    },
    {
      title: "Processing Fees",
      dataIndex: "processingFees",
      render: (val) => <div className="bold-text ">{formatter(val)}</div>,
      width: 100,
    },
    {
      title: "Is Completed",
      dataIndex: "isCompleted",
      render: (val) => renderTag(getStringFromBool(val)),
      width: 100,
    },
    {
      title: "Recovered Amount",
      dataIndex: "recoveredAmount",
      render: (val) => <div className="bold-text ">{formatter(val)}</div>,
      width: 100,
    },
    {
      title: "Recovery Status",
      dataIndex: "recoveryStatus",
      render: renderTag,
      width: 100,
    },
    {
      title: "Rejected Date",
      dataIndex: "rejectedDate",
      render: (val) => <div className="bold-text">{val ? renderDate(val) : '-'}</div>,
      width: 100,
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      render: (val) => <div className="bold-text ">{formatter(val)}</div>,
      width: 100,
    },
  ];

  return (
    <>
      <PageWrapper headerProps={headerProps}
        loadingWithOverlay={true}
        loading={reduxState?.loading}
        emptyText={"No Data"}
      >
        <Table
          className={`c-table layout-auto ${reduxState?.data?.length ? "" : "no-data-table"}`}
          rowKey={(obj) => obj?.bnplId}
          scroll={{ x: '100%' }}
          defaultExpandAllRows={false}
          loading={reduxState?.loading}
          columns={COLUMNS}
          dataSource={reduxState.data}
          pagination={{
            total: reduxState?.metaData?.totalDocuments,
            pageSize: limit,
            showSizeChanger: false,
            current: reduxState?.metaData?.page,
            defaultCurrent: allFilter?.page,
            onChange: (page) => setAllFilter({ ...allFilter, page })
          }}
        />
      </PageWrapper>
    </>
  );
}

export default memo(PersonalLoan);