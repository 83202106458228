import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {MappedElement} from "../../../../utils/methods";
import {CButton} from "../../../../uiComponents";
import dayjs from "dayjs";
import {Style} from "./Receipt.style";
import html2canvas from "html2canvas";
import {Modal} from "antd";

const Receipt = forwardRef((props, ref) => {

    const {refId, infoTitle = "Sent to", data, senTo = [], isHistory = false, fractionDigits = 2, isCreditPay = false,
            onClose, infoUpperTitle = "Total Amount", amountTitle = "Total Amount"} = props;

    const ref2 = useRef();

    const [isOpen, updateIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        toggleModal(val = false) {
            updateIsOpen(val);
        }
    }));

    const modalClose = (value = false) => {
        updateIsOpen(value);
        onClose && onClose()
    };

    const renderItem = (obj, i) => {
        return (
            <div key={i} style={{...Style.listItem, ...(obj?.isTitle ? Style.listItem2 : {})}}>
                <span style={{...Style.listItemText, ...(obj?.isTitle ? Style.listItemText2 : {})}}>
                    {obj.Name}
                </span>
                <span style={{...Style.listItemText, ...(obj?.isTitle ? Style.listItemLastText2 : Style.listItemLastText)}}>
                    {obj.value}
                </span>
            </div>
        )
    };

    const captureAndSaveScreenShot = async () => {
        try {
            const element = ref2?.current;
            if (element) {
                // console.log('element', element)
                const canvas = await html2canvas(element, { scale: 2 }); // High-quality capture
                const dataUrl = canvas.toDataURL('image/png');
                // console.log('Captured Image Data URL:', dataUrl, data);
                const link = document.createElement('a');
                link.href = dataUrl;
                link.target = "_blank";
                link.download = `${data?.referenceNo}_RECEIPT_${Date.now()}.png`;
                link.click();
                // modalClose()
                // You can now use the `dataUrl` (e.g., download or display it)
            }
        } catch (error) {
            console.error('Error capturing view on web:', error);
        }
    };

    return (
        <Modal
            title=""
            width={500}
            centered
            open={isOpen}
            maskClosable={false}
            keyboard={false}
            // closable={!closeable}
            onCancel={() => modalClose()}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            style={{padding: 0}}
            destroyOnClose
        >
            <div style={{...Style.viewContainer}}>
              <div style={{margin: '-20px -25px -10px', padding: "25px"}} ref={ref2}>
                  <div style={{...Style.popupHeader}}>
                      <img src={require('../../../../assets/images/payment-successful.png')} style={{...Style.popupHeaderImage}}/>
                      {data?.message ? <span style={{...Style.title}}>{data?.message}</span> : null}
                      <span style={{...Style.amountTitle}}>{amountTitle}</span>
                      <span style={{...Style.amountText}}> {data?.amountInAED ? Number(data?.amountInAED).toFixed(fractionDigits) : 0} <span>AED</span></span>
                  </div>
                  <div style={{...Style.list}}>
                      {infoUpperTitle ? <div style={{...Style.listItem}}>
                          <span style={{...Style.listItemText}}>{infoUpperTitle}</span>
                          <span style={{...Style.listItemText, ...Style.listItemLastText, ...Style.secondAmount}}>
                            {data?.Amount} <span>{data?.Currency}</span>
                        </span>
                      </div> : null}
                      {infoTitle ? <div style={{...Style.listHeader}}>
                          <span style={{...Style.listHeaderText}}>{infoTitle}</span>
                      </div> : null}
                      {refId ? <div style={{...Style.listItem}}>
                          <div style={{display: 'flex', flex: 1, justifyContent: 'flex-start', flexDirection: 'column'}}>
                            <span style={{...Style.listItemText, ...Style.fontBold}}>
                                Payment Reference ID / Control Pin
                            </span>
                              <img
                                  src={require('../../../../assets/images/hellopaisa-logo.png')}
                                  style={{...Style.listItemImage}}
                              />
                          </div>
                          <span style={{...Style.listItemText, ...Style.listItemLastText, ...Style.fontBold}}>
                            {refId}
                        </span>
                      </div> : null}
                      <MappedElement
                          data={senTo}
                          renderElement={(obj, i) => {
                              return(
                                  <div key={i}>
                                      {obj?.separate ? <div style={{...Style.separatorContainer}}>
                                          {!isHistory ? <div>
                                              <div style={{...Style.listItem}}>
                                                  <span style={{...Style.listItemText}}>Date</span>
                                                  <span style={{...Style.listItemText, ...Style.listItemLastText}}>
                                                    {dayjs().format('DD MMM YYYY')}
                                                </span>
                                              </div>
                                              <div style={{...Style.listItem}}>
                                                  <span style={{...Style.listItemText}}>Time</span>
                                                  <span style={{...Style.listItemText, ...Style.listItemLastText}}>
                                                    {dayjs().format('hh:mm a')}
                                                </span>
                                              </div>
                                          </div> : null}
                                          <div style={{...Style.separatorContainer, ...Style.confirmInfoListSeparator}} />
                                      </div> : null}
                                      {renderItem(obj, i)}
                                  </div>
                              )
                          }}
                      />
                  </div>
              </div>
                <div style={{...Style.popupFooter}} id="footer">
                    <CButton
                        containerClass='margin-right_zero margin-bottom_zero'
                        title={"Save"}
                        onClick={captureAndSaveScreenShot}/>
                </div>
            </div>
        </Modal>
    );
});

export default React.memo(Receipt);
