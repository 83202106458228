import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Card, List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { getBeneficiaryCountries } from "../../../store/actions/FindCardHolder.action";
import { formatter, readableTextCap, searchView } from "../../../utils/methods";
import { debounce } from "lodash";
const { Meta } = Card;

const SelectCountry = ({ onSelect, client, employee }) => {

  const dispatch = useDispatch();

  const reduxState = useSelector(({ findCardHolder }) => {
    return {
      data: findCardHolder?.countries,
      specialCountries: findCardHolder?.specialCountries,
      metaData: findCardHolder?.countriesMetaData,
      loading: findCardHolder?.getCountriesLoading,
      loadMoreLoading: findCardHolder?.loadMoreLoading,
      canLoadMore: findCardHolder?.canLoadMore,
    };
  });

  const { data, specialCountries, metaData, loading, loadMoreLoading, canLoadMore } = reduxState;

  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [search, setSearch] = useState('');

  useEffect(() => {
    get(page);
  }, [page, search]);

  const filtersDebounce = useCallback(
    debounce((e) => {
      try {
        dispatch(getBeneficiaryCountries(e));
      } catch (error) { }
    }, 800),
    []
  );

  const get = (val) => {
    const payload = {
      page: val || 1,
      limit,
      client: client?._id,
      employeeId: employee?._id,
      ...(search && { search })
    };
    if (search?.length) {
      filtersDebounce(payload)
    } else {
      dispatch(getBeneficiaryCountries(payload));
    }
  };

  const next = () => {
    if (metaData?.totalDocuments > data?.length) {
      setPage(page + 1)
    }
  };

  const onSearch = (val) => {
    setSearch(val);
    setPage(1);
  };

  return (
    <>
      <List
        grid={{
          column: 2,
        }}
        dataSource={specialCountries}
        loading={loading}
        loadMore={loadMoreLoading}
        className="ant-grid-item-countries"
        renderItem={(item) => (
          <Card
            hoverable
            cover={<Avatar shape="square" className="avatar-contain" size={"default"} src={item?.FlagPng} />}
            onClick={() => onSelect(item, 1)}
          >
            <Meta title={readableTextCap(item?.Name)} description={`${formatter(1)} = ${formatter(item?.singleAmountUnit, item?.Currency)}`} />
          </Card>
        )}
      />

      <h3 className="ant-modal-title margin-top_15" >Select Other Countries</h3>

      {searchView(search, onSearch, 'margin-top_15 margin-bottom_15')}

      <InfiniteScroll
        className={`infinite-scroll ${loading && 'loading'}`}
        dataLength={data?.length || 0}
        next={next}
        height={400}
        hasMore={canLoadMore}
        loader={loadMoreLoading && canLoadMore && <Skeleton className="margin-top_15 margin-left_20"
          avatar
          round={false}
          paragraph={{ rows: 0 }}
          active
        />}
      >
        <List
          dataSource={data}
          loading={loading}
          loadMore={loadMoreLoading}
          className="ant-list-item-countries"
          renderItem={(item) => (
            <List.Item key={item?.CCA2} onClick={() => onSelect(item, 1)} >
              <List.Item.Meta
                avatar={<Avatar shape="square" className="avatar-contain" src={item?.FlagPng} />}
                title={readableTextCap(item?.Name)}
                description={`${formatter(1)} = ${formatter(item?.singleAmountUnit, item?.Currency)}`}
              />
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </>
  );
};

export default React.memo(SelectCountry);
