import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Image, Modal } from "antd";
import { readableTextCap } from "../../utils/methods";
const success = require('../../assets/images/accept-icon.png');
const error = require('../../assets/images/error-icon.png');
const warning = require('../../assets/images/warning-icon.png');
const info = require('../../assets/images/info-icon.png');

const CResult = forwardRef(({ type = '', description, onCancel }, ref) => {

    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState(description);

    useImperativeHandle(ref, () => ({
        toggleModal(val = false, title = '', message = '') {
            setIsOpen(val);
            setTitle(title);
            setMessage(message);
        },
        setIsOpen,
        setMessage
    }));

    const getImage = () => {
        if (type === 'success') {
            return success;
        }
        if (type === 'warning') {
            return warning;
        }
        if (type === 'error') {
            return error;
        }
        if (type === 'info') {
            return info;
        }
    };

    const onCancelHandler = () => {
        setIsOpen(false);
        onCancel && onCancel();
    };

    return <Modal
        title=''
        width={450}
        centered
        open={isOpen}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={onCancelHandler}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
    >
        <div className="result-container">
            <Image src={getImage()} className="result-image" preview={false} />
            <div className="result-body" >
                <h1 className="result-title" >{title || readableTextCap(type)}</h1>
                <p className="result-subtitle" >{message}</p>
            </div>
        </div>
    </Modal>
});

export default CResult;
