import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { update } from "./Validations";
import { CButton, CField } from "../../../uiComponents";
import { Modal } from "antd";
import { editBeneficiary } from "../../../store/actions/FindCardHolder.action";

const UpdateForm = ({ isOpen, setIsOpen, selected, get }) => {

    const dispatch = useDispatch();
    const form = useRef(null);

    const reduxState = useSelector(({ findCardHolder }) => {
        return {
            loading: findCardHolder?.editBeneficiaryLoading,
        };
    });

    const { loading } = reduxState;

    const callBack = () => {
        get && get();
        setIsOpen(false);
    };

    const onFinish = (values) => {
        const payload = {
            id: selected?._id,
            ...values
        };
        dispatch(editBeneficiary(payload, callBack));
    };

    return (
        <Modal
            title={'Edit Beneficiary'}
            width={450}
            centered
            open={isOpen}
            maskClosable={false}
            closable={!loading}
            onCancel={() => setIsOpen(false)}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose
        >
            <Formik
                innerRef={form}
                initialValues={{
                    alias: selected?.beneficiaryAlias
                }}
                validationSchema={update}
                onSubmit={onFinish}
            >
                {({ errors, touched, handleSubmit, values, setFieldTouched, submitCount, handleChange }) => (

                    <Form className="inner-form col flex0">

                        <CField
                            containerClass='margin-right_zero'
                            disabled={loading}
                            name="alias"
                            placeholder="Alias"
                            type="text"
                            value={values?.alias}
                            onChange={(e) => handleChange("alias")(e.target.value)}
                            onBlur={() => setFieldTouched("alias", true, true)}
                            error={submitCount ? errors?.alias : touched?.alias && errors.alias}
                        />

                        <CButton
                            containerClass='margin-right_zero'
                            type="submit"
                            htmlType="submit"
                            onClick={handleSubmit}
                            title={`Update`}
                            buttonLayout="primary"
                            loading={loading}
                            disabled={loading}
                        />

                    </Form>
                )}
            </Formik>

        </Modal>
    );
};

export default React.memo(UpdateForm);
