import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Editor(props) {
    const {error, placeholder, isLabel, onChange, className} = props;
    return (
        <div className={`${className} c-field-container quill-editor`}>
            <div className={`c-field ${error ? "has-error" : ""}`}>
                {placeholder?.length && isLabel ? (
                    <div className="input-title">{placeholder}</div>
                ) : null}
                <div className="prefix">
                    <ReactQuill theme="snow"
                                {...props}
                        onChange={val => {
                            let value = val;
                            if(value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
                                //textarea is still empty
                                value = '';
                            }
                            onChange && onChange(value)
                        }}
                    />
                </div>
            </div>
            {error ? <div className="error">{error}</div> : null}
        </div>
    )
}

export default Editor;
