import {get, post} from "../../utils/apiMethods";
import {encodeQueryData, handleError} from "../../utils/methods";
import REMITTANCE from "../constants/Remittance.constant";

export const getHelloPaisaCurrencyRate = (payload, CB) => async (dispatch) => {
    let dispatchType = REMITTANCE.GET_HELLO_PAISA_CURRENCY_RATES;
    dispatch({ type: dispatchType, loading: true, data: []});
    try {
        let response = await post('employees/getCurrencyRate', payload);
        if(response?.data?.error) {
            dispatch({type: dispatchType, loading: false});
            handleError(response?.data?.data?.message || '');
        } else {
            CB && CB(response?.data?.data);
            dispatch({type: dispatchType, loading: false, data: response?.data?.data});
        }
    } catch (error) {
        handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false, data: {} });
    }
};


// PROCESS_PAYMENT
export const processPayment = (payload, CB) => async (dispatch) => {
    dispatch({ type: REMITTANCE.PROCESS_PAYMENT, loading: true});
    try {
        let response = await post(`employees/processPayment`, payload);
        if(response?.data?.error) {
            dispatch({type: REMITTANCE.PROCESS_PAYMENT, loading: false});
        } else {
            dispatch({
                type: REMITTANCE.PROCESS_PAYMENT,
                loading: false,
            });
        }
        CB && CB(response?.data, payload);
    } catch (error) {
        CB && CB({}, payload, true);
        handleError(error?.data?.error || error?.message, {autoHide: false});
        dispatch({type: REMITTANCE.PROCESS_PAYMENT, loading: false});
    }
};

export const getTransactionHistory = (payload = {}, CB) => (dispatch) => {
    let dispatchType = REMITTANCE.GET_TRANSACTION_HISTORY;
    dispatch({ type: dispatchType, loading: true, data: [], metaData: {} });
    post('employees/getMobileRemittanceTransaction', payload)
        .then(({ data }) => {
            if (!data.error) {
                dispatch({ type: dispatchType, loading: false, data: data?.data?.data, metaData: data?.data?.metaData });
                // CB && CB(data.data);
            } else {
                dispatch({ type: dispatchType, loading: false, data: [], metaData: {} });
                handleError(data?.data?.message);
            }
        })
        .catch((error) => {
            dispatch({ type: dispatchType, loading: false, data: [], metaData: {} });
            handleError(error?.data?.message || error?.message || "Something went wrong!");
        });
};

export const getSingleTransactionHistory = (payload, CB) => async (dispatch) => {
    let dispatchType = REMITTANCE.GET_HELLO_PAISA_SINGLE_TRANSACTION_HISTORY;
    dispatch({ type: dispatchType, loading: true});
    let queryParams = encodeQueryData(payload) ? `?${encodeQueryData(payload)}` : '';
    get(`employees/getMobileRemittanceTransactionDetails${queryParams}`)
        .then(async ({ data }) => {
            if (!data.error) {
                dispatch({ type: dispatchType, loading: false});
                CB && CB(data?.data);
            } else {
                handleError(data?.data?.message || "Something went wrong!");
                dispatch({ type: dispatchType, loading: false });
            }
        })
        .catch((error) => {
            handleError(error?.response?.data?.message || error?.data?.message || "Something went wrong!");
            dispatch({ type: dispatchType, loading: false });
        });
};
