import { useCallback } from "react";
import { useSelector } from "react-redux";
import { ACL_ACCESS_LIST, ACL_MODULES, IsAllowedOption, segregateTopic } from "../../utils/acl";
import { APPROVAL_MESSAGES_ARRAY, APPROVAL_TYPE, NATIONALITY, TOPIC } from "../../utils/constants";
import { MappedElement, getObjByKey, getStringFromBool, readableText, readableTextCap, renderDate } from "../../utils/methods";

export const KAMELPAY_OTHERBANK = [
    { value: 'KAMELPAY', label: 'KamelPay' },
    { value: 'OTHER_BANK', label: 'OtherBank' },
];

export const isKamelPay = (val) => val === KAMELPAY_OTHERBANK[0]?.value;
export const isOtherBank = (val) => val === KAMELPAY_OTHERBANK[1]?.value;

export const renderItem = (title, value) => {
    if (value) {
        return (
            <li>
                <span>{title}</span>
                <span>{value}</span>
            </li>
        )
    }
};

export const reGenerateEmployeeInfo = (item, onlyShow = []) => {
    let infoArray = [];
    if (onlyShow?.length ? onlyShow?.includes('molNo') ? item?.molNo : null : item?.molNo) {
        infoArray.push({
            title: 'MOL Number',
            value: item?.molNo
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('eid') ? item?.eid : null : item?.eid) {
        infoArray.push({
            title: 'Emirates Id',
            value: item?.eid
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('displayName') ? item?.displayName : null : item?.displayName) {
        infoArray.push({
            title: 'Display Name',
            value: item?.displayName
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('email') ? item?.email : null : item?.email) {
        infoArray.push({
            title: 'Email',
            value: item?.email
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('establishmentId') ? item?.establishmentId : null : item?.establishmentId) {
        infoArray.push({
            title: 'Linked Establishment Id',
            value: item?.establishmentId
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('empCode') ? item?.empCode : null : item?.empCode) {
        infoArray.push({
            title: 'Emp Code',
            value: item?.empCode
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('passportNumber') ? item?.passportNumber : null : item?.passportNumber) {
        infoArray.push({
            title: 'Passport Number',
            value: item?.passportNumber
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('employeeType') ? item?.employeeType : null : item?.employeeType) {
        infoArray.push({
            title: 'Employee Type',
            value: readableText(item?.employeeType)
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('cardType') ? item?.cardType : null : item?.cardType) {
        infoArray.push({
            title: 'Card Type',
            value: readableText(item?.cardType)
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('mobileNo') ? item?.mobileNo : null : item?.mobileNo) {
        infoArray.push({
            title: 'Mobile Number',
            value: item?.mobileNo
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('altMobileNo') ? item?.altMobileNo : null : item?.altMobileNo) {
        infoArray.push({
            title: 'Alt Mobile Number',
            value: item?.altMobileNo
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('gender') ? item?.gender : null : item?.gender) {
        infoArray.push({
            title: 'Gender',
            value: item?.gender
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('dob') ? item?.dob : null : item?.dob) {
        infoArray.push({
            title: 'Date of Birth',
            value: renderDate(item?.dob)
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('doj') ? item?.doj : null : item?.doj) {
        infoArray.push({
            title: 'Date of Joining',
            value: renderDate(item?.doj)
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('nationality') ? item?.nationality : null : item?.nationality) {
        infoArray.push({
            title: 'Nationality',
            value: item?.nationality?.length === 2 ? getObjByKey(NATIONALITY, `${item?.nationality}`)?.name : item?.nationality
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('kamelpayWalletId') ? item?.kamelpayWalletId : null : item?.kamelpayWalletId) {
        infoArray.push({
            title: 'Wallet Id',
            value: item?.kamelpayWalletId
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('workAddress') ? item?.workAddress : null : item?.workAddress) {
        infoArray.push({
            title: 'Address',
            value: item?.workAddress
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('workStateId') ? item?.workStateId : null : item?.workStateId) {
        infoArray.push({
            title: 'State',
            value: item?.workStateId?.name
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('workZipCode') ? item?.workZipCode : null : item?.workZipCode) {
        infoArray.push({
            title: 'Zip Code',
            value: item?.workZipCode
        })
    }
    if (onlyShow?.length ? onlyShow?.includes('havingMultipleAccounts') ? item?.havingMultipleAccounts : null : item?.havingMultipleAccounts) {
        infoArray.push({
            title: 'Multiple Accounts',
            value: getStringFromBool(item?.havingMultipleAccounts)
        })
    }
    if (item?.employeeType === "OTHER_BANK" || item?.havingMultipleAccounts) {
        if (onlyShow?.length ? onlyShow?.includes('otherBank_bankName') ? item?.otherBank?.bankName : null : item?.otherBank?.bankName) {
            infoArray.push({
                title: 'Bank Name',
                value: item?.otherBank?.bankName
            })
        }
        if (onlyShow?.length ? onlyShow?.includes('otherBank_routingCode') ? item?.otherBank?.routingCode : null : item?.otherBank?.routingCode) {
            infoArray.push({
                title: 'Routing Code',
                value: item?.otherBank?.routingCode
            })
        }
        if (onlyShow?.length ? onlyShow?.includes('otherBank_branchName') ? item?.otherBank?.branchName : null : item?.otherBank?.branchName) {
            infoArray.push({
                title: 'Branch Name',
                value: item?.otherBank?.branchName
            })
        }
        if (onlyShow?.length ? onlyShow?.includes('otherBank_iban') ? item?.otherBank?.iban : null : item?.otherBank?.iban) {
            infoArray.push({
                title: 'IBAN',
                value: item?.otherBank?.iban
            })
        }
    }
    return infoArray
};

export const generateRenderingData = (renderKeys = [], array = []) => {
    return array?.map((obj, i) => {
        return renderKeys.map(keyObj => {
            const key = keyObj?.key;
            const title = keyObj?.title;
            return {
                title: title ? title : readableTextCap(key || ''),
                value: keyObj?.render ? keyObj?.render(obj[key]) : obj[key],
                ...keyObj
            }
        });
    });
}

export const renderGeneratedData = (data = [], render = () => null, docKey = '', renderDocuments = () => null) => {
    return <MappedElement
        data={data}
        renderElement={(array) => <MappedElement data={array} renderElement={(obj) => {
            if (obj?.title === docKey && obj?.value?.length) {
                return renderDocuments(obj?.value);
            } else if (obj?.title !== docKey && obj?.value) {
                return <>
                    {render(obj?.title, obj?.value)}
                    {obj?.after && <hr />}
                </>
            }
        }}
        />}
    />
}

export const KYC = [
    { _id: 'INITIAL_VERIFICATION_APPROVED', name: 'Pending' },
    { _id: 'EMPLOYEE_ONBOARD_APPROVED', name: 'Approved' },
];

export const getFileName = (url = '') => {
    return url.substring(url.lastIndexOf('/') + 1)
}

export const useClientPendingApprovalCount = () => {
    const { user } = useSelector(({ auth }) => ({ user: auth?.user }));

    const permissions = {

        canAllChecker: IsAllowedOption(ACL_MODULES?.ALL_REQUESTS, ACL_ACCESS_LIST.ALL_REQUEST_CHECKER),
        canAllAuthorizer: IsAllowedOption(ACL_MODULES?.ALL_REQUESTS, ACL_ACCESS_LIST.ALL_REQUEST_AUTHORIZER),

        canEmployerRequestViewer: IsAllowedOption(ACL_MODULES.EMPLOYERS, ACL_ACCESS_LIST.VIEW),
        canEmployerRequestChecker: IsAllowedOption(ACL_MODULES.EMPLOYERS, ACL_ACCESS_LIST.REQUEST_CHECKER),
        canEmployerRequestAuthorizer: IsAllowedOption(ACL_MODULES.EMPLOYERS, ACL_ACCESS_LIST.REQUEST_AUTHORIZER),

        canExchangeHouseRequestViewer: IsAllowedOption(ACL_MODULES.EXCHANGE_HOUSES, ACL_ACCESS_LIST.VIEW),
        canExchangeHouseRequestChecker: IsAllowedOption(ACL_MODULES.EXCHANGE_HOUSES, ACL_ACCESS_LIST.REQUEST_CHECKER),
        canExchangeHouseRequestAuthorizer: IsAllowedOption(ACL_MODULES.EXCHANGE_HOUSES, ACL_ACCESS_LIST.REQUEST_AUTHORIZER),

        canCentivCardRequestViewer: IsAllowedOption(ACL_MODULES.CENTIV_CARD, ACL_ACCESS_LIST.VIEW),
        canCentivCardRequestChecker: IsAllowedOption(ACL_MODULES.CENTIV_CARD, ACL_ACCESS_LIST.REQUEST_CHECKER),
        canCentivCardRequestAuthorizer: IsAllowedOption(ACL_MODULES.CENTIV_CARD, ACL_ACCESS_LIST.REQUEST_AUTHORIZER),

        canProcessSalariesRequestViewer: IsAllowedOption(ACL_MODULES.PROCESS_SALARIES, ACL_ACCESS_LIST.VIEW),
        canProcessSalariesRequestChecker: IsAllowedOption(ACL_MODULES.PROCESS_SALARIES, ACL_ACCESS_LIST.REQUEST_CHECKER),
        canProcessSalariesRequestAuthorizer: IsAllowedOption(ACL_MODULES.PROCESS_SALARIES, ACL_ACCESS_LIST.REQUEST_AUTHORIZER),

        canMobileAppApprovalChecker: IsAllowedOption(ACL_MODULES?.MOBILE_APP_APPROVAL, ACL_ACCESS_LIST.REQUEST_CHECKER),
        canMobileAppApprovalAuthorizer: IsAllowedOption(ACL_MODULES?.MOBILE_APP_APPROVAL, ACL_ACCESS_LIST.REQUEST_AUTHORIZER),

        canDisputeRequestViewer: IsAllowedOption(ACL_MODULES.DISPUTE_FORM, ACL_ACCESS_LIST.VIEW),
        canDisputeRequestChecker: IsAllowedOption(ACL_MODULES.DISPUTE_FORM, ACL_ACCESS_LIST.REQUEST_CHECKER),
        canDisputeRequestAuthorizer: IsAllowedOption(ACL_MODULES.DISPUTE_FORM, ACL_ACCESS_LIST.REQUEST_AUTHORIZER),

    };

    const canClientApprovalsChecker = permissions?.canAllChecker || permissions?.canEmployerRequestChecker || permissions?.canCentivCardRequestChecker || permissions?.canProcessSalariesRequestChecker || permissions?.canDisputeRequestChecker;
    const canClientApprovalsAuthorizer = permissions?.canAllAuthorizer || permissions?.canEmployerRequestAuthorizer || permissions?.canCentivCardRequestAuthorizer || permissions?.canProcessSalariesRequestAuthorizer || permissions?.canDisputeRequestAuthorizer;

    const preparePayload = useCallback(() => {
        const checkerObj = {
            canAllChecker: permissions.canAllChecker,
            employer: permissions.canEmployerRequestChecker,
            processSalaries: permissions.canProcessSalariesRequestChecker,
            centivCard: permissions.canCentivCardRequestChecker,
            disputeForm: permissions.canDisputeRequestChecker,
        };

        const authorizerObj = {
            canAllAuthorizer: permissions.canAllAuthorizer,
            employer: permissions.canEmployerRequestAuthorizer,
            processSalaries: permissions.canProcessSalariesRequestAuthorizer,
            centivCard: permissions.canCentivCardRequestAuthorizer,
            disputeForm: permissions.canDisputeRequestAuthorizer,
        };

        const checkerTopics = segregateTopic(checkerObj, user);
        const authorizerTopics = segregateTopic(authorizerObj, user);

        const clientCheckerStatus = [APPROVAL_MESSAGES_ARRAY[0]?._id, APPROVAL_MESSAGES_ARRAY[1]?._id, APPROVAL_MESSAGES_ARRAY[2]?._id];
        const checkerStatus = [APPROVAL_MESSAGES_ARRAY[2]?._id];
        const authorizerStatus = [APPROVAL_MESSAGES_ARRAY[8]?._id];


        const adminClientApproval = {
            approvalTrackingStatus: [
                ...(canClientApprovalsChecker ? clientCheckerStatus : []),
                ...(canClientApprovalsAuthorizer ? authorizerStatus : [])
            ],
            notIncludeTopics: [TOPIC.ON_BOARD_REQUEST],
            ...(!user?.isSuperAdmin && !(permissions?.canAllChecker || permissions?.canAllAuthorizer) && { topicsInclude: [...checkerTopics, ...authorizerTopics] }),
        }

        const adminClientOnBoardApproval = {
            types: [
                ...((checkerObj?.employer || permissions?.canEmployerRequestAuthorizer || permissions?.canAllChecker) ? [APPROVAL_TYPE[0]?.value] : []),
                ...((permissions?.canExchangeHouseRequestViewer || permissions?.canExchangeHouseRequestChecker || permissions?.canExchangeHouseRequestAuthorizer || permissions?.canAllAuthorizer) ? [APPROVAL_TYPE[1]?.value] : []),
            ],
            topicsInclude: [TOPIC.ON_BOARD_REQUEST, TOPIC.EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST],
            approvalTrackingStatus: [
                ...((permissions?.canExchangeHouseRequestChecker || permissions?.canEmployerRequestChecker || permissions?.canAllChecker) ? checkerStatus : []),
                ...((permissions?.canExchangeHouseRequestAuthorizer || permissions?.canEmployerRequestAuthorizer || permissions?.canAllAuthorizer) ? authorizerStatus : [])
            ],
        }

        const adminExchangeHouseApproval = {
            notIncludeTopics: [TOPIC.ON_BOARD_REQUEST, TOPIC.EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST],
            approvalTrackingStatus: [
                ...((permissions?.canExchangeHouseRequestChecker || permissions?.canAllChecker) ? checkerStatus : []),
                ...((permissions?.canExchangeHouseRequestAuthorizer || permissions?.canAllAuthorizer) ? authorizerStatus : [])
            ],
        }

        const adminMobileAppApproval = {
            // topicsInclude: MOBILE_TOPICS,
            approvalTrackingStatus: [
                ...((permissions?.canMobileAppApprovalChecker || permissions?.canAllChecker) ? checkerStatus : []),
                ...((permissions?.canMobileAppApprovalAuthorizer || permissions?.canAllAuthorizer) ? authorizerStatus : [])
            ],
        }

        return { adminClientApproval, adminClientOnBoardApproval, adminExchangeHouseApproval, adminMobileAppApproval };
    }, [permissions, user]);

    return { preparePayload };
};

export const renderCamp = (selected = {}) => {
    let data = [], renderKeys = [];
    if (selected?.topic === TOPIC.ASSIGN_EMPLOYEES_TO_CAMP) {
        let camp = selected?.camp;
        renderKeys = [
            { key: 'kamelpayCampId', title: 'Camp Id' },
            { key: 'name', title: 'Camp Name' },
            { key: 'description', title: 'Description' },
            { key: 'address', title: 'Address' },
            { key: 'state', title: 'State', render: (state) => state && state?.name },
        ];
        data = generateRenderingData(renderKeys, [camp]);
    }
    if (data && data?.length) {
        return <ul className="inner-data">
            <h4>Camp</h4>
            <div className="d-flex flex-wrap">{renderGeneratedData(data, renderItem)}</div>
            <hr />
        </ul>
    }
}