const TEMPLATES = {
    
    CREATE_TEMPLATE : "CREATE_TEMPLATE",

    GET_TEMPLATE : "GET_TEMPLATE",

    ADD_NOTIFICATION_CONFIG: "ADD_NOTIFICATION_CONFIG",

    GET_NOTIFICATION_CONFIG: "GET_NOTIFICATION_CONFIG"
    
};

export default TEMPLATES;