const DisputeFormConstant = {

    GET_TRANSACTIONS: 'GET_TRANSACTIONS',

    GET_CLIENTS: 'GET_CLIENTS',

    OPEN_DISPUTE: 'OPEN_DISPUTE',

    GET_DISPUTES: 'GET_DISPUTES',

    MARK_APPROVED_DISPUTE: 'MARK_APPROVED_DISPUTE',

    ADD_TRACKING: 'ADD_TRACKING',

    GET_SUMMARY: 'GET_SUMMARY',

    DOWNLOAD_DISPUTE_FORM: 'DOWNLOAD_DISPUTE_FORM',

    GET_OTP_DISPUTE_FORM: 'GET_OTP_DISPUTE_FORM',
};

export default DisputeFormConstant;
