import APPROVAL from "../constants/Approval.constant";

const initialState = {
    getLoading: false,
    approvals: [],
    approvalsMetaData: {},

    approveRejectLoading: false,

    employeersList: [],
    getEmployeersLoading: false,

    getSingleApprovalLoading: false,
    approval: {},

    processEmployees: [],
    processEmployeesFileLoading: false,

    insertBulkEmployeesLoading: false,

    newBulkEmployees: [],
    newBulkEmployeesMetaData: {},
    statistics: {},
    getNewBulkEmployeesLoading: false,

    updatePendingBulkEmployeesLoading: false,

    forceApprovedOrRejectLoading: false,
    saveRejectedEmployeesLoading: false,

    rejectBulkEmployeesLoading: false,

};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case APPROVAL.GET_APPROVALS:
            return {
                ...state,
                approvals: action.data || state.approvals,
                getLoading: action.loading,
                approvalsMetaData: action.metaData
            };

        case APPROVAL.APPROVE_REJECT:
            return {
                ...state,
                approveRejectLoading: action.loading,
            };

        case APPROVAL.GET_EMPLOYEERS_LIST:
            return {
                ...state,
                employeersList: action.data,
                getEmployeersLoading: action.loading,
            };

        case APPROVAL.GET_SINGLE_APPROVAL:
            return {
                ...state,
                getSingleApprovalLoading: action.loading,
                approval: action.data,
            };

        case APPROVAL.UPDATE_APPROVAL_SOCKET:
            return {
                ...state,
                approvals: action.data,
            };

        case APPROVAL.PROCESS_EPLOYEES_FILE:
            return {
                ...state,
                processEmployeesFileLoading: action.loading,
                processEmployees: action.data,
            };

        case APPROVAL.INSERT_BULK_EMPLOYEES:
            return {
                ...state,
                insertBulkEmployeesLoading: action.loading,
            };

        case APPROVAL.GET_BULK_EMPLOYEES_FROM_APPROVAL:
            return {
                ...state,
                newBulkEmployees: action.data,
                newBulkEmployeesMetaData: action.metaData,
                statistics: action.statistics,
                getNewBulkEmployeesLoading: action.loading,
            };

        case APPROVAL.UPDATE_PENDING_BULK_EMPLOYEES:
            return {
                ...state,
                updatePendingBulkEmployeesLoading: action.loading,
            };

        case APPROVAL.FORCE_APPROVED_OR_REJECT:
            return {
                ...state,
                forceApprovedOrRejectLoading: action.loading,
            };

        case APPROVAL.SAVE_REJECTED_EMPLOYEES:
            return {
                ...state,
                saveRejectedEmployeesLoading: action.loading,
            };

        case APPROVAL.REJECT_BULK_EMPLOYEES:
            return {
                ...state,
                rejectBulkEmployeesLoading: action.loading,
            };

        default:
            return state;
    }
};
