import React from "react";
import { CButton } from "../../uiComponents";
import { FaUsers } from "react-icons/fa";
import { ConditionalRendering } from "../../utils/methods";
import { useNavigate } from "react-router";
import { MOBILE_USER_STATUS } from "../../utils/constants";

const CSendMoney = ({ employee, mobileUser, client }) => {

    const navigate = useNavigate();

    return <>
        <ConditionalRendering
            condition={mobileUser?.status === MOBILE_USER_STATUS?.ACTIVE && !mobileUser?.isLightUser}
            children={<CButton
                containerClass="flex0"
                buttonType="gradient"
                title="Send Money"
                onClick={() => navigate('/send-money', { state: { employee, client, mobileUser } })}
                leftIcon={(className) => <FaUsers className={className} />}
            />}
        />
    </>
};

export default CSendMoney;
