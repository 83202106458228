export const RADIO = 'RADIO';
export const CHECKBOX = 'CHECKBOX';

export const PI_CHART_SELECTIONS = [
  {
    _id: "BY_CATEGORY",
    name: "Chart by Category",
  },
  {
    _id: "BY_CENTIV_CARD",
    name: "Chart by Centiv Card",
  },
];

export const CARD_ACTIONS = [
  {
    _id: "DISSOCIATE",
    name: "Dissociate"
  }
]

export const CARD_TYPE = [
  {
    _id: "CENTIV",
    productId: 1,
    productCode: '36',
    name: "Centiv Card",
  },
  {
    _id: "PAYD",
    productId: 2,
    productCode: '35',
    name: "PayD Card",
  },
];

export const CARD_TYPE_OBJ = {
  CENTIV: 'CENTIV',
  PAYD: 'PAYD',
  PRODUCT_ID_CENTIV: 1,
  PRODUCT_ID_PAYD: 2,
  PRODUCT_CODE_CENTIV: '36',
  PRODUCT_CODE_PAYD: '35',
}

export const PAYD_OTHER = [
  {
    _id: "PAYD",
    name: "KamelPay Card",
  },
  {
    _id: "OTHER_BANK",
    name: "Other Bank"
  }
];

export const PAYD_OTHER_BOTH = [
  {
    _id: "PAYD",
    name: "PayD Card",
  },
  {
    _id: "OTHER_BANK",
    name: "Other Bank"
  },
  {
    _id: "MULTI_ACCOUNT",
    name: "multiple Account"
  }
];

export const EID_EXPIRY = [
  {
    _id: "NOT_AVAILABLE",
    name: "Not Available",
  },
  {
    _id: "EXPIRED",
    name: "Expired",
  },
  {
    _id: "NEAR_TO_EXPIRE",
    name: "Near to Expire",
  },
];

export const EXCEL_FILETYPES =
  "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const PDF_FILETYPE = "application/pdf";
export const CSV_FILETYPES = "text/csv";
export const IMAGE_FILYTYPES = "image/jpeg, image/png";
export const IMAGE_FILETYPE = "image/*";
export const TOPIC = {
  CLIENT_PROFILE_UPDATE: "CLIENT_PROFILE_UPDATE",
  INITIAL_VERIFICATION_APPROVED: "INITIAL_VERIFICATION_APPROVED",
  MOBILE_CARD_DISSOCIATE: "MOBILE_CARD_DISSOCIATE",
  ON_BOARD_REQUEST: "ON_BOARD_REQUEST",
  ON_BOARD_REQUEST_APPROVED: "ON_BOARD_REQUEST_APPROVED",
  ON_BOARD_REQUEST_REJECT: "ON_BOARD_REQUEST_REJECT",
  EMPLOYEES_FILE_UPLOAD: "EMPLOYEES_FILE_UPLOAD",
  EMPLOYEES_OTHER_BANK_FILE_UPLOAD: "EMPLOYEES_OTHER_BANK_FILE_UPLOAD",
  EMPLOYEE_ONBOARD_REQUEST: "EMPLOYEE_ONBOARD_REQUEST",
  EMPLOYEE_ONBOARD_APPROVED: "EMPLOYEE_ONBOARD_APPROVED",
  EMPLOYEE_ONBOARD_REJECT: "EMPLOYEE_ONBOARD_REJECT",
  EMPLOYEE_UPDATE_REQUEST: "EMPLOYEE_UPDATE_REQUEST",
  SALARY_FILE_UPLOAD: "SALARY_FILE_UPLOAD",
  DEPOSIT_FILE_UPLOAD: "DEPOSIT_FILE_UPLOAD",
  CENTIV_CARD_REQUEST: "CENTIV_CARD_REQUEST",
  CARD_REPLACEMENT_REQUEST: "CARD_REPLACEMENT_REQUEST",
  EMPLOYEE_APPLICATION_BLOCK_REQUEST: "EMPLOYEE_APPLICATION_BLOCK_REQUEST",
  EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST: "EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST",
  USER_BLOCK_REQUEST: "USER_BLOCK_REQUEST",
  CHANGE_EMPLOYEE_TYPE: "CHANGE_EMPLOYEE_TYPE",
  USER_UNBLOCK_REQUEST: "USER_UNBLOCK_REQUEST",
  ESTABLISHMENT_IDS_ADDED: "ESTABLISHMENT_IDS_ADDED",
  UPDATE_MOL_NO: "UPDATE_MOL_NO",
  ESTABLISHMENT_ID_ADDED: "ESTABLISHMENT_ID_ADDED",
  CENTIV_LOAD_FUNDS: "CENTIV_LOAD_FUNDS",
  EMPLOYEE_EID_UPDATE: "EMPLOYEE_EID_UPDATE",
  EMPLOYEE_PHONE_UPDATE: "EMPLOYEE_PHONE_UPDATE",
  CARD_PIN_CHANGE: "CARD_PIN_CHANGE",
  EMPLOYEE_STATEMENT_REQUEST: "EMPLOYEE_STATEMENT_REQUEST",
  CHANGE_EMPLOYEE_TYPE_TO_KAMELPAY: "CHANGE_EMPLOYEE_TYPE_TO_KAMELPAY",
  UPDATE_DATE_OF_BIRTH: 'UPDATE_DATE_OF_BIRTH',
  BULK_INACTIVE_EMPLOYEES: 'BULK_INACTIVE_EMPLOYEES',
  BULK_ACTIVE_EMPLOYEES: 'BULK_ACTIVE_EMPLOYEES',
  UPDATE_NATIONALITY: 'UPDATE_NATIONALITY',
  UPDATE_EMP_CODE: 'UPDATE_EMP_CODE',
  UPDATE_PASSPORT: 'UPDATE_PASSPORT',
  TEAM_MEMBER_PHONE_UPDATE: 'TEAM_MEMBER_PHONE_UPDATE',
  ADD_UPDATE_CASHBACK: 'ADD_UPDATE_CASHBACK',
  ADD_PRODUCT: 'ADD_PRODUCT',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  ADD_CHARGES: 'ADD_CHARGES',
  EDIT_CHARGES: 'EDIT_CHARGES',
  ADD_BUSINESS_OWNER: 'ADD_BUSINESS_OWNER',
  EDIT_BUSINESS_OWNER: 'EDIT_BUSINESS_OWNER',
  ADD_EDIT_ADDITIONAL_DETAILS: 'ADD_EDIT_ADDITIONAL_DETAILS',
  ADD_OPERATION_USER: 'ADD_OPERATION_USER',
  EDIT_OPERATION_USER: 'EDIT_OPERATION_USER',
  CREATE_DISPUTE_FORM: 'CREATE_DISPUTE_FORM',
  CREATE_CAMP: 'CREATE_CAMP',
  UPDATE_CAMP: 'UPDATE_CAMP',
  ASSIGN_EMPLOYEES_TO_CAMP: 'ASSIGN_EMPLOYEES_TO_CAMP',
  //Exchange house
  EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST: 'EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST',
  BULK_ESTABLISHMENT_IDS_ADDED: 'BULK_ESTABLISHMENT_IDS_ADDED',
  EOS_FILE_UPLOAD: 'EOS_FILE_UPLOAD',
  INACTIVE_EMPLOYEES: "INACTIVE_EMPLOYEES",
  ACTIVE_EMPLOYEES: "ACTIVE_EMPLOYEES",
};

export const MOBILE_TOPICS = {
  CREATE_PROMO_CODE: "CREATE_PROMO_CODE",
  CREATE_OFFER: "CREATE_OFFER",
  EDIT_PROMO_CODE: "EDIT_PROMO_CODE",
  EDIT_OFFER: "EDIT_OFFER",
  ADD_HELLOPAISA_FEE: "ADD_HELLOPAISA_FEE",
  EDIT_HELLOPAISA_FEE: "EDIT_HELLOPAISA_FEE",
  ADD_HELLOPAISA_RATE: "ADD_HELLOPAISA_RATE",
  EDIT_HELLOPAISA_RATE: "EDIT_HELLOPAISA_RATE",
  ADD_UPDATE_NOTIFICATION_TEMPLATE: "ADD_UPDATE_NOTIFICATION_TEMPLATE",
  ADD_UPDATE_NOTIFICATION_CONFIG: "ADD_UPDATE_NOTIFICATION_CONFIG",
}

export const TOPIC_ARRAY_FOR_APPROVAl = [
  { _id: 'CLIENT_PROFILE_UPDATE', name: 'Client Profile Update' },
  { _id: 'INITIAL_VERIFICATION_APPROVED', name: 'Initial Verification Approved' },
  { _id: 'ON_BOARD_REQUEST_APPROVED', name: 'On Board Request Approved' },
  { _id: 'ON_BOARD_REQUEST_REJECT', name: 'On Board Request Rejected' },
  { _id: 'EMPLOYEES_FILE_UPLOAD', name: 'Employees File Upload' },
  { _id: 'EMPLOYEES_OTHER_BANK_FILE_UPLOAD', name: 'Employees Other Bank File Upload' },
  { _id: 'EMPLOYEE_ONBOARD_REQUEST', name: 'Employee Onboard Request' },
  { _id: 'EMPLOYEE_ONBOARD_APPROVED', name: 'Employee Onboard Approved' },
  { _id: 'EMPLOYEE_ONBOARD_REJECT', name: 'Employee Onboard Rejected' },
  { _id: 'EMPLOYEE_UPDATE_REQUEST', name: 'Employee Update Request' },
  { _id: 'SALARY_FILE_UPLOAD', name: 'Salary File Upload' },
  { _id: 'DEPOSIT_FILE_UPLOAD', name: 'Deposit File Upload' },
  { _id: 'CENTIV_CARD_REQUEST', name: 'Centiv Card Request' },
  { _id: 'CARD_REPLACEMENT_REQUEST', name: 'Card Replacement Request' },
  { _id: 'EMPLOYEE_APPLICATION_BLOCK_REQUEST', name: 'Employee Application Block Request' },
  { _id: 'EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST', name: 'Employee Application Un Block Request' },
  { _id: 'USER_BLOCK_REQUEST', name: 'User Block Request' },
  { _id: 'CHANGE_EMPLOYEE_TYPE', name: 'Add Other Bank Details' },
  { _id: 'CHANGE_EMPLOYEE_TYPE_TO_KAMELPAY', name: 'Add PayD Details' },
  { _id: 'USER_UNBLOCK_REQUEST', name: 'User Unblock Request' },
  { _id: 'ESTABLISHMENT_IDS_ADDED', name: 'Establishment Ids Added' },
  { _id: 'UPDATE_MOL_NO', name: 'Update Mol No' },
  { _id: 'ESTABLISHMENT_ID_ADDED', name: 'Establishment Id Added' },
  { _id: 'CENTIV_LOAD_FUNDS', name: 'Centiv Load Funds' },
  { _id: 'EMPLOYEE_EID_UPDATE', name: 'Employee EID Update' },
  { _id: 'EMPLOYEE_PHONE_UPDATE', name: 'Employee Phone Update' },
  { _id: 'CARD_PIN_CHANGE', name: 'Card PIN Change' },
  { _id: 'EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST', name: 'Exchange House Branch Onboard Request' },
  { _id: 'BULK_ESTABLISHMENT_IDS_ADDED', name: 'Bulk Establishment Ids Added' },
  { _id: 'EOS_FILE_UPLOAD', name: 'EOS File Upload' },
  { _id: 'EMPLOYEE_STATEMENT_REQUEST', name: 'Employee Statement Request' },
  { _id: 'UPDATE_DATE_OF_BIRTH', name: 'Update Date Of Birth' },
  { _id: 'BULK_INACTIVE_EMPLOYEES', name: 'Bulk Inactive Employees' },
  { _id: 'BULK_ACTIVE_EMPLOYEES', name: 'Bulk Active Employees' },
  { _id: 'UPDATE_NATIONALITY', name: 'Update Nationality' },
  { _id: 'UPDATE_EMP_CODE', name: 'Update Emp Code' },
  { _id: 'UPDATE_PASSPORT', name: 'Update Passport' },
  { _id: 'ADD_UPDATE_CASHBACK', name: 'Add Update Cashback' },
  { _id: 'ADD_PRODUCT', name: 'Add Product' },
  { _id: 'EDIT_PRODUCT', name: 'Edit Product' },
  { _id: 'ADD_CHARGES', name: 'Add Charges' },
  { _id: 'EDIT_CHARGES', name: 'Edit Charges' },
  { _id: 'ADD_BUSINESS_OWNER', name: 'Add Business Owner' },
  { _id: 'EDIT_BUSINESS_OWNER', name: 'Edit Business Owner' },
  { _id: 'ADD_EDIT_ADDITIONAL_DETAILS', name: 'Add Edit Additional Details' },
  { _id: 'ADD_OPERATION_USER', name: 'Add Operation User' },
  { _id: 'EDIT_OPERATION_USER', name: 'Edit Operation User' },
  { _id: 'CREATE_DISPUTE_FORM', name: 'Create Dispute Form' },
  { _id: 'TEAM_MEMBER_PHONE_UPDATE', name: 'Team Member Phone Update' },
  { _id: 'CREATE_CAMP', name: 'Create Camp' },
  { _id: 'UPDATE_CAMP', name: 'Update Camp' },
  { _id: 'INACTIVE_EMPLOYEES', name: 'Inactive Employees' },
  { _id: 'ACTIVE_EMPLOYEES', name: 'Active Employees' },
  { _id: 'ASSIGN_EMPLOYEES_TO_CAMP', name: 'Assign Employees To Camp' },
  { _id: 'MOBILE_CARD_DISSOCIATE', name: 'Mobile Card Dissociate' },
];

export const TOPIC_OBJECT = [
  { _id: 1, name: "INITIAL_UNVERIFIED" },
  { _id: 2, name: "INITIAL_VERIFICATION_APPROVED" },
  { _id: 3, name: "ON_BOARD_REQUEST" },
  { _id: 4, name: "ON_BOARD_REQUEST_APPROVED" },
  { _id: 5, name: "ON_BOARD_REQUEST_REJECT" },
];
export const EMPLOYER_TYPE_OBJECT = {
  EXCHANGE_HOUSE_CLIENT: 'EXCHANGE_HOUSE_CLIENT',
  NON_EXCHANGE_HOUSE_CLIENT: 'NON_EXCHANGE_HOUSE_CLIENT'
}

export const EMPLOYER_TYPE = [
  { _id: "EXCHANGE_HOUSE_CLIENT", name: "EXCHANGE_HOUSE" },
  { _id: "NON_EXCHANGE_HOUSE_CLIENT", name: "KAMELPAY" },

];

export const ACTION_ALL = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  PARTIAL_APPROVED: 'PARTIAL_APPROVED',
  REJECTED_BY_EXCHANGE_HOUSE: 'REJECTED_BY_EXCHANGE_HOUSE',
  REJECTED_BY_EXCHANGE_HOUSE_BRANCH: 'REJECTED_BY_EXCHANGE_HOUSE_BRANCH',
  REJECTED_BY_EXCHANGE_HOUSE_USER: 'REJECTED_BY_EXCHANGE_HOUSE_USER',
  REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER: 'REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER',
  REJECTED_BY_CLIENT_ADMIN: 'REJECTED_BY_CLIENT_ADMIN',
  REJECTED_BY_CLIENT: 'REJECTED_BY_CLIENT',
  REJECTED_BY_ADMIN: 'REJECTED_BY_ADMIN',
  APPROVED_BY_ADMIN_AUTHORIZER: 'APPROVED_BY_ADMIN_AUTHORIZER',
  REJECTED_BY_ADMIN_AUTHORIZER: 'REJECTED_BY_ADMIN_AUTHORIZER',
  APPROVED_BY_CLIENT_ADMIN: 'APPROVED_BY_CLIENT_ADMIN',
  APPROVED_BY_EXCHANGE_ADMIN: 'APPROVED_BY_EXCHANGE_ADMIN',
  APPROVED_BY_CLIENT: 'APPROVED_BY_CLIENT',
  APPROVED_BY_AUTHORIZER: 'APPROVED_BY_AUTHORIZER',
  APPROVED_BY_ADMIN: 'APPROVED_BY_ADMIN',
};
export const ACTION = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED"
};

export const ACTION_ARRAY = [
  { _id: ACTION_ALL.PENDING, name: "Pending" },
  { _id: ACTION_ALL.APPROVED, name: "Approved" },
  { _id: ACTION_ALL.REJECTED, name: "Rejected" },
];
export const ACTION_ARRAY_COMMON = [
  { _id: ACTION_ALL.PENDING, name: "Pending" },
  { _id: ACTION_ALL.APPROVED, name: "Approved" },
  { _id: ACTION_ALL.PARTIAL_APPROVED, name: "Partial Approved" },
  { _id: ACTION_ALL.REJECTED, name: "Rejected" },
];
export const ACTION_ARRAY_ALL = [
  { _id: ACTION_ALL.PENDING, name: "Pending" },
  { _id: ACTION_ALL.APPROVED, name: "Approved" },
  { _id: ACTION_ALL.REJECTED, name: "Rejected" },
  { _id: ACTION_ALL.REJECTED_BY_CLIENT, name: "Rejected by Client" },
  { _id: ACTION_ALL.REJECTED_BY_CLIENT_ADMIN, name: "Rejected by Client User" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE, name: "Rejected by Exchange House" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_BRANCH, name: "Rejected by Exchange House Branch" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_USER, name: "Rejected by Exchange House User" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER, name: "Rejected by Exchange House Branch User" },
];
export const REJECTED_ARRAY_ALL = [
  { _id: ACTION_ALL.REJECTED_BY_ADMIN, name: "Admin" },
  { _id: ACTION_ALL.REJECTED_BY_CLIENT, name: "Client" },
  { _id: ACTION_ALL.REJECTED_BY_CLIENT_ADMIN, name: "Client User" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE, name: "Exchange House" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_BRANCH, name: "Exchange House Branch" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_USER, name: "Exchange House User" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER, name: "Exchange House Branch User" },
];
export const REJECTED_ARRAY_CLIENT = [
  { _id: ACTION_ALL.REJECTED_BY_ADMIN, name: "Admin" },
  { _id: ACTION_ALL.REJECTED_BY_CLIENT, name: "Client" },
  { _id: ACTION_ALL.REJECTED_BY_CLIENT_ADMIN, name: "Client User" },
];
export const PENDING_ON_CLIENT = [
  { _id: ACTION_ALL.APPROVED_BY_AUTHORIZER, name: "Client's Authorizer" },
  { _id: ACTION_ALL.APPROVED_BY_CLIENT_ADMIN, name: "Client" },
  { _id: ACTION_ALL.APPROVED_BY_CLIENT, name: "Checker" },
  { _id: ACTION_ALL.APPROVED_BY_ADMIN_AUTHORIZER, name: "Authorizer" },
];
export const REJECTED_ARRAY_EH = [
  { _id: ACTION_ALL.REJECTED_BY_ADMIN, name: "Admin" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE, name: "Exchange House" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_BRANCH, name: "Exchange House Branch" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_USER, name: "Exchange House User" },
  { _id: ACTION_ALL.REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER, name: "Exchange House Branch User" },
];
export const PENDING_ON_EH = [
  { _id: ACTION_ALL.APPROVED_BY_CLIENT_ADMIN, name: "Exchange House" },
  { _id: ACTION_ALL.APPROVED_BY_CLIENT, name: "Checker" },
  { _id: ACTION_ALL.APPROVED_BY_ADMIN_AUTHORIZER, name: "Authorizer" },
];
export const APPROVAL_TYPE = [
  { _id: "CLIENT", name: "KamelPay Clients", label: "KamelPay Clients", value: "CLIENT" },
  { _id: "EXCHANGE_HOUSE", name: "Exchange House Clients", label: "Exchange House Clients", value: "EXCHANGE_HOUSE" },
];

export const KYC = {
  INITIAL_UNVERIFIED: "INITIAL_UNVERIFIED",
  INITIAL_VERIFICATION_APPROVED: "INITIAL_VERIFICATION_APPROVED",
  ON_BOARD_REQUEST: "ON_BOARD_REQUEST",
  ON_BOARD_REQUEST_APPROVED: "ON_BOARD_REQUEST_APPROVED",
  ON_BOARD_REQUEST_REJECT: "ON_BOARD_REQUEST_REJECT",
  BANK_CHANGE_TYPE_REQUEST: 'BANK_CHANGE_TYPE_REQUEST',
};
export const SALARY_STATUS_ARRAY = (type = '') => {
  if (type === SALARY_TYPES_OBJ.WPS) {
    return {
      title: 'Get PAF',
      fileName: 'PAF-file',
      headerText: 'PAF Id',
      steps: [
        { _id: "PAF_RECEIVED", name: "PAF Received", title: "PAF Received" },
        { _id: "PAF_FILE_UPLOADED", name: "PAF Processed", title: "PAF Processed" },
        { _id: "TOPUP_REQUEST_GENERATED", name: "Card Load Request Sent", title: "Card Load Request Sent" },
        { _id: "SALARY_CREDITED", name: "Salary Credited", title: "Salary Credited" },
      ]
    }
  } else if (type === SALARY_TYPES_OBJ.NON_WPS) {
    return {
      title: 'Get Salary File',
      fileName: 'Salary-file',
      headerText: 'Salary Id',
      steps: [
        { _id: "TOPUP_REQUEST_GENERATED", name: "Card Load Request Sent", title: "Card Load Request Sent" },
        { _id: "SALARY_CREDITED", name: "Salary Credited", title: "Salary Credited" }
      ]
    }
  } else if (type === SALARY_TYPES_OBJ.OTHER_BANK) {
    return {
      title: 'Get SIF',
      fileName: 'SIF-file',
      headerText: 'SIF Id',
      steps: [
        { _id: "SIF_RECEIVED", name: "SIF Sent", title: "SIF Sent" },
        { _id: "SIF_FILE_UPLOADED", name: "SIF Ack Received", title: "SIF Ack Received" }
      ]
    }
  }
  return {};
};

export const ACTIVE_INACTIVE = [
  {
    _id: "ACTIVE",
    name: "Active",
  },
  {
    _id: "INACTIVE",
    name: "In-Active",
  },
];

export const YES_NO = [
  {
    _id: "true",
    name: "Yes",
  },
  {
    _id: "false",
    name: "No",
  },
];

export const YESNO = [
  {
    _id: "YES",
    name: "Yes",
  },
  {
    _id: "NO",
    name: "No",
  },
];

export const LOGS_TYPES = [
  {
    _id: "DOT_NET",
    name: "Dot Net",
  },
  {
    _id: "MOBILE_APP",
    name: "Mobile App",
  },
  {
    _id: "SUREPASS",
    name: "Surepass",
  },
  {
    _id: "HRCMS",
    name: "hrcms",
  },
  {
    _id: "BENCH_MATRIX",
    name: "Bench-Matrix",
  },
];

export const LOGS_TYPES_OBJ = {
  DOT_NET: 'DOT_NET',
  MOBILE_APP: 'MOBILE_APP',
  SUREPASS: 'SUREPASS',
  HRCMS: 'HRCMS',
  BENCH_MATRIX: 'BENCH_MATRIX',
};

export const CAT_SUB_CAT = [
  { label: "Category", value: "CATEGORY" },
  { label: "Sub Category", value: "SUB_CATEGORY" },
];

export const WITH_OR_WITHOUT_VARIATION = [
  { label: "With Variants", value: "WITH_VARIANTS" },
  { label: "Without Variants", value: "WITHOUT_VARIANTS" },
];

export const CLIENT_APPROVAL_STATUS = {
  APPROVED_BY_CLIENT_ADMIN: "APPROVED_BY_CLIENT_ADMIN",
  APPROVED_BY_CLIENT: "APPROVED_BY_CLIENT",
  APPROVED_BY_AUTHORIZER: 'APPROVED_BY_AUTHORIZER',
  REJECTED_BY_CLIENT: "REJECTED_BY_CLIENT",
  APPROVED_BY_ADMIN: "APPROVED_BY_ADMIN",
  APPROVED_BY_ADMIN_AUTHORIZER: "APPROVED_BY_ADMIN_AUTHORIZER",
  REJECTED_BY_ADMIN_AUTHORIZER: "REJECTED_BY_ADMIN_AUTHORIZER",
  REJECTED_BY_ADMIN: "REJECTED_BY_ADMIN",
  REJECTED_BY_AUTHORIZER: 'REJECTED_BY_AUTHORIZER',
};

export const EMPLOYEE_DOCUMENTS = [
  // { _id: 4, name: "Sponsor Document" },
  { _id: 5, name: "Labour Card" },
  { _id: 6, name: "Emirates Id" },
  { _id: 7, name: "Residence Visa" },
  { _id: 8, name: "Passport" },
];

export const REPLACEMENT_CARD_TRACKING_STATUS = [
  { _id: 'FILE_SEND_TO_PERSOU', name: "File Send To Persou" },
  { _id: 'CARD_PRINTED', name: "Card Printed" },
  { _id: 'CARD_RECEIVED_FROM_PERSOU', name: "Card Received From Persou" },
  { _id: 'CARD_RECEIVED_IN_H_O', name: "Card Received in H O" },
  { _id: 'ASSIGNED_TO_EXECUTIVE', name: "Assigned to Executive" },
  { _id: 'PERSON_NOT_AVAILABLE', name: "Person Not Available" },
  { _id: 'REQUEST_FOR_LOCATION_CHANGE', name: "Request for Location Change" },
  { _id: 'CARD_ACTIVATION_REQUEST_SEND', name: "Card Activation Request Send" },
  { _id: 'CARD_DELIVERED', name: "Card Delivered" },
  { _id: 'CARD_ACTIVATED', name: "Card Activated" },
  { _id: 'CARD_REPLACEMENT_REQUESTED', name: "Card Replacement Requested" },
  { _id: 'CARD_REPLACEMENT_FAILED', name: "Card Replacement Failed" },
  { _id: 'CARD_REPLACEMENT_SUCCESS', name: "Card Replacement Success" },
  { _id: 'HOT_BLOCK', name: "Hot Block" },
  { _id: 'WARM_BLOCK', name: "Warm Block" },
  { _id: 'ACTIVE', name: "Active" },
  { _id: 'LOSS_STOLEN', name: "Loss/Stolen" },
  { _id: 'CARD_CREATION_FROM_PERSOU', name: "Card Creation From Persou" },
  { _id: 'HOT_MARK_REQUESTED', name: "Hot Mark Requested" },
  { _id: 'HOT_MARK_FAILED', name: "Hot Mark Failed" },
  // { _id: 'CARD_COLLECTED', name: "Card Collecte" }
]

// export const PayrollTypes = [
//   { _id: "WPS", name: "WPS" },
//   { _id: "NON_WPS", name: "NON_WPS" },
// ];
export const EMPLOYER_DOCUMENTS = [
  { _id: 1, name: "Trade License" },
  { _id: 2, name: "Establishment Card" },
  { _id: 3, name: "Labour Card" },
  { _id: 4, name: "Sponsor Document" },
  { _id: 9, name: "Mansha List" },
];

export const PAYROLL_TYPES = [
  { _id: "WPS", name: "WPS" },
  { _id: "NON_WPS", name: "NON WPS" },
];

export const SALARY_TYPES = [
  { _id: "WPS", name: "WPS" },
  { _id: "NON-WPS", name: "Non WPS" },
  { _id: "OtherBank", name: "Other Bank" },
];

export const SALARY_TYPES_OBJ = {
  WPS: 'WPS',
  NON_WPS: 'NON-WPS',
  OTHER_BANK: 'OtherBank'
};

export const IMPORTANT_DOCS = [
  { _id: 1, name: "Trade License" },
  { _id: 4, name: "Sponsor Document" },
];

export const APPROVAL_MESSAGES = {
  APPROVED_BY_CLIENT_ADMIN: "Waiting for Client's Approval",
  APPROVED_BY_EXCHANGE_ADMIN: "Waiting for Exchange House Approval",
  APPROVED_BY_CLIENT: "Waiting for Checker's approval",
  APPROVED_BY_AUTHORIZER: "Waiting for Client's Authorizer Approval",
  APPROVED_BY_ADMIN: "Approved",
  APPROVED_BY_ADMIN_AUTHORIZER: "Waiting for Authorizer's Approval",
  REJECTED_BY_ADMIN_AUTHORIZER: "Rejected by Authorizer",
  REJECTED_BY_ADMIN: "Rejected",
  REJECTED_BY_CLIENT: "Rejected by Client",
  REJECTED_BY_CLIENT_ADMIN: 'Rejected by Client User',
  REJECTED_BY_EXCHANGE_HOUSE: 'Rejected by Exchange House',
  REJECTED_BY_EXCHANGE_HOUSE_BRANCH: 'Rejected by Exchange House Branch',
  REJECTED_BY_EXCHANGE_HOUSE_USER: 'Rejected by Exchange House User',
  REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER: 'Rejected by Exchange House Branch User',
};

export const CLIENT_TYPE = {
  NON_EXCHANGE_HOUSE_CLIENT: "Kamelpay",
  EXCHANGE_HOUSE_CLIENT: "Exchange House",
};

export const CLIENT_TYPE_VALUE = {
  NON_EXCHANGE_HOUSE_CLIENT: "NON_EXCHANGE_HOUSE_CLIENT",
  EXCHANGE_HOUSE_CLIENT: "EXCHANGE_HOUSE_CLIENT",
};

export const APPROVAL_MESSAGES_ARRAY = [
  { _id: "APPROVED_BY_CLIENT_ADMIN", name: "Waiting for Client's Approval" },//0
  { _id: "APPROVED_BY_AUTHORIZER", name: "Waiting For Client's Authorizer Approval" },//1
  { _id: "APPROVED_BY_CLIENT", name: "Waiting for checker's approval" },//2
  { _id: "APPROVED_BY_ADMIN", name: "Approved" },//3
  { _id: "REJECTED_BY_ADMIN", name: "Rejected" },//4
  { _id: "REJECTED_BY_CLIENT", name: "Rejected by Client" },//5
  { _id: 'REJECTED_BY_CLIENT_ADMIN', name: 'Rejected by Client User' },//6
  { _id: "REJECTED_BY_AUTHORIZER", name: "Rejected by Authorizer" },//7
  { _id: "APPROVED_BY_ADMIN_AUTHORIZER", name: "Waiting for Authorizer's Approval" },//8
  { _id: "REJECTED_BY_ADMIN_AUTHORIZER", name: "Rejected by Authorizer" },//9
];

export const APPROVAL_MESSAGES_EXCHANGE_ARRAY = [
  { _id: "APPROVED_BY_CLIENT_ADMIN", name: "Waiting for Exchange's Approval" },
  { _id: "APPROVED_BY_CLIENT", name: "Waiting for checker's approval" },
  { _id: "APPROVED_BY_ADMIN", name: "Approved" },
  { _id: "REJECTED_BY_ADMIN", name: "Rejected" },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE', name: 'Rejected by Exchange House' },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE_BRANCH', name: 'Rejected by Exchange House Branch' },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE_USER', name: 'Rejected by Exchange House User' },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER', name: 'Rejected by Exchange House Branch User' },
  { _id: "APPROVED_BY_ADMIN_AUTHORIZER", name: "Waiting for Exchange House Authorizer's Approval" },//8
  { _id: "REJECTED_BY_ADMIN_AUTHORIZER", name: "Rejected by Exchange House Authorizer" },//9
];
export const APPROVAL_MESSAGES_ONBOARD_ARRAY = [
  { _id: "APPROVED_BY_CLIENT_ADMIN", name: "Waiting for Client's /Exchange's Approval" },
  { _id: "APPROVED_BY_CLIENT", name: "Waiting for checker's approval" },
  { _id: "APPROVED_BY_ADMIN", name: "Approved" },
  { _id: "REJECTED_BY_ADMIN", name: "Rejected" },
  { _id: "REJECTED_BY_CLIENT", name: "Rejected by Client" },
  { _id: 'REJECTED_BY_CLIENT_ADMIN', name: 'Rejected by Client User' },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE', name: 'Rejected by Exchange House' },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE_USER', name: 'Rejected by Exchange House User' },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE_BRANCH', name: 'Rejected by Exchange House Branch' },
  { _id: 'REJECTED_BY_EXCHANGE_HOUSE_BRANCH_USER', name: 'Rejected by Exchange House Branch User' },
];
export const CARD_STATUS = {
  PENDING: "Pending",
  COMPLIANCE_BANK_PASSED: "Compliance Bank Passed",
  COMPLIANCE_BANK_FAIL: "Compliance Bank Fail",
  CARD_ON_PRINTING: "Card On Printing",
  CARD_ON_PRINTED: "Card On Printed",
  DELIVERED: "Delivered",
};
// export const CARD_STATUS_ARRAY = [
//   { _id: "PENDING", title: "Pending" },
//   { _id: "COMPLIANCE_BANK_PASSED", title: "Compliance Bank Passed" },
//   { _id: "COMPLIANCE_BANK_FAIL", title: "Compliance Bank Fail" },
//   { _id: "CARD_ON_PRINTING", title: "Card On Printing" },
//   { _id: "CARD_ON_PRINTED", title: "Card On Printed" },
//   { _id: "DELIVERED", title: "Delivered" },
// ];
export const EMPLOYEE_STATUS_ARRAY = [
  { _id: 'PENDING', title: 'Pending' },
  { _id: 'COMPLIANCE_PASSED', title: 'Compliance Passed' },
  { _id: 'ACCOUNT_RECEIVED', title: 'Account Received' }
];

export const CARD_STATUS_ARRAY = [
  { _id: 'CARD_SENT_TO_PRINTER', title: 'Card Sent To Printer' },
  { _id: 'PRINTED', title: 'Printed' },
  { _id: 'CARD_WITH_COURIER', title: 'Card With Courier' },
  { _id: 'DELIVERED', title: 'Delivered' },
];
export const REPLACEMENT_CARD_STATUS_ARRAY = [
  { _id: 0, title: 'Card Replacement Requested', match: ['Card Replacement Requested', 'Card Replacement Failed', 'Card Replacement Approve', 'Card Replacement Rejected'] },
  { _id: 1, title: "Card Replacement Success", match: ['Card Replacement Success'] },
  { _id: 3, title: 'File Send To Persou', match: ['File Send To Persou', 'Card Received From Persou', 'Card Received in H O'] },
  { _id: 2, title: 'Card File Sent To Printer', match: ['Card Creation From Persou', 'Card Printed', 'Assigned to Executive', 'Card Activation Request Send', 'Card Collected', 'Fresh', 'Warm Block', 'Person Not Available', 'Request for Location Change'] },
  { _id: 4, title: "Card Delivered", match: ['Card Delivered', 'Card Activated', 'Hot Mark Failed', 'Hot Mark Requested', 'Active'] },
];
export const EMPLOYEE_TYPE = [
  { _id: "KAMELPAY", name: "Kamel Pay" },
  { _id: "OTHER_BANK", name: "Other Bank" },
];

export const EMPLOYEE_TYPE_OBJ = {
  KAMELPAY: "KAMELPAY",
  OTHER_BANK: "OTHER_BANK"
};

export const STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const STATUS_ARRAY = [
  { _id: "PENDING", name: "Pending" },
  { _id: "APPROVED", name: "Approved" },
  { _id: "REJECTED", name: "Rejected" },
];

export const ACL_OPTIONS = {
  DASHBOARD: ["DASHBOARD_VIEW"],
  APPROVAL: ["APPROVAL_VIEW", "APPROVAL_APPROVE/REJECT"],
  EMPLOYER: ["EMPLOYER_VIEW"],
  EXCHANGE_HOUSE: ["EXCHANGE_EXCHANGE_HOUSE_VIEW"],
  ADVANCE_SALARY: ["ADVANCE_SALARY_VIEW"],
  TRANSACTIONS: ["TRANSACTIONS_VIEW"],
  CENTIV_CARD: ["CENTIV_CARD_VIEW"],
  PROCESS_SALARIES: ["PROCESSSALARIES_CAN_PROCESS"],
  FIND_CARD_HOLDER: ["FIND_CARD_HOLDER_VIEW"],
  REMITTANCES: ["REMITTANCES_VIEW"],
  MARKETPLACE: [
    "MARKETPLACE_PRODUCTS_VIEW",//0
    "MARKETPLACE_PRODUCTS_ADD",//1
    "MARKETPLACE_PRODUCTS_IMAGES_UPLOAD",//2
    "MARKETPLACE_CATEGORIES/SUBCATEGORY_VIEW",//3
    "MARKETPLACE_CATEGORIES/SUBCATEGORY_ADD",//4
    "MARKETPLACE_SUPPLIERS_VIEW",//5
    "MARKETPLACE_SUPPLIERS_ADD",//6
    "MARKETPLACE_PRODUCT_INVENTORY_VIEW", //7
    "MARKETPLACE_PRODUCT_INVENTORY_UPDATE", //8
    "MARKETPLACE_ORDERS_VIEW",//9,
    "MARKETPLACE_ORDERS_ACTION",//10,
  ],
  // MANAGE_USERS: ["USERS_CARD_ACTIVE", 'USERS_PIN_CHANGE'],
};

export const DOCUMENTS = [
  { _id: 1, name: "Trade License", type: 'C' },
  { _id: 2, name: "Establishment Card", type: 'C' },
  { _id: 3, name: "Labour Card", type: 'C' },
  { _id: 4, name: "Sponsor Document", type: 'C' },
  { _id: 5, name: "Labour Card", type: 'E' },
  { _id: 6, name: "Emirates Id", type: 'E' },
  { _id: 7, name: "Residence Visa", type: 'E' },
  { _id: 8, name: "Passport", type: 'E' },
  { _id: 9, name: "Mansha List", type: 'C' },
  { _id: 10, name: "Sponsor Passport", type: 'C' },
  { _id: 11, name: "Sponsor Emirates Id", type: 'C' },
  { _id: 12, name: "KYCPeper", type: 'E' },
];

export const DOCUMENTS_OBJ = {
  1: { name: "Trade License", description: "Trade License", type: 'C' },
  2: { name: "Establishment Card", description: "Establishment Card", type: 'C' },
  3: { name: "Labour Card", description: "Labour Card", type: 'C' },
  4: { name: "Sponsor Document", description: "Sponsor Document", type: 'C' },
  5: { name: "Labour Card", description: "Labour Card", type: 'E' },
  6: { name: "Emirates Id", description: "Emirates Id", type: 'E' },
  7: { name: "Residence Visa", description: "Residence Visa", type: 'E' },
  8: { name: "Passport", description: "Passport", type: 'E' },
  9: { name: "Mansha List", description: "Mansha List", type: 'C' },
  10: { name: "Sponsor Passport", description: "Sponsor Passport", type: 'C' },
  11: { name: "Sponsor Emirates Id", description: "Sponsor Emirates Id", type: 'C' },
  12: { name: "KYCPeper", description: "KYC Paper", type: 'E' },
};

export const ROLES = [
  {
    _id: "SUB_ADMIN",
    name: "Sub Admin",
  },
  {
    _id: "CSR",
    name: "Customer Services",
  },
];

export const INITIALS = [
  {
    _id: "MR",
    name: "MR",
  },
  {
    _id: "MS",
    name: "MS",
  },
];

export const GENDER = [
  {
    _id: "M",
    name: "Male",
  },
  {
    _id: "F",
    name: "Female",
  },
];

export const CARD_API_IDS = {
  BLOCK_APPLICATION: "BLOCK_APPLICATION",
  ACTIVATE_APPLICATION: "ACTIVATE_APPLICATION",
  BLOCK_CARD: "BLOCK_CARD",
  ACTIVATE_CARD: "ACTIVATE_CARD",
  REPLACE_CARD: "REPLACE_CARD",
  USER_BLOCK_REQUEST: "USER_BLOCK_REQUEST",
};

export const WHITELIST_BLACKLISTCAPS = {
  WHITE_LIST: 'WHITELIST',
  BLACK_LIST: 'BLACKLIST'
}

export const WHITELIST_BLACKLIST = {
  WHITE_LIST: 'WhiteList',
  BLACK_LIST: 'BlackList'
}

export const REPORTS = [
  // {
  //   _id: "REMITTANCE",
  //   name: "Remittance Report",
  //   fileName: "remittance_report",
  //   url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/remittance+report.xlsx",
  // },
  // {
  //   _id: "CASHOUT",
  //   name: "Cashout Report",
  //   fileName: "cashout_report",
  //   url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/cash-withdrawal-report.xlsx",
  // },
  // {
  //   _id: "BILLPAY",
  //   name: "BillPay Report",
  //   fileName: "billPay_report",
  //   url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/billpay.xlsx",
  // },
  // {
  //   _id: "ADVANCE_SALARY",
  //   name: "Advance Salary Report",
  //   fileName: "advance_salary_report",
  //   url: "https://firebasestorage.googleapis.com/v0/b/kamelpay-2a233.appspot.com/o/advance%20salary.xlsx?alt=media&token=f29c52de-ca67-4fa4-a08d-27fcc7869340",
  // },
  {
    _id: "SALARY_PROCESSED",
    name: "Salary Processed Report",
    fileName: "salary_processed_report",
    url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/salaries-processed.xlsx",
  },
  {
    _id: "SALARY_NOT_PROCESSED",
    name: "Salary Not Processed",
    fileName: "salary_not_processed",
    url: "https://static-medias.s3.ap-southeast-1.amazonaws.com/salaries-processed-cards.xlsx",
  },
];

export const approvalStatusArrayClient = (topic, isInThreeStep) =>
  topic === TOPIC.ON_BOARD_REQUEST ||
    topic === TOPIC.EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST
    ? [
      { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
      { _id: "APPROVED_BY_CLIENT", title: "Documents Checked" },
      ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
      { _id: "", title: "Approved By Admin" },
      { _id: "", title: "Compliance Passed" },
      { _id: "APPROVED_BY_ADMIN", title: "Onboarded" },
    ]
    : topic === TOPIC.ESTABLISHMENT_IDS_ADDED || topic === TOPIC.ESTABLISHMENT_ID_ADDED || topic === TOPIC.BULK_ESTABLISHMENT_IDS_ADDED || topic === TOPIC.BULK_INACTIVE_EMPLOYEES || topic === TOPIC.BULK_ACTIVE_EMPLOYEES
      ? [
        { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
        { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
        ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
        { _id: "", title: "Approved By Admin" },
        { _id: "APPROVED_BY_ADMIN", title: "Processed" },
      ]
      : topic === TOPIC.INACTIVE_EMPLOYEES || topic === TOPIC.ACTIVE_EMPLOYEES || topic === TOPIC.CREATE_CAMP || topic === TOPIC.UPDATE_CAMP || topic === TOPIC.ASSIGN_EMPLOYEES_TO_CAMP
        ? [
          { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
          { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
          ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
          { _id: "", title: "Approved By Admin" },
          { _id: "APPROVED_BY_ADMIN", title: "Processed" },
        ]
        : topic === TOPIC.UPDATE_MOL_NO || TOPIC.UPDATE_NATIONALITY || TOPIC.UPDATE_EMP_CODE || TOPIC.UPDATE_PASSPORT || TOPIC.ADD_UPDATE_CASHBACK || TOPIC.ADD_PRODUCT || TOPIC.EDIT_PRODUCT || TOPIC.ADD_CHARGES || TOPIC.EDIT_CHARGES || TOPIC.ADD_BUSINESS_OWNER || TOPIC.EDIT_BUSINESS_OWNER || TOPIC.ADD_EDIT_ADDITIONAL_DETAILS || TOPIC.ADD_OPERATION_USER || TOPIC.EDIT_OPERATION_USER || TOPIC.CREATE_DISPUTE_FORM
          ? [
            { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
            { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
            ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
            { _id: "", title: "Approved By Admin" },
            { _id: "APPROVED_BY_ADMIN", title: "Processed" },
          ]
          : topic === TOPIC.EMPLOYEE_PHONE_UPDATE ||
            topic === TOPIC.EMPLOYEE_EID_UPDATE ||
            topic === TOPIC.UPDATE_DATE_OF_BIRTH ||
            topic === TOPIC.CARD_PIN_CHANGE ||
            topic === TOPIC.EMPLOYEE_UPDATE_REQUEST ||
            topic === TOPIC.TEAM_MEMBER_PHONE_UPDATE
            ? [
              { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
              { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
              ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
              { _id: "", title: "Approved By Admin" },
              { _id: "APPROVED_BY_ADMIN", title: "Processed" },
            ]
            : topic === TOPIC.CHANGE_EMPLOYEE_TYPE ||
              topic === TOPIC.EMPLOYEE_APPLICATION_BLOCK_REQUEST ||
              topic === TOPIC.EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST ||
              topic === TOPIC?.CENTIV_LOAD_FUNDS ||
              topic === TOPIC?.CHANGE_EMPLOYEE_TYPE_TO_KAMELPAY
              ? [
                { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
                ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                { _id: "", title: "Approved By Admin" },
                { _id: "APPROVED_BY_ADMIN", title: "Processed" },
              ]
              : topic === TOPIC.EMPLOYEE_ONBOARD_REQUEST
                ? [
                  { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                  { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
                  ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                  { _id: "", title: "Approved By Admin" },
                  { _id: "", title: "Documents Checked" },
                  { _id: "", title: "Compliance Passed" },
                  { _id: "", title: "Card Printed" },
                  { _id: "", title: "Courier on the way" },
                  { _id: "APPROVED_BY_ADMIN", title: "Card Delivered" },
                ]
                : topic === TOPIC.EMPLOYEES_FILE_UPLOAD
                  ? [
                    { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                    { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
                    ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                    { _id: "APPROVED_BY_ADMIN", title: "Approved By Admin" },
                    { _id: "", title: "Compliance Passed" },
                    { _id: "", title: "Card Printed" },
                    { _id: "", title: "Courier on the way" },
                    { _id: "APPROVED_BY_ADMIN", title: "Delivered" },
                  ]
                  : topic === TOPIC.CARD_REPLACEMENT_REQUEST
                    ? [
                      { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                      { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
                      ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                      { _id: "", title: "Approved By Admin" },
                      { _id: "", title: "Card Permanently Blocked" },
                      { _id: "", title: "Replacement Request Sent" },
                      { _id: "", title: "Card Printed" },
                      { _id: "", title: "Courier on the way" },
                      { _id: "APPROVED_BY_ADMIN", title: "Delivered" },
                    ]
                    : topic === TOPIC.DEPOSIT_FILE_UPLOAD
                      ? [
                        { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                        { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
                        ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                        { _id: "", title: "Approved By Admin" },
                        { _id: "APPROVED_BY_ADMIN", title: "Company Account Credited" },
                      ]
                      : topic === TOPIC.SALARY_FILE_UPLOAD
                        ? [
                          { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                          { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
                          ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                          { _id: "", title: "Approved By Admin" },
                          { _id: "APPROVED_BY_ADMIN", title: "Salary Processed" },
                        ]
                        : topic === TOPIC.CLIENT_PROFILE_UPDATE || topic === TOPIC.EMPLOYEE_STATEMENT_REQUEST
                          ? [
                            { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                            { _id: "APPROVED_BY_CLIENT", title: "Approved By Client" },
                            ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                            { _id: "APPROVED_BY_ADMIN", title: "Approved By Admin" },
                          ]
                          : topic === TOPIC.EOS_FILE_UPLOAD
                            ? [
                              { _id: "APPROVED_BY_CLIENT", title: "Pending" },
                              ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                              { _id: "APPROVED_BY_ADMIN", title: "Approved By Admin" },
                            ]
                            : [];

export const approvalStatusArrayExchange = (topic, isInThreeStep) =>
  topic === TOPIC.ON_BOARD_REQUEST ||
    topic === TOPIC.EXCHANGE_HOUSE_BRANCH_ONBOARD_REQUEST
    ? [
      { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
      { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
      ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
      { _id: "", title: "Approved By Admin" },
      { _id: "", title: "Compliance Passed" },
      { _id: "APPROVED_BY_ADMIN", title: "Onboarded" },
    ]
    : topic === TOPIC.ESTABLISHMENT_IDS_ADDED || topic === TOPIC.ESTABLISHMENT_ID_ADDED || topic === TOPIC.BULK_ESTABLISHMENT_IDS_ADDED
      ? [
        { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
        { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
        ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
        { _id: "", title: "Approved By Admin" },
        { _id: "APPROVED_BY_ADMIN", title: "Processed" },
      ]
      : topic === TOPIC.UPDATE_MOL_NO || TOPIC.UPDATE_NATIONALITY || TOPIC.UPDATE_EMP_CODE || TOPIC.UPDATE_PASSPORT || TOPIC.ADD_UPDATE_CASHBACK || TOPIC.ADD_PRODUCT || TOPIC.EDIT_PRODUCT || TOPIC.ADD_CHARGES || TOPIC.EDIT_CHARGES || TOPIC.ADD_BUSINESS_OWNER || TOPIC.EDIT_BUSINESS_OWNER || TOPIC.ADD_EDIT_ADDITIONAL_DETAILS || TOPIC.ADD_OPERATION_USER || TOPIC.EDIT_OPERATION_USER
        ? [
          { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
          { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
          ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
          { _id: "", title: "Approved By Admin" },
          { _id: "APPROVED_BY_ADMIN", title: "Processed" },
        ]
        : topic === TOPIC.EMPLOYEE_PHONE_UPDATE ||
          topic === TOPIC.EMPLOYEE_EID_UPDATE ||
          topic === TOPIC.UPDATE_DATE_OF_BIRTH ||
          topic === TOPIC.CARD_PIN_CHANGE
          ? [
            { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
            { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
            ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
            { _id: "", title: "Approved By Admin" },
            { _id: "APPROVED_BY_ADMIN", title: "Processed" },
          ]
          : topic === TOPIC.CHANGE_EMPLOYEE_TYPE ||
            topic === TOPIC.EMPLOYEE_APPLICATION_BLOCK_REQUEST ||
            topic === TOPIC.EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST ||
            topic === TOPIC?.CENTIV_LOAD_FUNDS
            ? [
              { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
              { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
              ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
              { _id: "", title: "Approved By Admin" },
              { _id: "APPROVED_BY_ADMIN", title: "Processed" },
            ]
            : topic === TOPIC.EMPLOYEE_ONBOARD_REQUEST
              ? [
                { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
                ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                { _id: "", title: "Approved By Admin" },
                { _id: "", title: "Documents Checked" },
                { _id: "", title: "Compliance Passed" },
                { _id: "", title: "Card Printed" },
                { _id: "", title: "Courier on the way" },
                { _id: "APPROVED_BY_ADMIN", title: "Card Delivered" },
              ]
              : topic === TOPIC.EMPLOYEES_FILE_UPLOAD
                ? [
                  { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                  { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
                  ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                  { _id: "APPROVED_BY_ADMIN", title: "Approved By Admin" },
                  { _id: "", title: "Compliance Passed" },
                  { _id: "", title: "Card Printed" },
                  { _id: "", title: "Courier on the way" },
                  { _id: "APPROVED_BY_ADMIN", title: "Delivered" },
                ]
                : topic === TOPIC.CARD_REPLACEMENT_REQUEST
                  ? [
                    { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                    { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
                    ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                    { _id: "", title: "Approved By Admin" },
                    { _id: "", title: "Card Permanently Blocked" },
                    { _id: "", title: "Replacement Request Sent" },
                    { _id: "", title: "Card Printed" },
                    { _id: "", title: "Courier on the way" },
                    { _id: "APPROVED_BY_ADMIN", title: "Delivered" },
                  ]
                  : topic === TOPIC.DEPOSIT_FILE_UPLOAD
                    ? [
                      { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                      { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
                      ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                      { _id: "", title: "Approved By Admin" },
                      { _id: "APPROVED_BY_ADMIN", title: "Company Account Credited" },
                    ]
                    : topic === TOPIC.SALARY_FILE_UPLOAD
                      ? [
                        { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                        { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
                        ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                        { _id: "", title: "Approved By Admin" },
                        { _id: "APPROVED_BY_ADMIN", title: "Salary Processed" },
                      ]
                      : topic === TOPIC.CLIENT_PROFILE_UPDATE || topic === TOPIC.EMPLOYEE_STATEMENT_REQUEST
                        ? [
                          { _id: "APPROVED_BY_CLIENT_ADMIN", title: "Pending" },
                          { _id: "APPROVED_BY_CLIENT", title: "Approved By Exchange House" },
                          ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                          { _id: "APPROVED_BY_ADMIN", title: "Approved By Admin" },
                        ]
                        : topic === TOPIC.EOS_FILE_UPLOAD
                          ? [
                            { _id: "APPROVED_BY_CLIENT", title: "Pending" },
                            ...(isInThreeStep ? [{ _id: "APPROVED_BY_ADMIN_AUTHORIZER", title: "Waiting for Authorizer" }] : []),
                            { _id: "APPROVED_BY_ADMIN", title: "Approved By Admin" },
                          ]
                          : [];

export const LANGUAGES = [
    {
        _id: "ar",
        name: "Arabic",
        direction: 'rtl',
        subTitle: 'سيظهر التطبيق بهذه الطريقة',
        // icon: require('../../assets/images/flags/flag_UAE.png')
    },
    {
        _id: "en",
        name: "English",
        direction: 'ltr',
        subTitle: 'App will shown this way',
        // icon: require('../../assets/images/flags/flag_UK.png')
    },
    {
        _id: "ur",
        name: "Urdu",
        direction: 'rtl',
        subTitle: 'ایپ اس طرح دکھائے گی۔',
        // icon: require('../../assets/images/flags/flag_PK.png')
    },
    {
        _id: "hi",
        name: "Hindi",
        direction: 'ltr',
        subTitle: 'ऐप इस तरह दिखाएगा',
        // icon: require('../../assets/images/flags/flag_IN.png')
    },
    {
        _id: "ml",
        name: "Malayalam",
        direction: 'ltr',
        subTitle: 'ആപ്പ് ഈ രീതിയിൽ കാണിക്കും',
        // icon: require('../../assets/images/flags/flag_IN.png')
    },
    {
        _id: "ta",
        name: "Tamil",
        direction: 'ltr',
        subTitle: 'பயன்பாடு இந்த வழியில் காட்டப்படும்',
        // icon: require('../../assets/images/flags/flag_IN.png')
    },
    {
        _id: "hn",
        name: "Hinglish",
        direction: 'ltr',
        subTitle: 'aip is tarah dikhaega',
        // icon: require('../../assets/images/flags/flag_IN.png')
    },
];

export const NATIONALITY = [
  { _id: "AF", name: "Afghanistan", cca3: "AFG" },
  { _id: "AX", name: "Aland Islands", cca3: "ALA" },
  { _id: "AL", name: "Albania", cca3: "ALB" },
  { _id: "DZ", name: "Algeria", cca3: "DZA" },
  { _id: "AS", name: "American Samoa", cca3: "ASM" },
  { _id: "AD", name: "Andorra", cca3: "AND" },
  { _id: "AO", name: "Angola", cca3: "AGO" },
  { _id: "AI", name: "Anguilla", cca3: "AIA" },
  { _id: "AQ", name: "Antarctica", cca3: "ATA" },
  { _id: "AG", name: "Antigua And Barbuda", cca3: "ATG" },
  { _id: "AR", name: "Argentina", cca3: "ARG" },
  { _id: "AM", name: "Armenia", cca3: "ARM" },
  { _id: "AW", name: "Aruba", cca3: "ABW" },
  { _id: "AU", name: "Australia", cca3: "AUS" },
  { _id: "AT", name: "Austria", cca3: "AUT" },
  { _id: "AZ", name: "Azerbaijan", cca3: "AZE" },
  { _id: "BS", name: "Bahamas", cca3: "BHS" },
  { _id: "BH", name: "Bahrain", cca3: "BHR" },
  { _id: "BD", name: "Bangladesh", cca3: "BGD" },
  { _id: "BB", name: "Barbados", cca3: "BRB" },
  { _id: "BY", name: "Belarus", cca3: "BLR" },
  { _id: "BE", name: "Belgium", cca3: "BEL" },
  { _id: "BZ", name: "Belize", cca3: "BLZ" },
  { _id: "BJ", name: "Benin", cca3: "BEN" },
  { _id: "BM", name: "Bermuda", cca3: "BMU" },
  { _id: "BT", name: "Bhutan", cca3: "BTN" },
  { _id: "BO", name: "Bolivia", cca3: "BOL" },
  { _id: "BQ", name: "Caribbean Netherland", cca3: "BES" },
  { _id: "BA", name: "Bosnia - Herzegovina", cca3: "BIH" },
  { _id: "BW", name: "Botswana", cca3: "BWA" },
  { _id: "BV", name: "Bouvet Island", cca3: "BVT" },
  { _id: "BR", name: "Brazil", cca3: "BRA" },
  { _id: "IO", name: "British Indian Ocean", cca3: "IOT" },
  { _id: "VG", name: "US Virgin Islands", cca3: "VGB" },
  { _id: "BN", name: "Brunei Darussalam", cca3: "BRN" },
  { _id: "BG", name: "Bulgaria", cca3: "BGR" },
  { _id: "BF", name: "Burkina Faso", cca3: "BFA" },
  { _id: "BI", name: "Burundi", cca3: "BDI" },
  { _id: "KH", name: "Cambodia", cca3: "KHM" },
  { _id: "CM", name: "Cameroon", cca3: "CMR" },
  { _id: "CA", name: "Canada", cca3: "CAN" },
  { _id: "CV", name: "Cape Verde", cca3: "CPV" },
  { _id: "KY", name: "Cayman Islands", cca3: "CYM" },
  { _id: "CF", name: "Central African", cca3: "CAF" },
  { _id: "TD", name: "Chad", cca3: "TCD" },
  { _id: "CL", name: "Chile", cca3: "CHL" },
  { _id: "CX", name: "Christmas Islands", cca3: "CXR" },
  { _id: "CC", name: "Cocos Islands", cca3: "CCK" },
  { _id: "CO", name: "Columbia", cca3: "COL" },
  { _id: "KM", name: "Comoro Islands", cca3: "COM" },
  { _id: "CG", name: "Congo", cca3: "COG" },
  { _id: "CD", name: "Congo - Kinshasa", cca3: "COD" },
  { _id: "CK", name: "Cook Islands", cca3: "COK" },
  { _id: "CR", name: "Costa Rica", cca3: "CRI" },
  { _id: "CI", name: "Cote D'Ivore", cca3: "CIV" },
  { _id: "XP", name: "Country XPD & XPT", cca3: "XPD" },
  { _id: "HR", name: "Croatia", cca3: "HRV" },
  { _id: "CU", name: "Cuba", cca3: "CUB" },
  { _id: "CW", name: "Curacao", cca3: "CUW" },
  { _id: "CY", name: "Cyprus", cca3: "CYP" },
  { _id: "CZ", name: "Czech Republic", cca3: "CZE" },
  { _id: "DK", name: "Denmark", cca3: "DNK" },
  { _id: "DJ", name: "Djibouti", cca3: "DJI" },
  { _id: "DM", name: "Dominica", cca3: "DMA" },
  { _id: "DO", name: "Dominican Republic", cca3: "DOM" },
  { _id: "TL", name: "East Timor", cca3: "TLS" },
  { _id: "EC", name: "Ecuador", cca3: "ECU" },
  { _id: "EG", name: "Egypt", cca3: "EGY" },
  { _id: "SV", name: "El Salvador", cca3: "SLV" },
  { _id: "GQ", name: "Equatorial Guinea", cca3: "GNQ" },
  { _id: "ER", name: "Eritrea", cca3: "ERI" },
  { _id: "EE", name: "Estonia", cca3: "EST" },
  { _id: "ET", name: "Ethiopia", cca3: "ETH" },
  { _id: "XE", name: "Europa", cca3: "XEU" },
  { _id: "EU", name: "European In Country", cca3: "EUR" },
  { _id: "FK", name: "Falkland Islands", cca3: "FLK" },
  { _id: "FO", name: "Faroe Islands", cca3: "FRO" },
  { _id: "FJ", name: "Fiji", cca3: "FJI" },
  { _id: "FI", name: "Finland", cca3: "FIN" },
  { _id: "FR", name: "France", cca3: "FRA" },
  { _id: "GF", name: "French Guiana", cca3: "GUF" },
  { _id: "PF", name: "French Polynesia", cca3: "PYF" },
  { _id: "TF", name: "French Southern Ter", cca3: "ATF" },
  { _id: "GA", name: "Gabon", cca3: "GAB" },
  { _id: "GM", name: "Gambia", cca3: "GMB" },
  { _id: "GE", name: "Georgia", cca3: "GEO" },
  { _id: "DE", name: "Germany", cca3: "DEU" },
  { _id: "GH", name: "Ghana", cca3: "GHA" },
  { _id: "GI", name: "Gibraltar", cca3: "GIB" },
  { _id: "GR", name: "Greece", cca3: "GRC" },
  { _id: "GL", name: "Greenland", cca3: "GRL" },
  { _id: "GD", name: "Grenada", cca3: "GRD" },
  { _id: "GP", name: "Guadeloupe", cca3: "GLP" },
  { _id: "GU", name: "Guam", cca3: "GUM" },
  { _id: "GT", name: "Guatemala", cca3: "GTM" },
  { _id: "GG", name: "Guernsey Minor Ch", cca3: "GGY" },
  { _id: "GN", name: "Guinea", cca3: "GIN" },
  { _id: "GW", name: "Guinea-Bissau", cca3: "GNB" },
  { _id: "GY", name: "Guyana", cca3: "GUY" },
  { _id: "HT", name: "Haiti", cca3: "HTI" },
  { _id: "HM", name: "Heard Island", cca3: "HMD" },
  { _id: "VA", name: "Holy See", cca3: "VAT" },
  { _id: "HN", name: "Honduras", cca3: "HND" },
  { _id: "HK", name: "Hong Kong", cca3: "HKG" },
  { _id: "HU", name: "Hungary", cca3: "HUN" },
  { _id: "IS", name: "Iceland", cca3: "ISL" },
  { _id: "IN", name: "India", cca3: "IND" },
  { _id: "ID", name: "Indonesia", cca3: "IDN" },
  { _id: "IR", name: "Iran", cca3: "IRN" },
  { _id: "IQ", name: "Iraq", cca3: "IRQ" },
  { _id: "IE", name: "Ireland", cca3: "IRL" },
  { _id: "IM", name: "Isle of Man", cca3: "IMN" },
  { _id: "IL", name: "Israel", cca3: "ISR" },
  { _id: "IT", name: "Italy", cca3: "ITA" },
  { _id: "JM", name: "Jamaica", cca3: "JAM" },
  { _id: "JP", name: "Japan", cca3: "JPN" },
  { _id: "JE", name: "Jersey", cca3: "JEY" },
  { _id: "JO", name: "Jordan", cca3: "JOR" },
  { _id: "KZ", name: "Kazakhstan", cca3: "KAZ" },
  { _id: "KE", name: "Kenya", cca3: "KEN" },
  { _id: "KI", name: "Kiribati", cca3: "KIR" },
  { _id: "KP", name: "North Korea", cca3: "PRK" },
  { _id: "KR", name: "South Korea", cca3: "KOR" },
  { _id: "KW", name: "Kuwait", cca3: "KWT" },
  { _id: "KG", name: "Kyrgyzstan", cca3: "KGZ" },
  { _id: "LA", name: "Laos", cca3: "LAO" },
  { _id: "LV", name: "Latvia", cca3: "LVA" },
  { _id: "LB", name: "Lebanon", cca3: "LBN" },
  { _id: "LS", name: "Lesotho", cca3: "LSO" },
  { _id: "LR", name: "Liberia", cca3: "LBR" },
  { _id: "LY", name: "Libya", cca3: "LBY" },
  { _id: "LI", name: "Liechtenstein", cca3: "LIE" },
  { _id: "LT", name: "Lithuania", cca3: "LTU" },
  { _id: "LU", name: "Luxembourg", cca3: "LUX" },
  { _id: "MO", name: "Macau", cca3: "MAC" },
  { _id: "MK", name: "Macedonia", cca3: "MKD" },
  { _id: "MG", name: "Madagascar", cca3: "MDG" },
  { _id: "MW", name: "Malawi", cca3: "MWI" },
  { _id: "MY", name: "Malaysia", cca3: "MYS" },
  { _id: "MV", name: "Maldives", cca3: "MDV" },
  { _id: "ML", name: "Mali", cca3: "MLI" },
  { _id: "MT", name: "Malta", cca3: "MLT" },
  { _id: "MH", name: "Marshall Island", cca3: "MHL" },
  { _id: "MQ", name: "Martinique", cca3: "MTQ" },
  { _id: "MR", name: "Mauritania", cca3: "MRT" },
  { _id: "MU", name: "Mauritius", cca3: "MUS" },
  { _id: "YT", name: "Mayotte", cca3: "MYT" },
  { _id: "MX", name: "Mexico", cca3: "MEX" },
  { _id: "FM", name: "Micronesia", cca3: "FSM" },
  { _id: "MD", name: "Moldova, Republic of", cca3: "MDA" },
  { _id: "MC", name: "Monaco", cca3: "MCO" },
  { _id: "MN", name: "Mongolia", cca3: "MNG" },
  { _id: "MS", name: "Montserrat", cca3: "MSR" },
  { _id: "ME", name: "Montenegro", cca3: "MNE" },
  { _id: "MA", name: "Morocco", cca3: "MAR" },
  { _id: "MZ", name: "Mozambique", cca3: "MOZ" },
  { _id: "MM", name: "Myanmar", cca3: "MMR" },
  { _id: "NA", name: "Namibia", cca3: "NAM" },
  { _id: "NR", name: "Nauru", cca3: "NRU" },
  { _id: "NP", name: "Nepal", cca3: "NPL" },
  { _id: "NL", name: "Netherlands", cca3: "NLD" },
  { _id: "AN", name: "Netherlands Antilles", cca3: "ANT" },
  { _id: "NC", name: "New Caledonia", cca3: "NCL" },
  { _id: "NZ", name: "New Zealand", cca3: "NZL" },
  { _id: "NI", name: "Nicaragua", cca3: "NIC" },
  { _id: "NE", name: "Niger", cca3: "NER" },
  { _id: "NG", name: "Nigeria", cca3: "NGA" },
  { _id: "NU", name: "Niue", cca3: "NIU" },
  { _id: "NF", name: "Norfolk Island", cca3: "NFK" },
  { _id: "MP", name: "Mariana Islands", cca3: "MNP" },
  { _id: "NO", name: "Norway", cca3: "NOR" },
  { _id: "OM", name: "Oman", cca3: "OMN" },
  { _id: "PK", name: "Pakistan", cca3: "PAK" },
  { _id: "PW", name: "Palau", cca3: "PLW" },
  { _id: "PS", name: "Palestine", cca3: "PSE" },
  { _id: "PA", name: "Panama", cca3: "PAN" },
  { _id: "PG", name: "Papua New Guinea", cca3: "PNG" },
  { _id: "PY", name: "Paraguay", cca3: "PRY" },
  { _id: "CN", name: "Rep of China", cca3: "CHN" },
  { _id: "PE", name: "Peru", cca3: "PER" },
  { _id: "PH", name: "Philippines", cca3: "PHL" },
  { _id: "PN", name: "Pitcairn", cca3: "PCN" },
  { _id: "PI", name: "PLATINUM", cca3: "XXX" }, // "XXX" placeholder, real cca3 unknown
  { _id: "PL", name: "Poland", cca3: "POL" },
  { _id: "PT", name: "Portugal", cca3: "PRT" },
  { _id: "PR", name: "Puerto Rico", cca3: "PRI" },
  { _id: "QA", name: "Qatar", cca3: "QAT" },
  { _id: "TW", name: "Rep of China (Taiwan)", cca3: "TWN" },
  { _id: "RE", name: "Reunion", cca3: "REU" },
  { _id: "RO", name: "Romania", cca3: "ROU" },
  { _id: "RU", name: "Russian Federation", cca3: "RUS" },
  { _id: "RW", name: "Rwanda", cca3: "RWA" },
  { _id: "BL", name: "Saint Barthelemy", cca3: "BLM" },
  { _id: "KN", name: "Saint Kitts and Nevis", cca3: "KNA" },
  { _id: "LC", name: "Saint Lucia", cca3: "LCA" },
  { _id: "SX", name: "Saint Maarten (Dutch)", cca3: "SXM" },
  { _id: "MF", name: "Saint Martin (French)", cca3: "MAF" },
  { _id: "WS", name: "Samoa", cca3: "WSM" },
  { _id: "SM", name: "San Marino", cca3: "SMR" },
  { _id: "ST", name: "Sao Tome and Principe", cca3: "STP" },
  { _id: "SA", name: "Saudi Arabia", cca3: "SAU" },
  { _id: "SN", name: "Senegal", cca3: "SEN" },
  { _id: "RS", name: "Serbia", cca3: "SRB" },
  { _id: "SC", name: "Seychelles", cca3: "SYC" },
  { _id: "SL", name: "Sierra Leone", cca3: "SLE" },
  { _id: "XS", name: "Silver", cca3: "XXX" },
  { _id: "SS", name: "SILVER", cca3: "XXX" },
  { _id: "SG", name: "Singapore", cca3: "SGP" },
  { _id: "SK", name: "Slovakia", cca3: "SVK" },
  { _id: "SI", name: "Slovenia", cca3: "SVN" },
  { _id: "SB", name: "Solomon Islands", cca3: "SLB" },
  { _id: "SO", name: "Somalia", cca3: "SOM" },
  { _id: "ZA", name: "South Africa", cca3: "ZAF" },
  { _id: "GS", name: "South Georgia", cca3: "SGS" },
  { _id: "ES", name: "Spain", cca3: "ESP" },
  { _id: "LK", name: "Sri Lanka", cca3: "LKA" },
  { _id: "SH", name: "St. Helena", cca3: "SHN" },
  { _id: "PM", name: "St Pierre & Miquelon", cca3: "SPM" },
  { _id: "VC", name: "St Vincent Grenadine", cca3: "VCT" },
  { _id: "QQ", name: "Stateless", cca3: "XXX" },
  { _id: "SP", name: "Stateless Persons", cca3: "XXX" },
  { _id: "SD", name: "Sudan", cca3: "SDN" },
  { _id: "SR", name: "Suriname", cca3: "SUR" },
  { _id: "SJ", name: "Svalbard & Jan Mayen", cca3: "SJM" },
  { _id: "SZ", name: "Swaziland", cca3: "SWZ" },
  { _id: "SE", name: "Sweden", cca3: "SWE" },
  { _id: "CH", name: "Switzerland", cca3: "CHE" },
  { _id: "SY", name: "Syrian Arab Republic", cca3: "SYR" },
  { _id: "TJ", name: "Tajikistan", cca3: "TJK" },
  { _id: "TZ", name: "Tanzania", cca3: "TZA" },
  { _id: "TH", name: "Thailand", cca3: "THA" },
  { _id: "TL", name: "Timor-Leste", cca3: "TLS" },
  { _id: "TG", name: "Togo", cca3: "TGO" },
  { _id: "TK", name: "Tokelau", cca3: "TKL" },
  { _id: "TO", name: "Tonga", cca3: "TON" },
  { _id: "TT", name: "Trinidad and Tobago", cca3: "TTO" },
  { _id: "TN", name: "Tunisia", cca3: "TUN" },
  { _id: "TR", name: "Turkey", cca3: "TUR" },
  { _id: "TM", name: "Turkmenistan", cca3: "TKM" },
  { _id: "TC", name: "Turks and Caicos", cca3: "TCA" },
  { _id: "TV", name: "Tuvalu", cca3: "TUV" },
  { _id: "UG", name: "Uganda", cca3: "UGA" },
  { _id: "UA", name: "Ukraine", cca3: "UKR" },
  { _id: "AE", name: "United Arab Emirates", cca3: "ARE" },
  { _id: "GB", name: "United Kingdom of GB and NI", cca3: "GBR" },
  { _id: "UM", name: "United States Minor Outlying Islands", cca3: "UMI" },
  { _id: "US", name: "United States", cca3: "USA" },
  { _id: "UY", name: "Uruguay", cca3: "URY" },
  { _id: "YU", name: "USE RS (SERBIA)", cca3: "YUG" },
  { _id: "UZ", name: "Uzbekistan", cca3: "UZB" },
  { _id: "VU", name: "Vanuatu", cca3: "VUT" },
  { _id: "VE", name: "Venezuela", cca3: "VEN" },
  { _id: "VN", name: "Vietnam", cca3: "VNM" },
  { _id: "VI", name: "Virgin Islands, U.S.", cca3: "VIR" },
  { _id: "WF", name: "Wallis et Futuna", cca3: "WLF" },
  { _id: "EH", name: "Western Sahara", cca3: "ESH" },
  { _id: "XX", name: "Worldwide", cca3: "XXX" },
  { _id: "YE", name: "Yemen", cca3: "YEM" },
  { _id: "ZM", name: "Zambia", cca3: "ZMB" },
  { _id: "ZW", name: "Zimbabwe", cca3: "ZWE" }
];

export const BANKS = [
  {
    _id: "Abu Dhabi Commercial Bank",
    name: "Abu Dhabi Commercial Bank",
    routingCode: "600310101",
    swiftCode: "ADCBAEAA",
    shortCode: "ADCB"
  },
  {
    _id: "Abu Dhabi Islamic Bank",
    name: "Abu Dhabi Islamic Bank",
    routingCode: "405010101",
    swiftCode: "ABDIAEAD",
    shortCode: "ABDI"
  },
  {
    _id: "Ajman Bank",
    name: "Ajman Bank",
    routingCode: "805740101",
    swiftCode: "AJMNAEAJ",
    shortCode: "AJMN"
  },
  {
    _id: "Al Hilal Bank",
    name: "Al Hilal Bank",
    routingCode: "105310101",
    swiftCode: "HLALAEAA",
    shortCode: "HLAL"
  },
  {
    _id: "Al Maryah Community Bank",
    name: "Al Maryah Community Bank",
    routingCode: "009710001",
    swiftCode: "FRNYUS33",
    shortCode: "AMCB"
  },
  {
    _id: "Al Masraf Arab Bank for Investment & Foreign Trade",
    name: "Al Masraf Arab Bank for Investment & Foreign Trade",
    routingCode: "100810101",
    swiftCode: "ABINAEAA",
    shortCode: "ABIN"
  },
  {
    _id: "Bank of Sharjah",
    name: "Bank of Sharjah",
    routingCode: "401230101",
    swiftCode: "SHARAEAS",
    shortCode: "SHAR"
  },
  {
    _id: "Commercial Bank International",
    name: "Commercial Bank International",
    routingCode: "002220101",
    swiftCode: "CLBIAEAD",
    shortCode: "CLBI"
  },
  {
    _id: "Commercial Bank of Dubai",
    name: "Commercial Bank of Dubai",
    routingCode: "102320150",
    swiftCode: "CBDUAEAD",
    shortCode: "CBDU"
  },
  {
    _id: "Dubai Bank",
    name: "Dubai Bank",
    routingCode: "005120101",
    swiftCode: "DBXPAEAD",
    shortCode: "DBXP"
  },
  {
    _id: "Dubai Islamic Bank",
    name: "Dubai Islamic Bank",
    routingCode: "802420101",
    swiftCode: "DUIBAEAD",
    shortCode: "DUIB"
  },
  {
    _id: "Emirates Investment Bank",
    name: "Emirates Investment Bank",
    routingCode: "004820101",
    swiftCode: "AEINAEAD",
    shortCode: "AEIN"
  },
  {
    _id: "Emirates Islamic",
    name: "Emirates Islamic",
    routingCode: "703420114",
    swiftCode: "MEBLAEAD",
    shortCode: "MEBL"
  },
  {
    _id: "Emirates NBD",
    name: "Emirates NBD",
    routingCode: "302620122",
    swiftCode: "EBILAEAD",
    shortCode: "EBIL"
  },
  {
    _id: "First Abu Dhabi Bank",
    name: "First Abu Dhabi Bank",
    routingCode: "803510106",
    swiftCode: "NBADAEAA",
    shortCode: "NBAD"
  },
  {
    _id: "Invest Bank",
    name: "Invest Bank",
    routingCode: "503030102",
    swiftCode: "IBTFAEAS",
    shortCode: "IBTF"
  },
  {
    _id: "Mashreq",
    name: "Mashreq",
    routingCode: "203320101",
    swiftCode: "BOMLAEAD",
    shortCode: "BOML"
  },
  {
    _id: "National Bank of Fujairah",
    name: "National Bank of Fujairah",
    routingCode: "703820101",
    swiftCode: "NBFUAEAF",
    shortCode: "NBFU"
  },
  {
    _id: "National Bank of Ras Al-Khaimah PJSC (RAKBANK)",
    name: "National Bank of Ras Al-Khaimah PJSC (RAKBANK)",
    routingCode: "104060106",
    swiftCode: "NRAKAEAK",
    shortCode: "NRAK"
  },
  {
    _id: "National Bank of Umm Al-Quwain",
    name: "National Bank of Umm Al-Quwain",
    routingCode: "104251001",
    swiftCode: "UMMQAEAD",
    shortCode: "UMMQ"
  },
  {
    _id: "Noor Bank",
    name: "Noor Bank",
    routingCode: "905220101",
    swiftCode: "NISLAEAD",
    shortCode: "NISL"
  },
  {
    _id: "Sharjah Islamic Bank",
    name: "Sharjah Islamic Bank",
    routingCode: "404130101",
    swiftCode: "NBSHAEAS",
    shortCode: "NBSH"
  },
  {
    _id: "United Arab Bank",
    name: "United Arab Bank",
    routingCode: "904630101",
    swiftCode: "UARBAEAA",
    shortCode: "UARB"
  },
  {
    _id: "United Bank Ltd.",
    name: "United Bank Ltd.",
    routingCode: "604720106",
    swiftCode: "UNILAEAD",
    shortCode: "UNIL"
  },
  {
    _id: "Standard Chartered Bank",
    name: "Standard Chartered Bank",
    routingCode: "504420120",
    swiftCode: "SCBLAEAD",
    shortCode: "SCBL"
  },
  {
    _id: "Saudi National Bank",
    name: "Saudi National Bank",
    routingCode: "605520101",
    swiftCode: "SAMBAEAD",
    shortCode: "SAMB"
  },
  {
    _id: "Rafidain Bank",
    name: "Rafidain Bank",
    routingCode: "400510101",
    swiftCode: "RAFBAEAD",
    shortCode: "RAFB"
  },
  {
    _id: "National Bank of Oman",
    name: "National Bank of Oman",
    routingCode: "903910101",
    swiftCode: "NBOMAEAD",
    shortCode: "NBOM"
  },
  {
    _id: "National Bank of Kuwait",
    name: "National Bank of Kuwait",
    routingCode: "505620101",
    swiftCode: "NBOKAEAD",
    shortCode: "NBOK"
  },
  {
    _id: "National Bank of Bahrain",
    name: "National Bank of Bahrain",
    routingCode: "203610101",
    swiftCode: "NBOBAEAA",
    shortCode: "NBOB"
  },
  {
    _id: "MCB Bank Limited",
    name: "MCB Bank Limited",
    routingCode: "209120101",
    swiftCode: "MUCBAEAD",
    shortCode: "MUCBPKKANGL"
  },
  {
    _id: "KEB Hana Bank",
    name: "KEB Hana Bank",
    routingCode: "408910101",
    swiftCode: "KOEXKRSE",
    shortCode: "KEB"
  },
  {
    _id: "Janata Bank Limited",
    name: "Janata Bank Limited",
    routingCode: "103110110",
    swiftCode: "JANBAEAA",
    shortCode: "JANB"
  },
  {
    _id: "Intesa Sanpaolo S.P.A",
    name: "Intesa Sanpaolo S.P.A",
    routingCode: "309314334",
    swiftCode: "BCITITMM",
    shortCode: "ISP"
  },
  {
    _id: "Industrial & Commercial Bank of China",
    name: "Industrial & Commercial Bank of China",
    routingCode: "804310101",
    swiftCode: "ICBKAEAA",
    shortCode: "ICBK"
  },
  {
    _id: "HSBC Bank Middle East Limited",
    name: "HSBC Bank Middle East Limited",
    routingCode: "302020120",
    swiftCode: "BBMEAEAD",
    shortCode: "BBME"
  },
  {
    _id: "Habib Bank Ltd.",
    name: "Habib Bank Ltd.",
    routingCode: "102820111",
    swiftCode: "HABBAEAD",
    shortCode: "HABB"
  },
  {
    _id: "Habib Bank A.G Zurich",
    name: "Habib Bank A.G Zurich",
    routingCode: "302920101",
    swiftCode: "HBZUAEAD",
    shortCode: "HBZU"
  },
  {
    _id: "Gulf International Bank",
    name: "Gulf International Bank",
    routingCode: "509210001",
    swiftCode: "GULFAEAA",
    shortCode: "GIB"
  },
  {
    _id: "El Nilein Bank",
    name: "El Nilein Bank",
    routingCode: "002510101",
    swiftCode: "NILBAEAA",
    shortCode: "NILB"
  },
  {
    _id: "Doha Bank",
    name: "Doha Bank",
    routingCode: "705420101",
    swiftCode: "DOHBAEAD",
    shortCode: "DOHB"
  },
  {
    _id: "Deutsche Bank AG",
    name: "Deutsche Bank AG",
    routingCode: "204910101",
    swiftCode: "DEUTAEAA",
    shortCode: "DEUT"
  },
  {
    _id: "Credit Agricloe",
    name: "Credit Agricloe",
    routingCode: "301620101",
    swiftCode: "BSUIAEAD",
    shortCode: "BSUI"
  },
  {
    _id: "CitiBank N. A.",
    name: "CitiBank N. A.",
    routingCode: "102120101",
    swiftCode: "CITIAEAD",
    shortCode: "CITI"
  },
  {
    _id: "BNP Paribas",
    name: "BNP Paribas",
    routingCode: "401810101",
    swiftCode: "BNPAAEAA",
    shortCode: "BNPA"
  },
  {
    _id: "Barclays Bank PLC",
    name: "Barclays Bank PLC",
    routingCode: "401920110",
    swiftCode: "BARCAEAD",
    shortCode: "BARC"
  },
  {
    _id: "Banque Misr",
    name: "Banque Misr",
    routingCode: "001510102",
    swiftCode: "BMISAEAA",
    shortCode: "BMIS"
  },
  {
    _id: "Bank Saderat Iran",
    name: "Bank Saderat Iran",
    routingCode: "901320124",
    swiftCode: "BSIRAEAD",
    shortCode: "BSIR"
  },
  {
    _id: "Bank of China Limited",
    name: "Bank of China Limited",
    routingCode: "309010188",
    swiftCode: "BKCHSGSG",
    shortCode: "BOC"
  },
  {
    _id: "Bank of Baroda",
    name: "Bank of Baroda",
    routingCode: "801120101",
    swiftCode: "BARBAEAD",
    shortCode: "BARB"
  },
  {
    _id: "Bank Melli Iran",
    name: "Bank Melli Iran",
    routingCode: "901020101",
    swiftCode: "MELIAEAD",
    shortCode: "MELI"
  },
  {
    _id: "Bank Al Falah Limited",
    name: "Bank Al Falah Limited",
    routingCode: "009920501",
    swiftCode: "ALFHAEAD",
    shortCode: "BAFL"
  },
  {
    _id: "Arab Bank",
    name: "Arab Bank",
    routingCode: "000910101",
    swiftCode: "ARABAEAD",
    shortCode: "ARAB"
  },
  {
    _id: "Arab African International Bank",
    name: "Arab African International Bank",
    routingCode: "900720101",
    swiftCode: "ARAIAEAD",
    shortCode: "ARAI"
  },
  {
    _id: "Al Khaliji",
    name: "Al Khaliji",
    routingCode: "201720101",
    swiftCode: "LICOAEAD",
    shortCode: "LICO"
  },
  {
    _id: "Al Ahli Bank of Kuwait",
    name: "Al Ahli Bank of Kuwait",
    routingCode: "200420101",
    swiftCode: "ABKKAEAD",
    shortCode: "ABKK"
  },
  {
    _id: "Agricultural Bank of China Ltd.",
    name: "Agricultural Bank of China Ltd.",
    routingCode: "709820785",
    swiftCode: "ABOCCNBJ",
    shortCode: "ABC"
  },
  {
    _id: "Bank of America, N.A. (Canada)",
    name: "Bank of America, N.A. (Canada)",
    routingCode: "000910101",
    swiftCode: "ARABAEAD",
    shortCode: "ARAB"
  },
  {
    _id: "Royal Bank of Canada",
    name: "Royal Bank of Canada",
    routingCode: "002220101",
    swiftCode: "CLBIAEAD",
    shortCode: "CLBI"
  },
  {
    _id: "The Bank of Nova Scotia",
    name: "The Bank of Nova Scotia",
    routingCode: "002620132",
    swiftCode: "NOSCCATT",
    shortCode: "BNS"
  },
  {
    _id: "Canadian Imperial Bank of Commerce",
    name: "Canadian Imperial Bank of Commerce",
    routingCode: "009710001",
    swiftCode: "CIBCCATT",
    shortCode: "CIBC"
  },
  {
    _id: "FirstBank",
    name: "FirstBank",
    routingCode: "102710102",
    swiftCode: "FGBMAEAA",
    shortCode: "FGBM"
  },
  {
    _id: "HSBC Bank USA",
    name: "HSBC Bank USA",
    routingCode: "202620103",
    swiftCode: "EBILAEAD",
    shortCode: "EBIL"
  },
  {
    _id: "Regions Bank",
    name: "Regions Bank",
    routingCode: "205010141",
    swiftCode: "UPNBUS44",
    shortCode: "RF"
  },
  {
    _id: "BancFirst",
    name: "BancFirst",
    routingCode: "305012577",
    swiftCode: "BFOKUS44",
    shortCode: "RF"
  },
  {
    _id: "Bank of Oklahoma, N.A.",
    name: "Bank of Oklahoma, N.A.",
    routingCode: "305031064",
    swiftCode: "BAOKUS44",
    shortCode: "BOKF"
  },
  {
    _id: "PNC Bank, N.A.",
    name: "PNC Bank, N.A.",
    routingCode: "502320103",
    swiftCode: "PNCCUS33",
    shortCode: "PNC"
  },
  {
    _id: "TCF National Bank",
    name: "TCF National Bank",
    routingCode: "503870102",
    swiftCode: "TCNTUS44",
    shortCode: "HBAN"
  },
  {
    _id: "First Hawaiian Bank",
    name: "First Hawaiian Bank",
    routingCode: "600930105",
    swiftCode: "FHBKUS77",
    shortCode: "TCF"
  },
  {
    _id: "Bank of America, N.A. (Hawaii)",
    name: "Bank of America, N.A. (Hawaii)",
    routingCode: "602440109",
    swiftCode: "BOFAUS3N",
    shortCode: "BOH"
  },
  {
    _id: "Charles Schwab Bank, SSB",
    name: "Charles Schwab Bank, SSB",
    routingCode: "643140101",
    swiftCode: "CSCHUS6S",
    shortCode: "SCHW"
  },
  {
    _id: "Deutsche Bank Trust Company Americas",
    name: "Deutsche Bank Trust Company Americas",
    routingCode: "704510131",
    swiftCode: "UNBEAEAA",
    shortCode: "UNBE"
  },
  {
    _id: "Commerce Bank",
    name: "Commerce Bank",
    routingCode: "705010403",
    swiftCode: "CBKCUS44",
    shortCode: "CBSH"
  },
  {
    _id: "UMB Bank, N.A.",
    name: "UMB Bank, N.A.",
    routingCode: "705020103",
    swiftCode: "UMKCUS44",
    shortCode: "UMBF"
  },
  {
    _id: "U.S. Bank National Association",
    name: "U.S. Bank National Association",
    routingCode: "705040116",
    swiftCode: "USBKUS44",
    shortCode: "USB"
  },
  {
    _id: "First Financial Bank, N.A.",
    name: "First Financial Bank, N.A.",
    routingCode: "740810000",
    swiftCode: "FITEUS41",
    shortCode: "FFIN"
  },
  {
    _id: "Zions First National Bank",
    name: "Zions First National Bank",
    routingCode: "803720101",
    swiftCode: "ZFNBUS55",
    shortCode: "ZION"
  },
];

export const DOTNET_CARD_STATUS = [
  { id: '1', description: "New Employee" },
  { id: '2', description: "Send to Compliance KP" },
  { id: '3', description: "Compliance KP Passed" },
  { id: '4', description: "Compliance KP Failed" },
  { id: '5', description: "Send To Bank" },
  { id: '6', description: "Compliance Bank Passed" },
  { id: '7', description: "Compliance Bank Failed" },
  { id: '8', description: "Export To CBF - NI" },
  { id: '9', description: "CBF Feedback Passed - NI" },
  { id: '10', description: "CBF Feedback Failed - NI" },
  { id: '11', description: "Export to Processor" },
  { id: '12', description: "Account Received" },
  { id: '13', description: "Account Creation Failed" },
  { id: '14', description: "Card Creation From Persou" },
  { id: '15', description: "File Send To Persou" },
  { id: '16', description: "Card Printed" },
  { id: '17', description: "Card Received From Persou" },
  { id: '18', description: "Card Received in H.O" },
  { id: '19', description: "Assigned to Executive" },
  { id: '20', description: "Person Not Available" },
  { id: '21', description: "Request for Location Change" },
  { id: '22', description: "Card Activation Request Send" },
  { id: '23', description: "Card Delivered" },
  { id: '24', description: "Card Activated" }
]

export const BENFICIARY_TRANSACTION_TYPE = [
  { _id: 'BILL_PAYMENT', name: 'Bill Payment' },
  { _id: 'REMITTANCE', name: 'Remitance' },
  { _id: 'TOPUP', name: 'Top Up' },
]

export const MOBILE_USER_STATUS = {
  OTP_NOT_VERIFIED: 'OTP_NOT_VERIFIED',
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE'
}

export const PREPAID_WPS = [
  {
    _id: 1,
    name: "Prepaid",
  },
  {
    _id: 2,
    name: "WPS",
  },
];

export const BUSINESS_OWNER_TYPE_CODE_OBJ = {
  Ent: 'Entity',
  Ind: 'Individual'
};

export const REASONAL_TOPICS = [
  "CARD_REPLACEMENT_REQUEST",
  "EMPLOYEE_APPLICATION_BLOCK_REQUEST",
  "USER_BLOCK_REQUEST",
  'BULK_INACTIVE_EMPLOYEES',
  'INACTIVE_EMPLOYEES',
];
