import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { currencyExchangeScheme } from "./Validations";
import { CButton, CField } from "../../../uiComponents";
import {numberWithToFix, SEND_MONEY_TYPE, truncateAmount} from "./helper";
import _ from "lodash";
import {getHelloPaisaCurrencyRate} from "../../../store/actions/Remittance.action";
import {ConditionalRendering} from "../../../utils/methods";
import {Alert, Descriptions, Divider, Modal} from "antd";
import SendMoneyOverview from "./Overview";

const CurrencyExchange = forwardRef(({ client, employee }, ref) => {

    const dispatch = useDispatch();
    const form = useRef(null);

    const reduxState = useSelector(({ findCardHolder, remittance, common }) => {
        return {
            loading: common?.sendOtpMobileApiLoading || remittance.helloPaisaGetCurrencyLoading || remittance?.processPaymentLoading,
            getCurrencyLoading: remittance.helloPaisaGetCurrencyLoading,
            currencyInfo: remittance.helloPaisaGetCurrency,
            currentCountry: common?.currentCountry,
            maskLoading: common?.sendOtpMobileApiLoading || remittance?.processPaymentLoading
        };
    });

    const { loading, currencyInfo, getCurrencyLoading, currentCountry, maskLoading } = reduxState;

    const [isOpen, updateIsOpen] = useState(false);
    const [selectedBeneficiary, updateSelectedBeneficiary] = useState(null);
    const [exchangeRate, updateExchangeRate] = useState('');
    const [isOverview, updateIsOverview] = useState(null);
    const [currentTitle, updateCurrentTitle] = useState(0);

    const receiverCountry = selectedBeneficiary?.bank?.countryDetails;

    useImperativeHandle(ref, () => ({
        toggleModal(val, obj) {
            updateIsOpen(val);
            updateSelectedBeneficiary(obj)
        }
    }));

    const modalClose = (value = false) => {
        updateIsOpen(value);
        updateSelectedBeneficiary(null);
        updateIsOverview(null)
    };

    useEffect(() => {
        if(selectedBeneficiary?.bank?._id){
            get(1, SEND_MONEY_TYPE.RECEIVER._id)
        }
    }, [selectedBeneficiary]);

    useEffect(() => {

        if(currencyInfo && currencyInfo?.singleAmountUnit){
            updateExchangeRate(currencyInfo?.singleAmountUnit);
        }

        if(currencyInfo && form?.current?.values?.sender && Number(currencyInfo?.amountInForeignCurrencyReceivable)) {
            form?.current?.setFieldValue('receiver', numberWithToFix(currencyInfo?.amountInForeignCurrencyReceivable));
        }

        if(currencyInfo && form?.current?.values?.receiver && Number(currencyInfo?.amountInAED)) {
            form?.current?.setFieldValue('sender', numberWithToFix(currencyInfo?.amountInAED));
        }

    }, [currencyInfo]);

    const get = (amount, type, promo) => {
        let payload = {
            bankId: selectedBeneficiary?.bank?._id,
            amount: amount,
            type: type,
            isOfferEligible: true,
            client: client?._id,
            employee: employee?._id,
            ...(promo && {promoCode: promo}),
        };
        dispatch(getHelloPaisaCurrencyRate(payload));
    };

    const searchDebounce = useCallback(_.debounce((e, type, promo) => {
        try {
            if(selectedBeneficiary?.bank?._id){
                get(e, type, promo);
            }
        } catch (error) {

        }
    }, 1500), [selectedBeneficiary]);

    const applyPromo = (item) => {
        get(item?.receiver, SEND_MONEY_TYPE.RECEIVER._id, item?.promo)
    };
    const removePromo = (values) => {
        form?.current?.setFieldValue('promo', '');
        let updatedValues = _.omit(values, ['promo']);
        applyPromo(updatedValues)
    };

    const onChangeSender = (val, promo = '') => {
        form?.current?.setFieldValue('sender', numberWithToFix(val));
        if(val > 0) searchDebounce(val.toString(), SEND_MONEY_TYPE.SENDER._id, promo || form?.current?.values.promo);
        if(!val) { form?.current?.setFieldValue('receiver', ''); }
    };
    const onChangeReceiver = (val) => {
        form?.current?.setFieldValue('receiver', numberWithToFix(val));
        if(val > 0) searchDebounce(val.toString(), SEND_MONEY_TYPE.RECEIVER._id, form?.current?.values.promo);
        if(!val) { form?.current?.setFieldValue('sender', ''); }
    };

    const checkDisable = (values) => {
        let c = false;
        if(!values?.sender || values?.sender < 1){
            c = true;
        }
        if(!values?.receiver || values?.receiver < 1){
            c = true;
        }
        if(!values?.promo) {
            c = true
        }
        return c
    };

    const onFinish = (values) => {
        let payload = {
            formValues: values,
            Amount: currencyInfo?.amountInAED,
            ...selectedBeneficiary,
            client: client?._id,
            employee: employee?._id,
            currencyInfo: currencyInfo,
            ...(!currencyInfo?.promoError && values?.promo && {Promocode: values?.promo}),
        };
        updateCurrentTitle(1);
        updateIsOverview(payload)
    };

    const goBack = () => {
        updateCurrentTitle(0);
        let values = isOverview?.formValues;
        updateIsOverview(null);
        setTimeout(() => {
            if(values?.promo){
                form?.current?.setFieldValue('promo', values?.promo);
            }
            if(values?.sender){
                onChangeSender(values?.sender, values?.promo)
            }
        })
    };

    const titles = ["Currency Exchange", "Confirmation", ""];

    return (
        <Modal
            width={500}
            title={titles[currentTitle]}
            centered
            open={isOpen}
            maskClosable={false}
            keyboard={false}
            closable={!loading}
            onCancel={() => modalClose()}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose
        >
            {isOverview && Object.keys(isOverview).length ? <SendMoneyOverview data={isOverview}
                                                                               allClose={() => modalClose()}
                                                                               updateCurrentTitle={updateCurrentTitle}
                                                                               selectedBeneficiary={selectedBeneficiary}
                                                                               goBack={() => goBack()} /> : <Formik
                innerRef={form}
                initialValues={{
                    sender: '',
                    receiver: '',
                }}
                validationSchema={currencyExchangeScheme}
                onSubmit={onFinish}
            >
                {({ errors, touched, handleSubmit, values, setFieldTouched, submitCount, handleChange }) => (

                    <Form className="inner-form col flex0">

                        <CField
                            containerClass='margin-right_zero'
                            disabled={loading}
                            name="sender"
                            placeholder="Sender"
                            prefix={<div className="currency-input-prefix-container">
                                <img width={24} src={currentCountry?.flag} alt=""/>
                                <span>{currentCountry?.currency}</span>
                            </div>}
                            type="number"
                            value={values?.sender}
                            onChange={(e) => onChangeSender(e.target.value)}
                            onBlur={() => setFieldTouched("sender", true, true)}
                            error={submitCount ? errors?.sender : touched?.sender && errors.sender}
                        />

                        <CField
                            containerClass='margin-right_zero'
                            disabled={loading || true}
                            name="receiver"
                            placeholder="Receiver"
                            prefix={<div className="currency-input-prefix-container">
                                <img width={24} src={receiverCountry?.FlagPng} alt=""/>
                                <span>{receiverCountry?.Currency}</span>
                            </div>}
                            type="number"
                            value={values?.receiver}
                            onChange={(e) => onChangeReceiver(e.target.value)}
                            onBlur={() => setFieldTouched("receiver", true, true)}
                            error={submitCount ? errors?.receiver : touched?.receiver && errors.receiver}
                        />

                        <Divider variant="dashed" className="ce-divider" style={{fontSize: 14}}>
                            <div className="rateTitleContainer ">
                                <span className="">Exchange Rate</span>
                                <span className="primary-color"> <b>1 {currentCountry?.currency}</b> </span>
                                <span className="primary-color rateTitle"> = </span>
                                <span className="primary-color rateTitle">
                                <b className="rateTitle">{receiverCountry?.Currency} {truncateAmount(exchangeRate * 1) || 0}</b>
                            </span>
                            </div>
                        </Divider>


                        <ConditionalRendering
                            condition={currencyInfo?.offerDetails?.title}
                            children={<>
                                <Divider variant="dashed" className="ce-divider" orientation="left" style={{fontSize: 14}}>Offer</Divider>
                                <Alert banner message={currencyInfo?.offerDetails?.title} type="success" className="margin-top_10 alert-box word-break" />
                            </>}
                        />


                        <Divider variant="dashed" className="ce-divider" orientation="left" style={{fontSize: 14}}>Do you have a Discount Code?</Divider>
                        <div className="d-flex w-100" >
                            <CField
                                disabled={getCurrencyLoading && currencyInfo?.promoDetails?.promo}
                                containerClass='w-100'
                                name="promo"
                                isLabel={false}
                                placeholder="Enter Promo Code"
                                type="text"
                                error={currencyInfo?.promoError}
                                value={values.promo}
                                onBlur={() => {
                                    !checkDisable(values) && (currencyInfo?.promoDetails?.promo ? removePromo(values) : applyPromo(values))
                                }}
                                onChange={(e) => handleChange('promo')(e.target.value)}
                            />
                            <CButton
                                buttonLayout='primary'
                                containerClass='flex0 margin-right_zero'
                                title={loading ? '' : currencyInfo?.promoDetails?.promo ? 'Remove' : 'Apply'}
                                loading={loading}
                                disabled={checkDisable(values)}
                                id='apply'
                                onClick={() => currencyInfo?.promoDetails?.promo ? removePromo(values) : applyPromo(values)}
                            />
                        </div>

                        <Divider variant="dashed" className="ce-divider" orientation="left" style={{fontSize: 14}}>Transfer Amount & Charges</Divider>
                        <Descriptions title="" layout="horizontal" size={'small'} bordered column={1} >
                            <Descriptions.Item label={`Transfer Amount`}>
                                {receiverCountry?.Currency} {values.sender && values.receiver && currencyInfo?.amountInForeignCurrency || 0}
                            </Descriptions.Item>
                            <Descriptions.Item label={`Charges`}>
                                {currentCountry?.currency} {values.sender && values.receiver && currencyInfo?.totalFee || 0}
                            </Descriptions.Item>
                            <Descriptions.Item label={`VAT`}>
                                {currentCountry?.currency} {values.sender && values.receiver && currencyInfo?.totalVat || 0}
                            </Descriptions.Item>
                            {values.sender && values.receiver && currencyInfo?.mode ? <Descriptions.Item label={`${currencyInfo?.mode === 'DISCOUNT' ? 'Discount' : 'Cashback'}`}>
                                {currentCountry?.currency} {`${currencyInfo?.mode === 'DISCOUNT' ? '-' : ''}` + currencyInfo?.discountAmount || 0}
                            </Descriptions.Item> : null}
                            {values.sender && values.receiver && currencyInfo?.tat ? <Descriptions.Item label={`Turn Around Time`}>
                                {currencyInfo?.tat}
                            </Descriptions.Item> : null}
                            <Descriptions.Item label={`Total Amount`}>
                                {currentCountry?.currency} {values.sender && values.receiver && currencyInfo?.totalAmount || 0}
                            </Descriptions.Item>
                        </Descriptions>
                        <div className="margin-top_20" />

                        <CButton
                            containerClass='margin-right_zero margin-bottom_zero'
                            type="submit"
                            htmlType="submit"
                            onClick={handleSubmit}
                            title={`Next`}
                            buttonLayout="primary"
                            loading={loading}
                            disabled={loading || !values?.sender || !values?.receiver}
                        />

                    </Form>
                )}
            </Formik>}

        </Modal>
    );
});

export default React.memo(CurrencyExchange);
