import React, {useEffect, useState, memo, useRef} from "react";
import { useLocation, useNavigate } from "react-router";
import { PageWrapper } from "../../../containers";
import { CButton } from "../../../uiComponents";
import {
  _readableTextCap,
  ConditionalRendering,
  getQueryFiltersAsObject,
  pushFiltersToQueryString,
  readableText,
  getCountryObj
} from "../../../utils/methods";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm, Tag, Table } from "antd";
import { deleteBeneficiary, getBeneficiaries } from "../../../store/actions/FindCardHolder.action";
import Add from "./Add";
import UpdateForm from "./UpdateForm";
import CurrencyExchange from "./CurrencyExchange";
import {ACL_ACCESS_LIST, ACL_MODULES, IsAllowedOption} from "../../../utils/acl";
import BeneficiaryNumberUpdate from "./BeneficiaryNumberUpdate";
import { showNumberInBank } from "./helper";

function SendMoney() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const employee = location.state?.employee;
  const client = location.state?.client;
  const mobileUser = location.state?.mobileUser;

  const canAddBeneficiary = IsAllowedOption(ACL_MODULES.SEND_MONEY, ACL_ACCESS_LIST.ADD_BENEFICIARY);
  const canEditBeneficiary = IsAllowedOption(ACL_MODULES.SEND_MONEY, ACL_ACCESS_LIST.EDIT_BENEFICIARY);
  const canDeleteBeneficiary = IsAllowedOption(ACL_MODULES.SEND_MONEY, ACL_ACCESS_LIST.DELETE_BENEFICIARY);
  const canSendMoney = IsAllowedOption(ACL_MODULES.SEND_MONEY, ACL_ACCESS_LIST.BENEFICIARY_SEND_MONEY);
  const canTrackHistory = IsAllowedOption(ACL_MODULES.SEND_MONEY, ACL_ACCESS_LIST.SEND_MONEY_TRACK_HISTORY);

  const sendMoneyRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isInitial, setIsInitial] = useState(true);
  const [limit] = useState(10);
  const [allFilter, setAllFilter] = useState({});
  const [beneficiaryNumberUpdate, updateBeneficiaryNumberUpdate] = useState(null);

  const reduxState = useSelector(({ findCardHolder, auth }) => {
    return {
      user: auth.user,
      data: findCardHolder.beneficiaries,
      metaData: findCardHolder.beneficiariesMetaData,
      loading: findCardHolder.getBeneficiariesLoading,
      deleteLoading: findCardHolder.deleteBeneficiaryLoading,
    };
  });

  const { data, metaData, loading, deleteLoading } = reduxState;

  useEffect(() => {
    let filters = getQueryFiltersAsObject(location, true);
    if (Object.keys(filters)?.length) {
      setAllFilter(filters);
    } else {
      pushFilter();
    }
    setIsInitial(false);
  }, []);

  useEffect(() => {
    if (!isInitial) {
      pushFilter();
    }
  }, [allFilter]);

  const get = () => {
    let payload = {
      ...allFilter,
      page: allFilter?.page || 1,
      limit: limit,
      client: client?._id,
      employee: employee?._id,
      transactionTypes: ['REMITTANCE']
    };
    dispatch(getBeneficiaries(payload));
  };

  // push filters on queryString
  const pushFilter = async () => {
    let payload = {
      ...allFilter,
      page: allFilter?.page || 1,
    };
    await pushFiltersToQueryString(payload, navigate, location);
    get();
  };

  const toggleUpdateModal = (value = false, obj = {}) => {
    setIsOpenUpdate(value);
    setSelected(obj);
  };

  const sendMoneyModal = (value = false, obj = {}) => {
    if(obj){
      let payload = {};
      let inputsMod = {};
      if(obj?.usersInputs) {
        obj?.usersInputs.forEach(o => {
          inputsMod = {
            ...inputsMod,
            [o.id]: o.value
          }
        });
        payload.country = obj?.bank?.countryDetails;
        payload.bank = obj?.bank;
        payload.otherDetails = inputsMod;
        payload.pageType = 'BENEFICIARY_TO_SEND_MONEY';
        payload.beneficiaryAlias = obj?.beneficiaryAlias;
        payload.beneficiaryId = obj?._id;
        payload.transactionType = obj?.transactionType;
            if(checkNumberIsDummy(payload)) {
                updateBeneficiaryNumberUpdate(payload)
            } else {
              sendMoneyRef?.current.toggleModal(true, payload)
            }
      }
    }
  };

  const headerProps = {
    renderRight: () => renderRight(),
    title: "Send Money",
    subTitle: `${employee?.mobileUser?.fullName || mobileUser?.fullName || ''}`,
    canGoBack: true,
  };

  const renderRight = () => {
    return (
        <div className="btn-group">
          <ConditionalRendering
              condition={canTrackHistory}
              children={<CButton
                  containerClass="margin-top_zero flex0"
                  buttonLayout="primary-outline"
                  title="Track History"
                  onClick={() => navigate('/send-money/track-history', { state: { employee, client, mobileUser} })}
              />}
          />
          <ConditionalRendering
              condition={canAddBeneficiary}
              children={<CButton
                  buttonLayout="primary-outline"
                  disabled={loading || deleteLoading}
                  title="Add Beneficiary"
                  onClick={() => setIsOpen(true)}
              />}
          />
        </div>
    );
  };

  const checkNumberIsDummy = (item) => {
    let check = showNumberInBank(item?.bank?.BankType, item?.bank?.countryDetails);
    if(check) {
        let findUserNumber = item?.otherDetails?.BeneficiaryMSISDN;
        let country = getCountryObj(item?.bank?.countryDetails?.CCA2);
        let dummuyNumberPerifix = country?.code;
        let dummuyNumber = `${dummuyNumberPerifix.replace(/[^\w\s]/gi, '')}00000000000`;
        return !findUserNumber || findUserNumber === dummuyNumber
    } else {
        return false
    }
};

  const COLUMNS = [
    {
      title: "Alias",
      dataIndex: "beneficiaryAlias",
      width: 80,
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      render: (val) => <div className="bold-text ">{_readableTextCap(val)}</div>,
      width: 80,
      // ...renderCheckboxFilter('transactionTypes', allFilter, setAllFilter, BENFICIARY_TRANSACTION_TYPE, '_id', 'name'),
    },
    {
      title: "vendor",
      dataIndex: "vendor",
      width: 80,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        return (
            <Tag color={status === "ACTIVE" ? 'green' : 'red'}>
              {readableText(status)}
            </Tag>
        )
      },
      width: 60,
    },
    ...((canSendMoney || canEditBeneficiary || canDeleteBeneficiary) ? [{
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (val, obj) => (
          <div className="inner-data no-wrap">
            <ConditionalRendering
                condition={canSendMoney}
                children={<CButton
                    buttonLayout="secondary-outline small"
                    title="Send Money"
                    disabled={false}
                    onClick={() => sendMoneyModal(true, obj)}
                />}
            />
            <ConditionalRendering
                condition={canEditBeneficiary}
                children={
                  <CButton
                      buttonLayout="green-outline small"
                      title="Edit"
                      disabled={deleteLoading}
                      onClick={() => toggleUpdateModal(true, obj)}
                  />
                }/>

            <ConditionalRendering
                condition={canDeleteBeneficiary}
                children={
                  <Popconfirm
                      title="Are you sure, do you want to delete?"
                      onConfirm={() => dispatch(deleteBeneficiary({id: obj?._id}, pushFilter))}
                      onCancel={() => console.log("cancel")}
                      okText="Yes"
                      cancelText="No"
                  >
                    <CButton
                        containerClass="flex0"
                        buttonLayout="red-outline small"
                        title="Delete"
                        disabled={deleteLoading}
                    />
                  </Popconfirm>
                }/>
          </div>
      ),
      width: 160,
    }] : []),
  ];


  const numberUpdateOnSubmit = (values) => {
    let payload = {...beneficiaryNumberUpdate};
    payload.otherDetails.BeneficiaryMSISDN = values.BeneficiaryMSISDN;
    sendMoneyRef?.current.toggleModal(true, payload)
    updateBeneficiaryNumberUpdate(null)
};

  return (
      <PageWrapper headerProps={headerProps}>
        <Table
            className={`c-table layout-auto ${data?.length ? "" : "no-data-table"}`}
            rowKey={(obj) => obj?._id}
            scroll={{ x: "100%" }}
            loading={loading}
            columns={COLUMNS}
            dataSource={data}
            pagination={{
              total: metaData?.totalDocuments,
              pageSize: limit,
              showSizeChanger: false,
              current: metaData?.page,
              defaultCurrent: allFilter?.page,
              onChange: (page) => setAllFilter({ ...allFilter, page }),
            }}
        />

        <CurrencyExchange ref={sendMoneyRef} get={get} client={client} employee={employee}/>
        <Add isOpen={isOpen} setIsOpen={setIsOpen} get={get} client={client} employee={employee} />
        <UpdateForm isOpen={isOpenUpdate} setIsOpen={setIsOpenUpdate} selected={selected} get={get} />
        <BeneficiaryNumberUpdate 
           isOpen={!!(beneficiaryNumberUpdate)}
           country={beneficiaryNumberUpdate?.country}
           submit={(values) => numberUpdateOnSubmit(values)}
           onClose={() => updateBeneficiaryNumberUpdate(null)}
        />
      </PageWrapper>
  );
}

export default memo(SendMoney);
