import React, { useEffect, useState } from "react";
import { CButton, CField } from "../../../uiComponents";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getCountryObj, phoneValidateNumberRegex } from "../../../utils/methods";
import { Modal } from "antd";

const BeneficiaryNumberUpdate = (props) => {

    const { submit, loading, onClose, isOpen, country } = props;

    const [selectedCountry, setSelectedCountry] = useState(null);

    useEffect(() => {
        let foundCountry = getCountryObj(country?.CCA2);
        setSelectedCountry(foundCountry)
    }, [country]);

    const onSubmit = (values) => {
        let perifix = selectedCountry?.code;
        values.BeneficiaryMSISDN = `${perifix.replace(/[^\w\s]/gi, '')}${values.BeneficiaryMSISDN.replace(/\s+/g, '')}`;
        submit && submit(values);
        onClose && onClose()
    };

    return (
        <Modal
            title={'Update'}
            width={450}
            centered
            open={isOpen}
            maskClosable={false}
            closable={!loading}
            onCancel={() => onClose()}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose
            keyboard={false}
        >
            <Formik
                onSubmit={(values) => onSubmit(values)}
                initialValues={{}}
                validationSchema={Yup.object().shape({
                    BeneficiaryMSISDN: Yup.string().label('Phone Number')
                        .test('checkPhoneNumber', (value, obj) => phoneValidateNumberRegex(selectedCountry, value || '', obj))
                        .required("Phone number is required")
                })}>
                {({ errors, touched, handleSubmit, setFieldValue, values, setFieldTouched, submitCount, handleChange }) => {
                    return (
                        <Form className="inner-form col flex0">

                            <CField
                                name="BeneficiaryMSISDN"
                                placeholder="Phone Number"
                                type="text"
                                prefix={<span>{selectedCountry?.code}</span>}
                                containerClass='w-100 flex-auto margin-right_zero'
                                error={submitCount ? errors?.BeneficiaryMSISDN : touched?.BeneficiaryMSISDN && errors.BeneficiaryMSISDN}
                                onBlur={() => setFieldTouched("BeneficiaryMSISDN", true, true)}
                                value={values?.BeneficiaryMSISDN}
                                onChange={(e) => {
                                    let phone = e.target.value;
                                    let reg = /^0+/gi;
                                    if (phone.match(reg)) {
                                        phone = phone.replace(reg, '');
                                    }
                                    setFieldValue("BeneficiaryMSISDN", phone)
                                }}
                            />

                            <CButton
                                containerClass='margin-right_zero'
                                type="submit"
                                htmlType="submit"
                                onClick={() => handleSubmit()}
                                title={`Update`}
                                buttonLayout="primary"
                                loading={false}
                                disabled={false}
                            />

                        </Form>
                    )
                }}
            </Formik>

        </Modal>
    )
}

export default React.memo(BeneficiaryNumberUpdate)
