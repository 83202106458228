import React, {useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { CButton, CResult } from "../../../uiComponents";
import {Descriptions} from "antd";
import {generateReceiverDetail, generateSendToInfo, generateTransferAmountAndCharges, OTP_TOPIC, STATUS} from "./helper";
import OTPByRef from "./OTPByRef";
import {sendOtpMobileApi} from "../../../store/actions/Common.action";
import {processPayment} from "../../../store/actions/Remittance.action";
import Receipt from "./receipt/Receipt";

const SendMoneyOverview = ({ data, goBack, selectedBeneficiary, updateCurrentTitle, allClose }) => {

    const dispatch = useDispatch();
    const otpRef = useRef(null);
    const resultRef = useRef(null);
    const receiptRef = useRef(null);

    const reduxState = useSelector(({ common, remittance }) => {
        return {
            loading: common?.sendOtpMobileApiLoading || remittance?.processPaymentLoading,
        };
    });

    const { loading } = reduxState;

    const [payload, setPayload] = useState({});
    const [ticketPayload, updateTicketPayload] = useState(null);

    const renderReceiverDetail = (obj) => {
        let data = generateReceiverDetail(obj);
        return (
            <Descriptions title="Receiver Details" className={"margin-top_20"} layout="horizontal" size={'small'} bordered column={1} >
                {data?.map((obj, i) => {
                    return <Descriptions.Item key={i} label={obj?.Name}>{obj?.value}</Descriptions.Item>
                })}
            </Descriptions>
        )
    };

    const renderTransferAmountAndCharges = (obj) => {
        let data = generateTransferAmountAndCharges(obj);
        return (
            <Descriptions title="Transfer Amount & Charges" className={"margin-top_20"} layout="horizontal" size={'small'} bordered column={1} >
                {data?.map((obj, i) => {
                    return <Descriptions.Item key={i} label={obj?.Name}>{obj?.value}</Descriptions.Item>
                })}
            </Descriptions>
        )
    };

    const handleSubmit = (obj) => {
        let payload = {
            Amount: obj?.Amount.toString(),
            client: obj?.client,
            employeeId: obj?.employee,
            BankId: selectedBeneficiary.bank?._id,
            ...((!selectedBeneficiary?.beneficiaryId && selectedBeneficiary?.otherDetails?.AddBeneficiary) && {
                AddBeneficiary: selectedBeneficiary?.otherDetails?.AddBeneficiary,
                Alias: selectedBeneficiary?.otherDetails?.Alias
            }),
            ...((selectedBeneficiary?.beneficiaryId) ? {
                beneficiaryId: selectedBeneficiary?.beneficiaryId
            }: {
                BeneficiaryMSISDN: selectedBeneficiary?.otherDetails?.BeneficiaryMSISDN,
                BeneficiaryFirstName: selectedBeneficiary?.otherDetails?.BeneficiaryFirstName,
                BeneficiaryLastName: selectedBeneficiary?.otherDetails?.BeneficiaryLastName,
                BeneficiaryNationalityCountryISOCode: selectedBeneficiary?.otherDetails?.BeneficiaryNationalityCountryISOCode,
                RemitPurpose: selectedBeneficiary?.otherDetails?.RemitPurpose,
                ...((selectedBeneficiary?.bank?.BankTypeName === 'Bank Account') && {
                    AccountTitle: selectedBeneficiary?.otherDetails?.AccountTitle,
                    BeneficiaryAccountNo: selectedBeneficiary?.otherDetails?.BeneficiaryAccountNo
                })
            }),
            ...((obj?.Promocode) && {
                Promocode: obj?.Promocode
            }),

        };

        setPayload(payload);

        dispatch(sendOtpMobileApi({
            client: obj?.client,
            employeeId: obj?.employee,
            topic: OTP_TOPIC.TRANSACTION_PURPOSE
        }, sendOtpMobileApiCallback));

    };

    const sendOtpMobileApiCallback = (res) => {
        otpRef?.current?.toggleModal(true, res);
    };

    const callBackOTPVerification = (res) => {
        otpRef?.current?.toggleModal(false, null);
        dispatch(processPayment(payload, processPaymentCallBack))
    };

    const processPaymentCallBack = (res) => {
        updateCurrentTitle(2);
        if(res?.error || res?.data?.error){
            resultRef?.current?.toggleModal(
                true,
                res?.data?.message || res?.data?.data?.message || "Something went wrong",
                "Your transaction could not be processed at the moment. Please check and try again"
            );
        } else {
            let s = res?.data?.status || 'IN_PROGRESS';
            updateTicketPayload({
                ...res?.data,
                amountInAED: res?.data?.totalAmount || data?.currencyInfo?.totalAmount,
                ...payload,
                Currency: 'AED',
                ...(s && {
                    title: STATUS[s].title,
                    message: STATUS[s].message,
                    icon: STATUS[s]
                }),
                ...((res?.referenceNo) && {
                    referenceNo: res?.referenceNo
                }),
                //     ...(isTrackHistory && {
                //         trackHistory: () => {
                //             updateTicketPayload({});
                //             navigation.popToTop();
                //             navigation.navigate('send_money_history');
                //         }
                //     })
            });
            receiptRef?.current?.toggleModal(true)
        }
    };

    return (
        <div className="inner-form col flex0">

            {renderReceiverDetail(data)}

            {renderTransferAmountAndCharges(data)}

            <Descriptions title="" className={"margin-top_20"} layout="horizontal" size={'small'} bordered column={1} >
                <Descriptions.Item label={"Total Amount"}>{data?.currencyInfo?.totalAmount} {"AED"}</Descriptions.Item>
            </Descriptions>

           <div className="d-flex margin-top_20">
               <CButton
                   type="button"
                   htmlType="button"
                   onClick={goBack}
                   title={`Go Back`}
                   buttonLayout="primary-outline"
                   disabled={loading}
               />

               <CButton
                   containerClass='margin-left_15 margin-right_zero'
                   type="submit"
                   htmlType="submit"
                   onClick={() => handleSubmit(data)}
                   title={`Send`}
                   buttonLayout="primary"
                   loading={loading}
                   disabled={loading}
               />
           </div>

            <OTPByRef
                ref={otpRef}
                clientId={data?.client}
                employeeId={data?.employee}
                callBack={callBackOTPVerification}
            />

            <CResult ref={resultRef} type={'error'} onCancel={allClose}/>

            <Receipt
                ref={receiptRef}
                data={ticketPayload}
                onClose={allClose}
                refId={ticketPayload?.payoutPartnerRef || ''}
                infoTitle={"Transaction Details"}
                infoUpperTitle=""
                senTo={generateSendToInfo(data, ticketPayload)}
            />
        </div>

    );
};

export default React.memo(SendMoneyOverview);
