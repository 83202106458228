import { useSelector } from "react-redux";
import { TOPIC } from "../constants";

export const ACL_MODULES = {

    DASHBOARD: 'DASHBOARD',

    // MANAGE_ADMINS: 'MANAGE_ADMINS',

    ALL_REQUESTS: 'ALL_REQUESTS',

    CLIENT_APPROVALS: 'CLIENT_APPROVALS',

    EXCHANGE_HOUSE_APPROVALS: 'EXCHANGE_HOUSE_APPROVALS',

    ON_BOARD_APPROVALS: 'ON_BOARD_APPROVALS',

    EMPLOYERS: 'EMPLOYERS',
    EMPLOYEES: 'EMPLOYEES',
    EMPLOYEE: 'EMPLOYEE',

    EXCHANGE_HOUSES: 'EXCHANGE_HOUSES',
    EXCHANGE_HOUSE_BRANCHES: 'EXCHANGE_HOUSE_BRANCHES',

    FIND_CARD_HOLDER: 'FIND_CARD_HOLDER',

    CARD_ACTIVATION_AND_PIN_CHANGE: 'CARD_ACTIVATION_AND_PIN_CHANGE',

    ADVANCE_SALARY: 'ADVANCE_SALARY',

    SALARY_STATUS: 'SALARY_STATUS',

    TRANSACTIONS: 'TRANSACTIONS',

    REMITTANCES: 'REMITTANCES',

    CENTIV_CARD: 'CENTIV_CARD',
    CENTIV_CARD_EMPLOYEE: 'CENTIV_CARD_EMPLOYEE',

    REPLACEMENT_CARDS: 'REPLACEMENT_CARDS',

    LOGS: 'LOGS',

    PROCESS_SALARIES: 'PROCESS_SALARIES',

    MARKETPLACE: 'MARKETPLACE',
    MARKETPLACE_PRODUCTS: 'PRODUCTS',
    MARKETPLACE_PRODUCT_CATEGORIES: 'PRODUCT_CATEGORIES',
    MARKETPLACE_PRODUCT_SUBCATEGORIES: 'PRODUCT_SUBCATEGORIES',
    MARKETPLACE_PRODUCT_SUPPLIERS: 'PRODUCT_SUPPLIERS',
    MARKETPLACE_PRODUCT_INVENTORIES: 'PRODUCT_INVENTORIES',
    MARKETPLACE_PRODUCT_ORDERS: 'PRODUCT_ORDERS',
    EMPLOYER_TEAM_MEMBERS: 'EMPLOYER_TEAM_MEMBERS',
    MANAGE_ACCESS_TEAM_MEMBER: 'MANAGE_ACCESS_TEAM_MEMBER',
    TRANSACTION_HISTORY: 'TRANSACTION_HISTORY',
    SCRATCHED_CARD: 'SCRATCHED_CARD',
    LOGIN_HISTORY: 'LOGIN_HISTORY',
    PROMOS: 'PROMOS',
    OFFERS: 'OFFERS',
    TRANSACTION_SCREENING: 'TRANSACTION_SCREENING',
    BENEFICIARY_SCREENING: 'BENEFICIARY_SCREENING',
    DELIVERY_MODULE: 'DELIVERY_MODULE',
    MOBILE_APP_APPROVAL: 'MOBILE_APP_APPROVAL',
    CAMPAIGNS: 'CAMPAIGNS',
    CAMPAIGN_VARIABLES: 'CAMPAIGN_VARIABLES',
    CAMPAIGN_CATEGORIES: 'CAMPAIGN_CATEGORIES',
    CAMPAIGN_TEMPLATES: 'CAMPAIGN_TEMPLATES',
    CAMPAIGN_NOTIFICATION_CONFIG: 'CAMPAIGN_NOTIFICATION_CONFIG',
    HELLO_PAISA_FEE: 'HELLO_PAISA_FEE',
    MOBILE_APP_LOGS: 'MOBILE_APP_LOGS',
    REMITTANCE_RATE: 'REMITTANCE_RATE',
    CAMPS: 'CAMPS',
    SEND_MONEY: 'SEND_MONEY',
    DISPUTE_FORM: 'DISPUTE_FORM',
};

export const ACL_SUB_MODULES = {};

export const ACL_ACCESS_LIST = {
    VIEW: 'VIEW',
    ADD: 'ADD',
    ADD_WITH_EXCEL: 'ADD_WITH_EXCEL',
    EDIT: 'EDIT',
    UPDATE: 'UPDATE',

    IMAGE_UPLOAD: 'IMAGE_UPLOAD',

    ACTIONS: 'ACTIONS',

    CREATE: 'CREATE',

    MAKER: 'MAKER',
    CHECKER: 'CHECKER',

    ALL_REQUEST_CHECKER: 'ALL_REQUEST_CHECKER',
    REQUEST_CHECKER: 'REQUEST_CHECKER',

    REQUEST_AUTHORIZER: 'REQUEST_AUTHORIZER',
    ALL_REQUEST_AUTHORIZER: 'ALL_REQUEST_AUTHORIZER',


    REPLACE_CARD: 'REPLACE_CARD',
    STATEMENT_REQUEST: 'STATEMENT_REQUEST',
    ADD_UPDATE_EID: 'ADD_UPDATE_EID',
    EDIT_PERSONAL_DETAIL: 'EDIT_PERSONAL_DETAIL',
    CHECK_CARD_STATUS: 'CHECK_CARD_STATUS',
    LOAD_FUND: 'LOAD_FUND',
    CARD_ACTIONS: 'CARD_ACTIONS',

    VIEW_DETAIL: 'VIEW_DETAIL',
    UPLOAD_SALARY: 'UPLOAD_SALARY',
    UPLOAD_TRANSFER_RECEIPT: 'UPLOAD_TRANSFER_RECEIPT',
    CAN_DOWNLOAD: 'CAN_DOWNLOAD',
    CHANGE_PIN: 'CHANGE_PIN',
    CARD_ACTIVATION: 'CARD_ACTIVATION',
    CENTIV_DETAIL_VIEW: 'CENTIV_DETAIL_VIEW',
    EMPLOYEE_DETAIL_VIEW: 'EMPLOYEE_DETAIL_VIEW',
    ADD_OTHER_BANK_DETAILS: 'ADD_OTHER_BANK_DETAILS',
    EDIT_OTHER_BANK_DETAILS: 'EDIT_OTHER_BANK_DETAILS',
    ADD_KAMELPAY_DETAILS: 'ADD_KAMELPAY_DETAILS',
    CHECK_SUBSCRIPTION_STATUS: 'CHECK_SUBSCRIPTION_STATUS',
    GET_BALANCE: 'GET_BALANCE',
    GET_TRANSACTIONS: 'GET_TRANSACTIONS',
    UPLOAD_KYC: 'UPLOAD_KYC',
    APPLY_ADVANCE_SALARY: 'APPLY_ADVANCE_SALARY',
    ADVANCE_SALARY: 'ADVANCE_SALARY',
    UPDATE_MOBILE_USER_PASSWORD: 'UPDATE_MOBILE_USER_PASSWORD',
    UPDATE_MOBILE_USER_PHONE: 'UPDATE_MOBILE_USER_PHONE',
    CREATE_MOBILE_APP_USER: 'CREATE_MOBILE_APP_USER',
    EMPLOYEE_DEACTIVATE: 'EMPLOYEE_DEACTIVATE',
    EMPLOYEE_ACTIVATE: 'EMPLOYEE_ACTIVATE',
    SHOW_TRANSACTIONS: 'SHOW_TRANSACTIONS',
    BLOCK_AND_UNBLOCK_CARD: 'BLOCK_AND_UNBLOCK_CARD',
    SYNC_EXCHANGE_HOUSES: 'SYNC_EXCHANGE_HOUSES',
    ACCESS_CASHOUT: 'ACCESS_CASHOUT',
    EDIT_THEME: 'EDIT_THEME',

    SYNC_EMPLOYERS: 'SYNC_EMPLOYERS',
    SYNC_EMPLOYEES: 'SYNC_EMPLOYEES',
    ADD_EMPLOYER: 'ADD_EMPLOYER',
    UPLOAD_EOS: 'UPLOAD_EOS',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    EMPLOYER_ADD_ESTABLISHMENT: 'EMPLOYER_ADD_ESTABLISHMENT',
    DEACTIVATE_EMPLOYEES: 'DEACTIVATE_EMPLOYEES',
    ACTIVATE_EMPLOYEES: 'ACTIVATE_EMPLOYEES',
    DEACTIVATE_EMPLOYEE: 'DEACTIVATE_EMPLOYEE',
    ACTIVATE_EMPLOYEE: 'ACTIVATE_EMPLOYEE',
    BLOCK_APPLICATION: 'BLOCK_APPLICATION',
    ACTIVATE_APPLICATION: 'ACTIVATE_APPLICATION',
    DELETE_DUPLICATES: 'DELETE_DUPLICATES',

    VIEW_BENEFICIARIES: 'VIEW_BENEFICIARIES',
    UPLOAD_ESTABLISHMENTS: 'UPLOAD_ESTABLISHMENTS',
    ADD_UPDATE_PASSPORT: 'ADD_UPDATE_PASSPORT',
    ACTIVATE_DEACTIVATE: 'ACTIVATE_DEACTIVATE',
    EDIT_TEAM_MEMBER: 'EDIT_TEAM_MEMBER',
    CHANGE_PIN_WITHOUT_EID: 'CHANGE_PIN_WITHOUT_EID',
    VIEW_TRANSACTION_HISTORY: 'VIEW_TRANSACTION_HISTORY',
    VIEW_SCRATCHED_CARD: 'VIEW_SCRATCHED_CARD',
    VIEW_LOGIN_HISTORY: 'VIEW_LOGIN_HISTORY',
    VIEW_PROMOS: 'VIEW_PROMOS',
    VIEW_OFFERS: 'VIEW_OFFERS',
    ACTIVATE_DEACTIVATE_PROMO: 'ACTIVATE_DEACTIVATE_PROMO',
    // VIEW_LOGIN_HISTORY: 'VIEW_LOGIN_HISTORY',
    ACTIVATE_DEACTIVATE_OFFER: 'ACTIVATE_DEACTIVATE_OFFER',
    DELETE_USER: 'DELETE_USER',
    VIEW_LOGS: 'VIEW_LOGS',
    VIEW_CNPL: 'VIEW_CNPL',
    VIEW_PERSONAL_LOAN: 'VIEW_PERSONAL_LOAN',
    VIEW_FETCH_REASONS: 'VIEW_FETCH_REASONS',

    BENEFICIARY_SEND_MONEY: 'BENEFICIARY_SEND_MONEY',
    ADD_BENEFICIARY: 'ADD_BENEFICIARY',
    DELETE_BENEFICIARY: 'DELETE_BENEFICIARY',
    EDIT_BENEFICIARY: 'EDIT_BENEFICIARY',
    SEND_MONEY_TRACK_HISTORY: 'SEND_MONEY_TRACK_HISTORY',

    OPEN_DISPUTE: 'OPEN_DISPUTE',
    VIEW_ALL_DISPUTES: 'VIEW_ALL_DISPUTES',
    MARK_COMPLETE: 'MARK_COMPLETE',
    ADD_REASON: 'ADD_REASON',

    // CREATE_ADMIN: 'CREATE_ADMIN',
    // EDIT_ADMIN: 'EDIT_ADMIN',
    // MANAGE_ACCESS_ADMIN: 'MANAGE_ACCESS_ADMIN',
    // ACTIVATE_DEACTIVATE_ADMIN: 'ACTIVATE_DEACTIVATE_ADMIN',
};

export const ACL_OPTIONS = [
    {
        key: ACL_MODULES.DASHBOARD,
        access: [
            ACL_ACCESS_LIST.VIEW,
        ]
    },
    // {
    //     key: ACL_MODULES.MANAGE_ADMINS,
    //     access: [
    //       ACL_ACCESS_LIST.VIEW,
    //       ACL_ACCESS_LIST.CREATE_ADMIN,
    //       ACL_ACCESS_LIST.EDIT_ADMIN,
    //       ACL_ACCESS_LIST.MANAGE_ACCESS_ADMIN,
    //       ACL_ACCESS_LIST.ACTIVATE_DEACTIVATE_ADMIN,
    //     ]
    //   },
    {
        key: ACL_MODULES.ALL_REQUESTS,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.ALL_REQUEST_CHECKER
        ]
    },
    {
        key: ACL_MODULES.MOBILE_APP_APPROVAL,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.REQUEST_CHECKER,
            ACL_ACCESS_LIST.REQUEST_AUTHORIZER
        ]
    },
    {
        key: ACL_MODULES.EMPLOYERS,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.EDIT,
            ACL_ACCESS_LIST.SYNC_EMPLOYERS,
            ACL_ACCESS_LIST.ADD_EMPLOYER,
            ACL_ACCESS_LIST.UPLOAD_EOS,
            ACL_ACCESS_LIST.CHANGE_PASSWORD,
            ACL_ACCESS_LIST.EMPLOYER_ADD_ESTABLISHMENT,
            ACL_ACCESS_LIST.REQUEST_CHECKER,
            ACL_ACCESS_LIST.REQUEST_AUTHORIZER,
        ],
        subModules: [
            {
                key: ACL_MODULES.EMPLOYEES,
                access: [
                    ACL_ACCESS_LIST.VIEW,
                    ACL_ACCESS_LIST.ADD,
                    ACL_ACCESS_LIST.ADD_WITH_EXCEL,
                    ACL_ACCESS_LIST.CAN_DOWNLOAD,
                    ACL_ACCESS_LIST.SYNC_EMPLOYEES,
                    ACL_ACCESS_LIST.DEACTIVATE_EMPLOYEES,
                    ACL_ACCESS_LIST.ACTIVATE_EMPLOYEES,
                    ACL_ACCESS_LIST.BLOCK_APPLICATION,
                    ACL_ACCESS_LIST.ACTIVATE_APPLICATION,
                    ACL_ACCESS_LIST.DELETE_DUPLICATES,
                ],
                subModules: [
                    {
                        key: ACL_MODULES.EMPLOYEE,
                        access: [
                            ACL_ACCESS_LIST.VIEW,
                            ACL_ACCESS_LIST.CAN_DOWNLOAD,
                            ACL_ACCESS_LIST.ADD_UPDATE_EID,
                            ACL_ACCESS_LIST.EDIT_PERSONAL_DETAIL,
                            ACL_ACCESS_LIST.ADD_KAMELPAY_DETAILS,
                            ACL_ACCESS_LIST.ADD_OTHER_BANK_DETAILS,
                            ACL_ACCESS_LIST.EDIT_OTHER_BANK_DETAILS,
                            ACL_ACCESS_LIST.REPLACE_CARD,
                            ACL_ACCESS_LIST.CARD_ACTIONS,
                            ACL_ACCESS_LIST.CHANGE_PIN,
                            ACL_ACCESS_LIST.CHANGE_PIN_WITHOUT_EID,
                            ACL_ACCESS_LIST.CHECK_CARD_STATUS,
                            ACL_ACCESS_LIST.BLOCK_AND_UNBLOCK_CARD,
                            ACL_ACCESS_LIST.CARD_ACTIVATION,
                            ACL_ACCESS_LIST.CHECK_SUBSCRIPTION_STATUS,
                            ACL_ACCESS_LIST.GET_BALANCE,
                            ACL_ACCESS_LIST.GET_TRANSACTIONS,
                            ACL_ACCESS_LIST.STATEMENT_REQUEST,
                            ACL_ACCESS_LIST.UPLOAD_KYC,
                            ACL_ACCESS_LIST.DEACTIVATE_EMPLOYEE,
                            ACL_ACCESS_LIST.ACTIVATE_EMPLOYEE,
                            ACL_ACCESS_LIST.ADD_UPDATE_PASSPORT,
                            // MOBILE_APP_FUNCTIONS
                            ACL_ACCESS_LIST.APPLY_ADVANCE_SALARY,
                            ACL_ACCESS_LIST.VIEW_CNPL,
                            ACL_ACCESS_LIST.VIEW_PERSONAL_LOAN,
                            ACL_ACCESS_LIST.UPDATE_MOBILE_USER_PASSWORD,
                            ACL_ACCESS_LIST.UPDATE_MOBILE_USER_PHONE,
                            ACL_ACCESS_LIST.CREATE_MOBILE_APP_USER,
                            ACL_ACCESS_LIST.VIEW_BENEFICIARIES,
                            ACL_ACCESS_LIST.VIEW_TRANSACTION_HISTORY,
                            ACL_ACCESS_LIST.VIEW_SCRATCHED_CARD,
                            ACL_ACCESS_LIST.VIEW_LOGIN_HISTORY

                        ],

                    }
                ]
            },
            {
                key: ACL_MODULES.EMPLOYER_TEAM_MEMBERS,
                access: [
                    ACL_ACCESS_LIST.VIEW,
                    ACL_ACCESS_LIST.ADD,
                    ACL_ACCESS_LIST.EDIT_TEAM_MEMBER,
                    ACL_ACCESS_LIST.ACTIVATE_DEACTIVATE,
                ]
            }
        ]
    },
    {
        key: ACL_MODULES.EXCHANGE_HOUSES,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.EDIT,
            ACL_ACCESS_LIST.SYNC_EXCHANGE_HOUSES,
            ACL_ACCESS_LIST.ACCESS_CASHOUT,
            ACL_ACCESS_LIST.EDIT_THEME,
            ACL_ACCESS_LIST.UPLOAD_ESTABLISHMENTS,
            ACL_ACCESS_LIST.REQUEST_CHECKER,
            ACL_ACCESS_LIST.REQUEST_AUTHORIZER,
        ],
        subModules: [
            {
                key: ACL_MODULES.EXCHANGE_HOUSE_BRANCHES,
                access: [
                    ACL_ACCESS_LIST.VIEW,
                    ACL_ACCESS_LIST.ADD,
                ]
            }
        ]
    },
    {
        key: ACL_MODULES.FIND_CARD_HOLDER,
        access: [
            // EMPLOYEE
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.ADD_UPDATE_EID,
            ACL_ACCESS_LIST.EDIT_PERSONAL_DETAIL,
            ACL_ACCESS_LIST.ADD_OTHER_BANK_DETAILS,
            ACL_ACCESS_LIST.EDIT_OTHER_BANK_DETAILS,
            ACL_ACCESS_LIST.CHECK_SUBSCRIPTION_STATUS,
            ACL_ACCESS_LIST.UPLOAD_KYC,
            ACL_ACCESS_LIST.EMPLOYEE_DEACTIVATE,
            ACL_ACCESS_LIST.EMPLOYEE_ACTIVATE,
            ACL_ACCESS_LIST.ADD_UPDATE_PASSPORT,
            ACL_ACCESS_LIST.VIEW_LOGS,
            ACL_ACCESS_LIST.VIEW_FETCH_REASONS,
            // TRANSACTION
            ACL_ACCESS_LIST.CAN_DOWNLOAD,
            ACL_ACCESS_LIST.SHOW_TRANSACTIONS,
            //CARD_MANAGEMENT
            ACL_ACCESS_LIST.REPLACE_CARD,
            ACL_ACCESS_LIST.CHANGE_PIN,
            ACL_ACCESS_LIST.CHANGE_PIN_WITHOUT_EID,
            ACL_ACCESS_LIST.CHECK_CARD_STATUS,
            ACL_ACCESS_LIST.GET_BALANCE,
            ACL_ACCESS_LIST.GET_TRANSACTIONS,
            ACL_ACCESS_LIST.STATEMENT_REQUEST,
            ACL_ACCESS_LIST.CARD_ACTIVATION,
            ACL_ACCESS_LIST.BLOCK_AND_UNBLOCK_CARD,
            ACL_ACCESS_LIST.CARD_ACTIONS,
            // MOBILE_APP_FUNCTIONS
            ACL_ACCESS_LIST.APPLY_ADVANCE_SALARY,
            ACL_ACCESS_LIST.VIEW_CNPL,
            ACL_ACCESS_LIST.VIEW_PERSONAL_LOAN,
            ACL_ACCESS_LIST.UPDATE_MOBILE_USER_PASSWORD,
            ACL_ACCESS_LIST.UPDATE_MOBILE_USER_PHONE,
            ACL_ACCESS_LIST.CREATE_MOBILE_APP_USER,
            ACL_ACCESS_LIST.VIEW_BENEFICIARIES,
            ACL_ACCESS_LIST.VIEW_TRANSACTION_HISTORY,
            ACL_ACCESS_LIST.VIEW_SCRATCHED_CARD,
            ACL_ACCESS_LIST.VIEW_LOGIN_HISTORY,
            ACL_ACCESS_LIST.DELETE_USER,
            ACL_ACCESS_LIST.BLOCK_APPLICATION,
            ACL_ACCESS_LIST.ACTIVATE_APPLICATION,
        ]
    },
    {
        key: ACL_MODULES.DELIVERY_MODULE,
        access: [
            ACL_ACCESS_LIST.VIEW,
        ]
    },
    {
        key: ACL_MODULES.DISPUTE_FORM,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.OPEN_DISPUTE,
            ACL_ACCESS_LIST.VIEW_ALL_DISPUTES,
            ACL_ACCESS_LIST.MARK_COMPLETE,
            ACL_ACCESS_LIST.ADD_REASON,
            ACL_ACCESS_LIST.REQUEST_CHECKER,
            ACL_ACCESS_LIST.REQUEST_AUTHORIZER
        ]
    },
    {
        key: ACL_MODULES.ADVANCE_SALARY,
        access: [
            ACL_ACCESS_LIST.VIEW
        ]
    },
    {
        key: ACL_MODULES.SALARY_STATUS,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.CAN_DOWNLOAD
        ]
    },
    {
        key: ACL_MODULES.TRANSACTIONS,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.CAN_DOWNLOAD,
        ]
    },
    {
        key: ACL_MODULES.REMITTANCES,
        access: [
            ACL_ACCESS_LIST.VIEW,
        ]
    },
    {
        key: ACL_MODULES.CENTIV_CARD,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.LOAD_FUND,
            ACL_ACCESS_LIST.ADD,
            ACL_ACCESS_LIST.ADD_WITH_EXCEL,
            ACL_ACCESS_LIST.REQUEST_CHECKER,
            ACL_ACCESS_LIST.REQUEST_AUTHORIZER,
        ],
        subModules: [
            {
                key: ACL_MODULES.CENTIV_CARD_EMPLOYEE,
                access: [
                    ACL_ACCESS_LIST.VIEW,
                    ACL_ACCESS_LIST.REPLACE_CARD,
                    ACL_ACCESS_LIST.STATEMENT_REQUEST,
                    ACL_ACCESS_LIST.ADD_UPDATE_EID,
                    ACL_ACCESS_LIST.EDIT_PERSONAL_DETAIL,
                    ACL_ACCESS_LIST.CHECK_CARD_STATUS,
                    ACL_ACCESS_LIST.BLOCK_AND_UNBLOCK_CARD,
                    ACL_ACCESS_LIST.CARD_ACTIVATION,
                    ACL_ACCESS_LIST.CHANGE_PIN,
                    ACL_ACCESS_LIST.CHANGE_PIN_WITHOUT_EID,
                    ACL_ACCESS_LIST.GET_BALANCE,
                ],
            }
        ]
    },
    {
        key: ACL_MODULES.REPLACEMENT_CARDS,
        access: [
            ACL_ACCESS_LIST.VIEW,
        ]
    },
    {
        key: ACL_MODULES.LOGS,
        access: [
            ACL_ACCESS_LIST.VIEW,
        ]
    },
    {
        key: ACL_MODULES.TRANSACTION_HISTORY,
        access: [
            ACL_ACCESS_LIST.VIEW,
        ]
    },
    {
        key: ACL_MODULES.SCRATCHED_CARD,
        access: [
            ACL_ACCESS_LIST.VIEW,
        ]
    },
    {
        key: ACL_MODULES.PROMOS,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.ADD,
            ACL_ACCESS_LIST.ACTIVATE_DEACTIVATE_PROMO,
        ]
    },
    {
        key: ACL_MODULES.OFFERS,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.ADD,
            ACL_ACCESS_LIST.ACTIVATE_DEACTIVATE_OFFER,
        ]
    },
    {
        key: ACL_MODULES.HELLO_PAISA_FEE,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.ADD,
            ACL_ACCESS_LIST.EDIT
        ]
    },
    {
        key: ACL_MODULES.PROCESS_SALARIES,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.UPLOAD_SALARY,
            ACL_ACCESS_LIST.UPLOAD_TRANSFER_RECEIPT,
            ACL_ACCESS_LIST.SYNC_EMPLOYEES,
            ACL_ACCESS_LIST.REQUEST_CHECKER,
            ACL_ACCESS_LIST.REQUEST_AUTHORIZER,
        ]
    },
    {
        key: ACL_MODULES.MARKETPLACE,
        access: [
            ACL_ACCESS_LIST.VIEW,
        ],
        subModules: [
            {
                key: ACL_MODULES.MARKETPLACE_PRODUCTS,
                access: [
                    ACL_ACCESS_LIST.VIEW,
                    ACL_ACCESS_LIST.ADD,
                    ACL_ACCESS_LIST.IMAGE_UPLOAD,
                ],
            },
            {
                key: ACL_MODULES.MARKETPLACE_PRODUCT_CATEGORIES,
                access: [
                    ACL_ACCESS_LIST.VIEW,
                    ACL_ACCESS_LIST.ADD,
                ],
                subModules: [
                    {
                        key: ACL_MODULES.MARKETPLACE_PRODUCT_SUBCATEGORIES,
                        access: [
                            ACL_ACCESS_LIST.VIEW,
                            ACL_ACCESS_LIST.ADD,
                        ],
                    }
                ]
            },
            {
                key: ACL_MODULES.MARKETPLACE_PRODUCT_SUPPLIERS,
                access: [
                    ACL_ACCESS_LIST.VIEW,
                    ACL_ACCESS_LIST.ADD,
                ],
            },
            // {
            //     key: ACL_MODULES.MARKETPLACE_PRODUCT_INVENTORIES,
            //     access: [
            //         ACL_ACCESS_LIST.VIEW,
            //         ACL_ACCESS_LIST.UPDATE,
            //     ],
            // },
            {
                key: ACL_MODULES.MARKETPLACE_PRODUCT_ORDERS,
                access: [
                    ACL_ACCESS_LIST.VIEW,
                    ACL_ACCESS_LIST.ACTIONS,
                ],
            },
        ]
    },
    {
        key: ACL_MODULES.CAMPS,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.ADD,
            ACL_ACCESS_LIST.EDIT,
            ACL_ACCESS_LIST.CAN_DOWNLOAD,
        ]
    },
    {
        key: ACL_MODULES.TRANSACTION_SCREENING,
        access: [
            ACL_ACCESS_LIST.VIEW,
        ]
    },
    {
        key: ACL_MODULES.BENEFICIARY_SCREENING,
        access: [
            ACL_ACCESS_LIST.VIEW,
        ]
    },
    {
        key: ACL_MODULES.CAMPAIGNS,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.ADD
        ],
        subModules: [
            {
                key: ACL_MODULES.CAMPAIGN_VARIABLES,
                access: [
                    ACL_ACCESS_LIST.VIEW,
                    ACL_ACCESS_LIST.ADD
                ],
            },
            {
                key: ACL_MODULES.CAMPAIGN_TEMPLATES,
                access: [
                    ACL_ACCESS_LIST.VIEW,
                    ACL_ACCESS_LIST.ADD
                ],
            },
            {
                key: ACL_MODULES.CAMPAIGN_CATEGORIES,
                access: [
                    ACL_ACCESS_LIST.VIEW,
                    ACL_ACCESS_LIST.ADD
                ],
            },
            {
                key: ACL_MODULES.CAMPAIGN_NOTIFICATION_CONFIG,
                access: [
                    ACL_ACCESS_LIST.VIEW,
                    ACL_ACCESS_LIST.ADD
                ],
            }
        ]
    },
    {
        key: ACL_MODULES.MOBILE_APP_LOGS,
        access: [
            ACL_ACCESS_LIST.VIEW,
        ]
    },
    {
        key: ACL_MODULES.REMITTANCE_RATE,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.ADD,
            ACL_ACCESS_LIST.EDIT
        ]
    },
    {
        key: ACL_MODULES.SEND_MONEY,
        access: [
            ACL_ACCESS_LIST.VIEW,
            ACL_ACCESS_LIST.ADD_BENEFICIARY,
            ACL_ACCESS_LIST.EDIT_BENEFICIARY,
            ACL_ACCESS_LIST.DELETE_BENEFICIARY,
            ACL_ACCESS_LIST.BENEFICIARY_SEND_MONEY,
            ACL_ACCESS_LIST.SEND_MONEY_TRACK_HISTORY,
        ]
    }
];

export const modifyRequestSubModuleOrModuleConstants = [
    ACL_ACCESS_LIST.VIEW,
    ACL_ACCESS_LIST.REQUEST_CHECKER,
    ACL_ACCESS_LIST.REQUEST_AUTHORIZER,
    // ACL_ACCESS_LIST.EMPLOYEE_DETAIL_VIEW,
    // ACL_ACCESS_LIST.CENTIV_DETAIL_VIEW
];

export const IsAllowedOption = (key = '', role = "") => {
    const user = useSelector(({ auth }) => auth.user);
    if (user && Object.keys(user)?.length) {
        if (user?.isSuperAdmin) return true;
        if (user?.accessRights && user?.accessRights?.length) {
            const parsedAccessRights = JSON.parse(user?.accessRights);
            if (!user?.isSuperAdmin) {
                const getObj = Object.keys(parsedAccessRights).length ? parsedAccessRights?.find(d => d?.key === key) : {};
                if (getObj?.access && getObj?.access?.length) {
                    return getObj?.access.some(access => access === role);
                } else {
                    return false;
                }
            }
        }
    }
    return false;
};

let topicsClub = {
    // [ACL_MODULES.EMPLOYEES]: EMPLOYEES_TOPIC,
    // [ACL_MODULES.CENTIV_CARD]: CENTIV_TOPIC,
    // [ACL_MODULES.ESTABLISHMENT]: ESTABLISHMENT_TOPIC,
    // [ACL_MODULES.PROCESS_SALARIES]: SALARY_TOPIC,
};

function matchArrays(array1, array2) {
    return array1.filter(element => array2.includes(element));
}

export const getACLData = (user) => {
    let thirdStepTopics = user?.approvalverificationTracking?.threeStepVerification;
    let ACL_LIST = ACL_OPTIONS;
    ACL_LIST = ACL_LIST.map((obj) => {
        let topicsArray = topicsClub[obj?.key] || [];
        if (topicsArray?.length && thirdStepTopics?.length) {
            if (matchArrays(topicsArray, thirdStepTopics)?.length && !obj.access?.includes(ACL_ACCESS_LIST.REQUEST_AUTHORIZER)) {
                obj.access = [...obj.access, ACL_ACCESS_LIST.REQUEST_AUTHORIZER]
            }
        }
        if (thirdStepTopics?.length) {
            if (obj.key === ACL_MODULES.REQUESTS && !obj.access?.includes(ACL_ACCESS_LIST.ALL_REQUEST_AUTHORIZER)) {
                obj.access = [...obj.access, ACL_ACCESS_LIST.ALL_REQUEST_AUTHORIZER]
            }
        }
        return obj
    });

    if (user?.isSuperAdmin) {
        return ACL_LIST;
    } else {
        if (user?.accessRights && user?.accessRights?.length) {
            return JSON.parse(user?.accessRights);
        }
        return [];
    }
};


export const EMPLOYEES_TOPIC = [
    "EMPLOYEES_FILE_UPLOAD",
    "EMPLOYEES_OTHER_BANK_FILE_UPLOAD",
    "EMPLOYEE_ONBOARD_REQUEST",
    "EMPLOYEE_ONBOARD_APPROVED",
    "EMPLOYEE_ONBOARD_REJECT",
    "EMPLOYEE_UPDATE_REQUEST",
    "CARD_REPLACEMENT_REQUEST",
    "EMPLOYEE_APPLICATION_BLOCK_REQUEST",
    "EMPLOYEE_APPLICATION_UN_BLOCK_REQUEST",
    "USER_BLOCK_REQUEST",
    "CHANGE_EMPLOYEE_TYPE",
    "CHANGE_EMPLOYEE_TYPE_TO_KAMELPAY",
    "USER_UNBLOCK_REQUEST",
    "UPDATE_MOL_NO",
    "EMPLOYEE_PHONE_UPDATE",
    "CARD_PIN_CHANGE",
    "EMPLOYEE_STATEMENT_REQUEST",
    "INACTIVE_EMPLOYEES",
    "ACTIVE_EMPLOYEES",
    "BULK_INACTIVE_EMPLOYEES",
    "BULK_ACTIVE_EMPLOYEES",

    "EOS_FILE_UPLOAD",

    "EMPLOYEE_EID_UPDATE",
    "UPDATE_DATE_OF_BIRTH",
    "UPDATE_NATIONALITY",
    "UPDATE_EMP_CODE",
    "UPDATE_PASSPORT",
    "ON_BOARD_REQUEST",
    "TEAM_MEMBER_PHONE_UPDATE",
    "ADD_UPDATE_CASHBACK",
    "ADD_PRODUCT",
    "EDIT_PRODUCT",
    "ADD_CHARGES",
    "EDIT_CHARGES",
    "ADD_BUSINESS_OWNER",
    "EDIT_BUSINESS_OWNER",
    "ADD_EDIT_ADDITIONAL_DETAILS",
    "ADD_OPERATION_USER",
    "EDIT_OPERATION_USER",
    "MOBILE_CARD_DISSOCIATE",
];

export const ESTABLISHMENT_TOPIC = [
    "ESTABLISHMENT_ID_ADDED",
    "ESTABLISHMENT_IDS_ADDED",
    "BULK_ESTABLISHMENT_IDS_ADDED",
];

const CLIENT_TOPIC = [
    "CLIENT_PROFILE_UPDATE",
    "INITIAL_VERIFICATION_APPROVED",
    "ON_BOARD_REQUEST",
    "ON_BOARD_REQUEST_APPROVED",
    "ON_BOARD_REQUEST_REJECT",
];

export const SALARY_TOPIC = [
    "SALARY_FILE_UPLOAD",
    "DEPOSIT_FILE_UPLOAD",
    // "EOS_FILE_UPLOAD",
];
export const CENTIV_TOPIC = [
    "CENTIV_CARD_REQUEST",
    "CENTIV_LOAD_FUNDS",
    "EMPLOYEES_FILE_UPLOAD",
    "EMPLOYEE_ONBOARD_REQUEST",
    "EMPLOYEE_ONBOARD_APPROVED",
    "EMPLOYEE_ONBOARD_REJECT",
];

export const MOBILE_TOPICS = [
    "CREATE_PROMO_CODE",
    "EDIT_PROMO_CODE",
    "CREATE_OFFER",
    "EDIT_OFFER",
    "CARD_ACTIONS",
    "EDIT_HELLOPAISA_FEE",
    "ADD_HELLOPAISA_FEE",
    "ADD_HELLOPAISA_RATE",
    "EDIT_HELLOPAISA_RATE",
];

export const DISPUTE_TOPICS = [
    "CREATE_DISPUTE_FORM",
];

export const segregateTopic = (obj, user, fromMobileApprovals = false) => {

    let topics = [];

    if (user && Object.keys(user)?.length) {
        if (fromMobileApprovals) {
            if (user?.isSuperAdmin || obj.allTopics) {
                topics.push(...MOBILE_TOPICS)
            } else {
                if (obj?.mobileAppApproval) {
                    topics.push(...MOBILE_TOPICS)
                }
            }
        } else {
            if (user?.isSuperAdmin || obj.allTopics) {
                topics.push(...Object.keys(TOPIC))
            } else {
                if (obj?.employer) {
                    topics.push(...EMPLOYEES_TOPIC);
                    topics.push(...ESTABLISHMENT_TOPIC)
                }
                if (obj?.centivCard) {
                    topics.push(...CENTIV_TOPIC)
                }
                if (obj?.processSalaries) {
                    topics.push(...SALARY_TOPIC)
                }
                if (obj?.processSalaries) {
                    topics.push(...SALARY_TOPIC)
                }
                if (obj?.disputeForm) {
                    topics.push(...DISPUTE_TOPICS)
                }
            }
        }
    }

    return topics

};
export const topicWiseCheck = (obj, user, topic, fromMobileApprovals = false) => {
    return segregateTopic(obj, user, fromMobileApprovals)?.includes(topic)

};
export const thirdStepApprovalCheck = (topics = [], topic) => {

    return topics?.includes(topic)

};
