import React, { useEffect } from "react";
import { getProfile } from "./store/actions/Auth.action";
import { useDispatch, useSelector } from "react-redux";
import { Auth, Root } from "./routing";
import { CLoading } from "./uiComponents";
import { createBrowserHistory } from "history";
import { connectionSocket } from "./utils/socket";
import {
  changeClient,
  getClients,
  selectCountry,
} from "./store/actions/Common.action";
import { getValueIntoLocalStorage } from "./utils/asyncStorage/Functions";
import { CLIENTID } from "./utils/asyncStorage/Constants";
import { CAutoLogoutModal } from "./components";

let history = createBrowserHistory({ window });

function App(props) {
  const dispatch = useDispatch();

  const reduxState = useSelector(({ auth, common }) => {
    return {
      loading: auth.loading || auth.getProfileLoading,
      isLoggedIn: auth.isLoggedIn,
      user: auth.user,
      isAutoLogout: auth.isAutoLogout
    };
  });
  const { loading, isLoggedIn, isAutoLogout } = reduxState;

  useEffect(() => {
    (async () => {
      let clientId = await getValueIntoLocalStorage(CLIENTID);
      if (clientId) {
        dispatch(changeClient(clientId));
      }
    })();
    dispatch(getProfile());
    dispatch(selectCountry());
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      connectionSocket();
      let body = { kyc: "ON_BOARD_REQUEST_APPROVED", defaultCompany: true };

      dispatch(getClients(body));
    }
  }, [isLoggedIn]);

  const renderRoutingWithRole = (value) => {
    switch (value) {
      case true:
        return <Root {...props} />;
      case false:
        return <Auth />;
      default:
        return <CLoading />;
    }
  };

  return (
    <div className="app-container responsive">
      {/*<CLoading />*/}
      {loading ? <CLoading /> : renderRoutingWithRole(isLoggedIn)}
      {/*loading ? <CLoading /> : <Auth />*/}
      {/*<Root />*/}
      {/*<Auth />*/}
      {!isLoggedIn && <CAutoLogoutModal isAutoLogout={isAutoLogout} />}
    </div>
  );
}

const withRouter = (Child) => {
  return function withRouter(props) {
    return <Child {...props} history={history} />;
  };
};

export default withRouter(App);
