import DisputeFormConstant from "../constants/DisputeForm.constant";

const initialState = {

    transactions: [],
    employeeDetail: {},
    transactionsMetaData: {},
    getTransactionsLoading: false,

    clients: [],
    getClientLoading: false,

    openDisputeLoading: false,

    dipsutes: [],
    dipsutesMetaData: {},
    getDipsutesLoading: false,

    markApprovedDipsuteLoading: false,

    addTrackingLoading: false,

    summary: [],
    getSummaryLoading: false,

    downloadDisputeFormLoading: false,

    getOTPLoading: false,

};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case DisputeFormConstant.GET_TRANSACTIONS:
            return {
                ...state,
                transactions: action.data,
                getTransactionsLoading: action.loading,
                transactionsMetaData: action.metaData,
                employeeDetail: action.employeeDetail,
            };

        case DisputeFormConstant.GET_CLIENTS:
            return {
                ...state,
                clients: action.data,
                getClientLoading: action.loading,
            };

        case DisputeFormConstant.OPEN_DISPUTE:
            return {
                ...state,
                openDisputeLoading: action.loading,
            };

        case DisputeFormConstant.GET_DISPUTES:
            return {
                ...state,
                dipsutes: action.data,
                getDipsutesLoading: action.loading,
                dipsutesMetaData: action.metaData,
            };

        case DisputeFormConstant.MARK_APPROVED_DISPUTE:
            return {
                ...state,
                markApprovedDipsuteLoading: action.loading,
            };

        case DisputeFormConstant.ADD_TRACKING:
            return {
                ...state,
                addTrackingLoading: action.loading,
            };

        case DisputeFormConstant.GET_SUMMARY:
            return {
                ...state,
                summary: action.data,
                getSummaryLoading: action.loading,
            };

        case DisputeFormConstant.DOWNLOAD_DISPUTE_FORM:
            return {
                ...state,
                downloadDisputeFormLoading: action.loading,
            };

        case DisputeFormConstant.GET_OTP_DISPUTE_FORM:
            return {
                ...state,
                getOTPLoading: action.loading,
            };

        default:
            return state;
    }
};
